/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a ui template
 * @export
 * @interface UiTemplateItemDto
 */
export interface UiTemplateItemDto {
    /**
     * id
     * @type {string}
     * @memberof UiTemplateItemDto
     */
    id: string;
    /**
     * The templateId associated with the template, must be unique throughout the system
     * @type {string}
     * @memberof UiTemplateItemDto
     */
    templateId: string;
    /**
     * The template name
     * @type {string}
     * @memberof UiTemplateItemDto
     */
    name: string;
    /**
     * The template description
     * @type {string}
     * @memberof UiTemplateItemDto
     */
    description: string;
    /**
     * List of prepopulated fields
     * @type {Array<string>}
     * @memberof UiTemplateItemDto
     */
    prepopulatedFields?: Array<string>;
}

/**
 * Check if a given object implements the UiTemplateItemDto interface.
 */
export function instanceOfUiTemplateItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "templateId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function UiTemplateItemDtoFromJSON(json: any): UiTemplateItemDto {
    return UiTemplateItemDtoFromJSONTyped(json, false);
}

export function UiTemplateItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UiTemplateItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'templateId': json['templateId'],
        'name': json['name'],
        'description': json['description'],
        'prepopulatedFields': !exists(json, 'prepopulatedFields') ? undefined : json['prepopulatedFields'],
    };
}

export function UiTemplateItemDtoToJSON(value?: UiTemplateItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'templateId': value.templateId,
        'name': value.name,
        'description': value.description,
        'prepopulatedFields': value.prepopulatedFields,
    };
}

