/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProfileDto } from './ProfileDto';
import {
    ProfileDtoFromJSON,
    ProfileDtoFromJSONTyped,
    ProfileDtoToJSON,
} from './ProfileDto';

/**
 * Returns patient search results 
 * @export
 * @interface PatientSearchResponse
 */
export interface PatientSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof PatientSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof PatientSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof PatientSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof PatientSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof PatientSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof PatientSearchResponse
     */
    orderDirection?: string;
    /**
     * List of patient profiles
     * @type {Array<ProfileDto>}
     * @memberof PatientSearchResponse
     */
    profiles?: Array<ProfileDto>;
}

/**
 * Check if a given object implements the PatientSearchResponse interface.
 */
export function instanceOfPatientSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatientSearchResponseFromJSON(json: any): PatientSearchResponse {
    return PatientSearchResponseFromJSONTyped(json, false);
}

export function PatientSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'profiles': !exists(json, 'profiles') ? undefined : ((json['profiles'] as Array<any>).map(ProfileDtoFromJSON)),
    };
}

export function PatientSearchResponseToJSON(value?: PatientSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'total': value.total,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'profiles': value.profiles === undefined ? undefined : ((value.profiles as Array<any>).map(ProfileDtoToJSON)),
    };
}

