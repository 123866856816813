import i18n from 'src/i18n/config';

const onRequest = (promise, dispatch, successHandler, hasError, isLoading, successData, contextErrorMessage) => {
    promise.then((response) => {
      dispatch(successHandler(response, successData));
      dispatch(isLoading(false));
    }
    ).catch((e) => {
      // Report error
      console.log("Error");
      console.log(e);
      if (e.name === "ResponseError") {
        const errorCode = e.response.status;
        console.log("Response error code: " + errorCode);
        var errorMessage = i18n.t("system.internal_server_error", { ns: 'errors' });
        if (errorCode === 401 || errorCode === 403) {
          const errorMessage = i18n.t("apperta.invalid.token", { ns: 'errors' });
          window.rollbar.error("Unauthorized API response: ", {errorCode: errorCode});
        } else {
          try {
            const errorJson = e.response.json().then((errorResponse) => {
              console.log(errorResponse);
              const errorMessage = i18n.t(errorResponse.errorCode, { ns: 'errors' } );
              dispatch(isLoading(false));
              dispatch(hasError(true, errorMessage));
            }).catch(jsonError => {
              console.warn("Error parsing json error response: " + jsonError.errorMessage);
              const errorMessage = i18n.t("system.internal_server_error", { ns: 'errors' });
              dispatch(isLoading(false));
              dispatch(hasError(true, errorMessage));
            });
            return;  // Do not continue and dispatch if the promise completed
          } catch (parseError) {
            // Occurs if the json parse fails because response is not a string
            console.warn("Error running json parse, no string to parse? " + errorCode);
            window.rollbar.error("Error parsing error json for response code", {errorCode: errorCode});
            // this will fall through to the dispatch with a generic error code
          }
        }
      } else if (e.name === "FetchError") { 
        errorMessage = i18n.t("fetch_error", { ns: 'errors' });
      } else {
        errorMessage = i18n.t("system.internal_server_error", { ns: 'errors' });
      }
      dispatch(isLoading(false));
      dispatch(hasError(true, errorMessage));
    });
  }

  export default onRequest;