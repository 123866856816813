/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionsResponse
 */
export interface PermissionsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof PermissionsResponse
     */
    permissions?: Array<string>;
}

/**
 * Check if a given object implements the PermissionsResponse interface.
 */
export function instanceOfPermissionsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PermissionsResponseFromJSON(json: any): PermissionsResponse {
    return PermissionsResponseFromJSONTyped(json, false);
}

export function PermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function PermissionsResponseToJSON(value?: PermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': value.permissions,
    };
}

