const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

const generatePromList = (pathway) => {
    // Create list of questionaires and subtemplates
    const promsArray = [];
    pathway.steps.forEach((step) => {
      if (step.type === "proms") {
        step.proms.forEach((prom) => {
          const promsId = prom.promsId;
          var promsIdItem = promsArray.find((p) => p.promsId === promsId);
          if (promsIdItem === undefined) {
            promsIdItem = { promsId: promsId, promsSubTemplates: [...prom.promsSubTemplates] };
            promsArray.push(promsIdItem);
          } else {
            const subtemplates = promsIdItem.promsSubTemplates;
            prom.promsSubTemplates.forEach((st) => {
              if (!subtemplates.includes(st)) {
                subtemplates.push(st);
              }
            });
          }
        });
      }
    });

    return promsArray;
}


const matchingDates24Hrs = (date1, date2) => {
  return Math.abs(date1 - date2) < TWENTY_FOUR_HOURS;
}

export default {
  matchingDates24Hrs,
  generatePromList
}