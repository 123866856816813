import { AUDIT_FETCH_LIST } from '../actions/Actions';
import { AUDIT_IS_LOADING  } from '../actions/Actions';
import { AUDIT_ERROR  } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    audit: [],
    total: 0,
    currentTotal: 0,
    searchTerms: "",
    orderTerm: "",
    orderDirection: "desc",
    fetchTime: null,

    hasError: false,
    isLoading: false,
    errorMsg: null,

};


const auditReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case AUDIT_FETCH_LIST: {
            console.log("Fetch audit", action);

            var audit = action.audit;

            if (action.pageStart > 0) {
                const updatedAuditArray = [...state.audit, ...audit];

                return Object.assign({}, state, {
                    audit: updatedAuditArray,
                    currentTotal: updatedAuditArray.length,
                    isLoading: false
                });
            } else {
                return Object.assign({}, state, {
                    audit: audit,
                    currentTotal: audit.length,
                    total: action.total,
                    searchTerms: action.searchTerms,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false
                });
            }
        }
        case LOGOUT:
            return initialState;
        case AUDIT_IS_LOADING:
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case AUDIT_ERROR:
            console.log('haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg
            });
        default:
            return state;
    }
}

export default auditReducer;