/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskDto } from './TaskDto';
import {
    TaskDtoFromJSON,
    TaskDtoFromJSONTyped,
    TaskDtoToJSON,
} from './TaskDto';

/**
 * Returns task search results 
 * @export
 * @interface TaskSearchResponse
 */
export interface TaskSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof TaskSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof TaskSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof TaskSearchResponse
     */
    start?: number;
    /**
     * Total number of tasks
     * @type {number}
     * @memberof TaskSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof TaskSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof TaskSearchResponse
     */
    orderDirection?: string;
    /**
     * List of tasks
     * @type {Array<TaskDto>}
     * @memberof TaskSearchResponse
     */
    tasks?: Array<TaskDto>;
}

/**
 * Check if a given object implements the TaskSearchResponse interface.
 */
export function instanceOfTaskSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaskSearchResponseFromJSON(json: any): TaskSearchResponse {
    return TaskSearchResponseFromJSONTyped(json, false);
}

export function TaskSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'tasks': !exists(json, 'tasks') ? undefined : ((json['tasks'] as Array<any>).map(TaskDtoFromJSON)),
    };
}

export function TaskSearchResponseToJSON(value?: TaskSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'total': value.total,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'tasks': value.tasks === undefined ? undefined : ((value.tasks as Array<any>).map(TaskDtoToJSON)),
    };
}

