/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcedureExportMethodDto } from './ProcedureExportMethodDto';
import {
    ProcedureExportMethodDtoFromJSON,
    ProcedureExportMethodDtoFromJSONTyped,
    ProcedureExportMethodDtoToJSON,
} from './ProcedureExportMethodDto';

/**
 * Represents a sub procedure
 * @export
 * @interface SubProcedureTemplateDto
 */
export interface SubProcedureTemplateDto {
    /**
     * Sub Procedure id
     * @type {string}
     * @memberof SubProcedureTemplateDto
     */
    id: string;
    /**
     * Sub Procedure name
     * @type {string}
     * @memberof SubProcedureTemplateDto
     */
    subProcedureName: string;
    /**
     * Export method list
     * @type {Array<ProcedureExportMethodDto>}
     * @memberof SubProcedureTemplateDto
     */
    exportMethodDtoList?: Array<ProcedureExportMethodDto>;
    /**
     * Parent procedure id
     * @type {string}
     * @memberof SubProcedureTemplateDto
     */
    parentProcedureId: string;
    /**
     * Parent procedure name
     * @type {string}
     * @memberof SubProcedureTemplateDto
     */
    parentProcedureName: string;
}

/**
 * Check if a given object implements the SubProcedureTemplateDto interface.
 */
export function instanceOfSubProcedureTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "subProcedureName" in value;
    isInstance = isInstance && "parentProcedureId" in value;
    isInstance = isInstance && "parentProcedureName" in value;

    return isInstance;
}

export function SubProcedureTemplateDtoFromJSON(json: any): SubProcedureTemplateDto {
    return SubProcedureTemplateDtoFromJSONTyped(json, false);
}

export function SubProcedureTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubProcedureTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subProcedureName': json['subProcedureName'],
        'exportMethodDtoList': !exists(json, 'exportMethodDtoList') ? undefined : ((json['exportMethodDtoList'] as Array<any>).map(ProcedureExportMethodDtoFromJSON)),
        'parentProcedureId': json['parentProcedureId'],
        'parentProcedureName': json['parentProcedureName'],
    };
}

export function SubProcedureTemplateDtoToJSON(value?: SubProcedureTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subProcedureName': value.subProcedureName,
        'exportMethodDtoList': value.exportMethodDtoList === undefined ? undefined : ((value.exportMethodDtoList as Array<any>).map(ProcedureExportMethodDtoToJSON)),
        'parentProcedureId': value.parentProcedureId,
        'parentProcedureName': value.parentProcedureName,
    };
}

