import { ADD_PATIENT_DATA_COMPOSITION } from './Actions';
import { FETCH_PATIENT_DATA_COMPOSITION } from './Actions';
import { FETCH_PATIENT_PATHWAY_RESULTS } from './Actions';
import { FETCH_PATIENT_PATHWAYS } from './Actions';
import { FETCH_PATIENT_PATHWAY_BY_UUID } from './Actions';

import { PATIENT_DATA_ERROR } from './Actions';
import { PATIENT_DATA_UPDATE_ERROR } from './Actions';
import { PATIENT_DATA_CLEAR_ERROR } from './Actions';
import { PATIENT_DATA_IS_LOADING } from './Actions';
import { PATIENT_DATA_SUCCESS_CLEAR } from './Actions';
import { PATIENT_DATA_DELETE_SUCCESS_CLEAR } from './Actions';

import { ADD_PATIENT_PATHWAY } from './Actions';
import { UPDATE_PATIENT_PATHWAY } from './Actions';
import { DELETE_PATIENT_PATHWAY } from './Actions';

import { HealthRecordEndpointApi } from 'promsApi';
import { PathwayEndpointApi } from 'promsApi';

import getConfiguation from './Configuration';
import onRequest from './Request';

import PromQueryUtils from 'src/Common/PromQueryUtils';


const hasError = (bool, errorMsg) => {
  return {
    type: PATIENT_DATA_ERROR,
    hasError: bool,
    errorMsg: errorMsg
  }
};

const hasUpdateError = (bool, errorMsg) => {
  return {
    type: PATIENT_DATA_UPDATE_ERROR,
    hasError: bool,
    errorMsg: errorMsg
  }
};

const clearError = () => {
  return {
    type: PATIENT_DATA_CLEAR_ERROR,
  }
};

const isLoading = (bool) => {
  return {
    type: PATIENT_DATA_IS_LOADING,
    isLoading: bool
  }
};

const clearUpdateSuccess = () => {
  return {
    type: PATIENT_DATA_SUCCESS_CLEAR
  }
};

const clearDeleteSuccess = () => {
  return {
    type: PATIENT_DATA_DELETE_SUCCESS_CLEAR
  }
}

const addCompositionSucessHandler = (patientData, successData) => {
  return {
    type: ADD_PATIENT_DATA_COMPOSITION
  }
}

const addComposition = (userId, userIdNamespace, composition, compositionUid) => {
  var successData = {
    timeStamp: new Date().getTime()
  }

  if (userId != null) {
    composition.userId = userId;
    composition.userIdNamespace = userIdNamespace;
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var userApi = new HealthRecordEndpointApi(config);
    var request;
    if (compositionUid !== undefined && compositionUid !== null) {
      request = userApi.createComposition({ "compositionDto": composition, compositionUid: compositionUid })
    } else {
      console.log("~~~~~~~~~~~~~~~~~~~~~~");
      console.log(composition);
      request = userApi.createComposition2({ "compositionDto": composition });
    }

    onRequest(request, dispatch, addCompositionSucessHandler, hasUpdateError, isLoading, successData, "Error updating questionaire data");
  };
}


const fetchPathwayResultsSuccessHandler = (patientData, successData) => {
  console.log(patientData);
  return {
    type: FETCH_PATIENT_PATHWAY_RESULTS,
    promResults: patientData.promResults,
    procedureCompositions: patientData.procedureCompositions,
    pathwayId: successData.pathwayId,
    userId: successData.userId,
    userIdNamespace: successData.userIdNamespace
  }
}

const fetchPathwayResults = (userId, userIdNamespace, pathway, procedureCompositionUUIDs) => {
  console.log(pathway);

  const promsList = PromQueryUtils.generatePromList(pathway);

  const pathwayQueryRequest = {
    userId : userId,
    userIdNamespace : userIdNamespace,
    pathwayId: pathway.pathwayId,
    promQueryList: promsList,
    procedureCompositionUUIDs: procedureCompositionUUIDs ? procedureCompositionUUIDs : []
  }

  const successData = {
    pathwayId: pathway.uuid,
    userId: userId,
    userIdNamespace: userIdNamespace
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var userApi = new HealthRecordEndpointApi(config);
    var request = userApi.runPathwayQuery({ pathwayQueryDto : pathwayQueryRequest });
    onRequest(request, dispatch, fetchPathwayResultsSuccessHandler, hasError, isLoading, successData, "Error fetching patient data");
  };
}

const fetchPatientPathwaysSuccess = (response, ids) => {
  return {
    type: FETCH_PATIENT_PATHWAYS,
    payload: response,
    patientPathwayFetchTime: new Date().getTime(),
    userId: ids.userId,
    userIdNamespace: ids.userIdNamespace
  }
}

const fetchPatientPathways = (userId, userIdNamespace) => {
  return (dispatch, getState) => {
    // Check time since pathways last fetched.
    const lastFetchTime = getState().patientData.patientPathwayFetchTime;
    const timeSinceFetch = new Date().getTime() - lastFetchTime;

    const lastUserIdFetch = getState().patientData.userId;
    const lastUserIdNamespaceFetch = getState().patientData.userIdNamespace;

    if (timeSinceFetch > window._env_.REACT_APP_PATIENT_PATHWAY_CACHE_TIME
       || userId !== lastUserIdFetch || userIdNamespace !== lastUserIdNamespaceFetch)
       {
      dispatch(isLoading(true));
      var config = getConfiguation(dispatch, getState);
      var pathwayApi = new PathwayEndpointApi(config);
      var request = pathwayApi.getAllPathwayCompositions({ userId: userId, userIdNamespace: userIdNamespace });
      onRequest(request, dispatch, fetchPatientPathwaysSuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace }, "Error fetching patient data");
    };
  }
}

const fetchPatientPathwayByUUIDSuccess = (response, pathwayUUID) => {
  const pathway = response.pathway;
  pathway.uuid = pathwayUUID;
  return {
    type: FETCH_PATIENT_PATHWAY_BY_UUID,
    pathway: response.pathway,
    patientPathwayFetchTime: new Date().getTime()
  }
}

const fetchPatientPathwayByUUID = (userId, userIdNamespace, pathwayUUID) => {
  return (dispatch, getState) => {
      const requestParameters = { 
        userId: userId,
        userNamespace: userIdNamespace,
        compositionUid: pathwayUUID
      }

      dispatch(isLoading(true));
      var config = getConfiguation(dispatch, getState);
      var pathwayApi = new PathwayEndpointApi(config);
      var request = pathwayApi.getComposition(requestParameters);
      onRequest(request, dispatch, fetchPatientPathwayByUUIDSuccess, hasError, isLoading, pathwayUUID, "Error fetching patient pathway");
    };
}

const onAddPathwaySuccess = (response, data) => {
  return {
    type: ADD_PATIENT_PATHWAY,
    userId: data.userId,
    userIdNamespace: data.userIdNamespace,
    pathway: response.pathway
  }
};

const addPatientPathway = (userId, userIdNamespace, pathway, procedureList) => {
  pathway.lastModified = new Date();

  // Extract procedures
  var procedures = pathway.steps.filter((step) => step.type === "procedure")
    .map((p) => {
      var procedure = procedureList.find((pl) => pl.id === p.procedureId);
      var procedureCopy = JSON.parse(JSON.stringify(procedure)); // Deep copy
      procedureCopy.id = p.id;
      procedureCopy.procedureId = p.procedureId;
      procedureCopy.title = p.title;
      procedureCopy.scheduled = p.scheduled;
      procedureCopy.additionalInfo = p.additionalInfo;
      if ( p.procedureSide !== "na"){
        procedureCopy.procedureSide =p.procedureSide;
      }

      return procedureCopy;
    });
  pathway.primaryProcedure = procedures.find(p => p.id === 0);
  pathway.secondaryProcedures = procedures.filter(p => p.id !== 0);

  const data = {
    pathway: pathway,
    userId: userId,
    userIdNamespace: userIdNamespace
  }

  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var pathwayApi = new PathwayEndpointApi(config);
    var request = pathwayApi.createComposition1({ pathwayCompositionDto: data });
    onRequest(request, dispatch, onAddPathwaySuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace }, "Error adding pathway");
  };
}

const onUpdatePathwaySuccess = (response, data) => {
  console.log("PATHWAY UPDATE");
  console.log(response);

  return {
    type: UPDATE_PATIENT_PATHWAY,
    patientId: data.patientId,
    pathway: response.pathway
  }
};

const updatePatientPathwayStep = (userId, userIdNamespace, pathway, pathwayStep) => {

  const data = {
    step: pathwayStep,
    userId: userId,
    userIdNamespace: userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway
  }

  console.log("******************************");
  console.log(pathway);
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var pathwayApi = new PathwayEndpointApi(config);
    var request = pathwayApi.updatePathwaySteps({ pathwayStepDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace },  "Error updating pathway");
  };
}

const deletePatientPathwayStep = (userId, userIdNamespace, pathway, pathwayStep) => {
  const data = {
    step: pathwayStep,
    userId: userId,
    userIdNamespace: userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway
  }

  console.log("******************************");
  console.log(pathway);
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var pathwayApi = new PathwayEndpointApi(config);
    var request = pathwayApi.removePathwayStep({ pathwayStepDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace },  "Error updating pathway");
  };
}

const deletePatientPathwayProcedure = (userId, userIdNamespace, pathway, procedure) => {
  const data = {
    procedure: procedure,
    userId: userId,
    userIdNamespace: userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway
  }

  console.log("******************************");
  console.log(pathway);
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var pathwayApi = new PathwayEndpointApi(config);
    var request = pathwayApi.removePathwayProcedure({ pathwayProcedureDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace },  "Error updating pathway");
  };
}

const onDeletePathwaySuccess = (response, data) => {
  console.log("PATHWAY DELETE");
  console.log(data);

  return {
    type: DELETE_PATIENT_PATHWAY,
    data: data
  }
};

const deletePatientPathway = (userId, userIdNamespace, pathway ) => {
  const data = {
    userId: userId,
    userIdNamespace: userIdNamespace,
    pathway: pathway
  }

  console.log("******************************");
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var pathwayApi = new PathwayEndpointApi(config);
    var request = pathwayApi.removeCompositionRaw({ pathwayCompositionDto: data });
    onRequest(request, dispatch, onDeletePathwaySuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace, pathway: pathway },  "Error updating pathway");
  };
}

const updatePatientProcedure = (userId, userIdNamespace, pathway, procedure, subProcedureRescheduleList, promRescheduleList) => {
  const data = {
    procedure: procedure,
    userId: userId,
    userIdNamespace: userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway,
    subProcedureRescheduleList: subProcedureRescheduleList,
    promRescheduleList: promRescheduleList
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var pathwayApi = new PathwayEndpointApi(config);
    var request = pathwayApi.updatePathwayProcedure({ pathwayProcedureDto: data })
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId: userId, userIdNamespace: userIdNamespace },  "Error updating pathway");
  };
}

const fetchCompositionSuccessHandler = (composition, successData) => {
  return {
    type: FETCH_PATIENT_DATA_COMPOSITION,
    payload: composition,
    compositionUUID: successData.compositionUUID,
    pathwayId: successData.pathwayId,
    userId: successData.userId,
    userIdNamespace: successData.userIdNamespace,
  }
}

const fetchComposition = (compositionUUID, pathwayId, userId, userIdNamespace) => {
  const successData = {
    pathwayId: pathwayId,
    userId: userId,
    userIdNamespace: userIdNamespace,
    compositionUUID: compositionUUID
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var userApi = new HealthRecordEndpointApi(config);

    var requestParameters = {
      compositionUid: compositionUUID,
      userId: userId,
      userIdNamespace: userIdNamespace
    }

    var request = userApi.getComposition1(requestParameters);
    onRequest(request, dispatch, fetchCompositionSuccessHandler, hasError, isLoading, successData,  "Error fetching patient procedure data");
  };
}

export default {
  addComposition,
  fetchComposition,
  clearUpdateSuccess,
  hasError,
  clearError,
  fetchPatientPathways,
  fetchPatientPathwayByUUID,
  fetchPathwayResults,
  addPatientPathway,
  updatePatientProcedure,
  updatePatientPathwayStep,
  deletePatientPathwayStep,
  deletePatientPathwayProcedure,
  deletePatientPathway,
  clearDeleteSuccess
}