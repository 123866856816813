/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiError,
  ApiValidationErrors,
  PermissionsDto,
  PermissionsResponse,
  ProfileDto,
} from '../models';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    PermissionsDtoFromJSON,
    PermissionsDtoToJSON,
    PermissionsResponseFromJSON,
    PermissionsResponseToJSON,
    ProfileDtoFromJSON,
    ProfileDtoToJSON,
} from '../models';

export interface SetPermissionRequest {
    permissionsDto: PermissionsDto;
}

export interface SetProfileRequest {
    profileDto: ProfileDto;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Fetches current settings for T&C and permissions for user
     * Consents
     */
    async getPermissionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/profile/permission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionsDtoFromJSON(jsonValue));
    }

    /**
     * Fetches current settings for T&C and permissions for user
     * Consents
     */
    async getPermission(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionsDto> {
        const response = await this.getPermissionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches list of permissions associated with user
     * associated hcps
     */
    async getPermissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionsResponseFromJSON(jsonValue));
    }

    /**
     * Fetches list of permissions associated with user
     * associated hcps
     */
    async getPermissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionsResponse> {
        const response = await this.getPermissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches current profile details for user
     * profile
     */
    async getProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Fetches current profile details for user
     * profile
     */
    async getProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates settings for T&C and permissions for user
     * Consents
     */
    async setPermissionRaw(requestParameters: SetPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionsDto>> {
        if (requestParameters.permissionsDto === null || requestParameters.permissionsDto === undefined) {
            throw new runtime.RequiredError('permissionsDto','Required parameter requestParameters.permissionsDto was null or undefined when calling setPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/profile/permission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionsDtoToJSON(requestParameters.permissionsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionsDtoFromJSON(jsonValue));
    }

    /**
     * Updates settings for T&C and permissions for user
     * Consents
     */
    async setPermission(requestParameters: SetPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionsDto> {
        const response = await this.setPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates current profile for user
     * profile
     */
    async setProfileRaw(requestParameters: SetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.profileDto === null || requestParameters.profileDto === undefined) {
            throw new runtime.RequiredError('profileDto','Required parameter requestParameters.profileDto was null or undefined when calling setProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileDtoToJSON(requestParameters.profileDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Updates current profile for user
     * profile
     */
    async setProfile(requestParameters: SetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.setProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
