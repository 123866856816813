/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Prom } from './Prom';
import {
    PromFromJSON,
    PromFromJSONTyped,
    PromToJSON,
} from './Prom';

/**
 * 
 * @export
 * @interface Step
 */
export interface Step {
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Step
     */
    timeScale?: number;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    timeUnits?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    anchor?: string;
    /**
     * 
     * @type {Array<Prom>}
     * @memberof Step
     */
    proms?: Array<Prom>;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    completed?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    scheduled?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    procedureId?: string;
    /**
     * 
     * @type {number}
     * @memberof Step
     */
    timeScaleDays?: number;
    /**
     * 
     * @type {Date}
     * @memberof Step
     */
    anchorStepDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    promsJson?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    subProcedureId?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    updatedBy?: string;
}

/**
 * Check if a given object implements the Step interface.
 */
export function instanceOfStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StepFromJSON(json: any): Step {
    return StepFromJSONTyped(json, false);
}

export function StepFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'timeScale': !exists(json, 'timeScale') ? undefined : json['timeScale'],
        'timeUnits': !exists(json, 'timeUnits') ? undefined : json['timeUnits'],
        'anchor': !exists(json, 'anchor') ? undefined : json['anchor'],
        'proms': !exists(json, 'proms') ? undefined : ((json['proms'] as Array<any>).map(PromFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'scheduled': !exists(json, 'scheduled') ? undefined : json['scheduled'],
        'procedureId': !exists(json, 'procedureId') ? undefined : json['procedureId'],
        'timeScaleDays': !exists(json, 'timeScaleDays') ? undefined : json['timeScaleDays'],
        'anchorStepDate': !exists(json, 'anchorStepDate') ? undefined : (new Date(json['anchorStepDate'])),
        'promsJson': !exists(json, 'promsJson') ? undefined : json['promsJson'],
        'subProcedureId': !exists(json, 'subProcedureId') ? undefined : json['subProcedureId'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function StepToJSON(value?: Step | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'type': value.type,
        'timeScale': value.timeScale,
        'timeUnits': value.timeUnits,
        'anchor': value.anchor,
        'proms': value.proms === undefined ? undefined : ((value.proms as Array<any>).map(PromToJSON)),
        'status': value.status,
        'completed': value.completed,
        'scheduled': value.scheduled,
        'procedureId': value.procedureId,
        'timeScaleDays': value.timeScaleDays,
        'anchorStepDate': value.anchorStepDate === undefined ? undefined : (value.anchorStepDate.toISOString()),
        'promsJson': value.promsJson,
        'subProcedureId': value.subProcedureId,
        'updatedBy': value.updatedBy,
    };
}

