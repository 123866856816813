/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TemplateDto } from './TemplateDto';
import {
    TemplateDtoFromJSON,
    TemplateDtoFromJSONTyped,
    TemplateDtoToJSON,
} from './TemplateDto';

/**
 * Returns a template search
 * @export
 * @interface TemplateSearchResponse
 */
export interface TemplateSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof TemplateSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof TemplateSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof TemplateSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof TemplateSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof TemplateSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof TemplateSearchResponse
     */
    orderDirection?: string;
    /**
     * List of templates
     * @type {Array<TemplateDto>}
     * @memberof TemplateSearchResponse
     */
    templateDtoList?: Array<TemplateDto>;
}

/**
 * Check if a given object implements the TemplateSearchResponse interface.
 */
export function instanceOfTemplateSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TemplateSearchResponseFromJSON(json: any): TemplateSearchResponse {
    return TemplateSearchResponseFromJSONTyped(json, false);
}

export function TemplateSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'templateDtoList': !exists(json, 'templateDtoList') ? undefined : ((json['templateDtoList'] as Array<any>).map(TemplateDtoFromJSON)),
    };
}

export function TemplateSearchResponseToJSON(value?: TemplateSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'total': value.total,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'templateDtoList': value.templateDtoList === undefined ? undefined : ((value.templateDtoList as Array<any>).map(TemplateDtoToJSON)),
    };
}

