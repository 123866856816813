
import { FETCH_PATHWAYS  } from '../actions/Actions';
import { FETCH_PATHWAY_LIST } from '../actions/Actions';
import { ADD_PATHWAY } from '../actions/Actions';
import { UPDATE_PATHWAY  } from '../actions/Actions';
import { DELETE_PATHWAY } from '../actions/Actions';
import { PATHWAY_LOADING  } from '../actions/Actions';
import { PATHWAY_UPDATE_ERROR  } from '../actions/Actions';
import { PATHWAY_SUCCESS_CLEAR  } from '../actions/Actions';


import { LOGOUT } from '../actions/Actions';

const initialState = {
    pathways: [],
    pathwayList: [],
    searchTerm: '',

    hasError: false,
    isLoading: false,
    errorMsg: null,
    updateSuccess: false,
    updateSuccessMessage: null
};

function updatePathwayInArray(array, pathway) {
    var found = false;
    var updatedPathwayArray = array.map((item, index) => {
      if (item.id !== pathway.id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }

      found = true;
      return {
        ...item,
        ...pathway
      };
    });

    if ( !found ){
        updatedPathwayArray = [...updatedPathwayArray,pathway ];
    }

    return updatedPathwayArray;
  }

const pathwaysReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case FETCH_PATHWAY_LIST: {
            console.log('Pathway reducer ', action);
            const pathwayList = action.pathwayList;

            if (action.pageStart > 0) {
                const updatedPathwayArray = [...state.pathwayList, ...pathwayList];

                return Object.assign({}, state, {
                    pathwayList: updatedPathwayArray,
                    currentTotal: updatedPathwayArray.length,
                    isLoading: false
                });
            } else {
                return Object.assign({}, state, {
                    pathwayList: pathwayList,
                    currentTotal: pathwayList.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });
            }
        }
        case FETCH_PATHWAYS: {
            console.log('Pathway reducer ', action);

            var pathwayArray = state.pathways;
            action.pathways.forEach((p) => {
                const pathway = JSON.parse(p.template);
                pathwayArray = updatePathwayInArray(pathwayArray, pathway);
            })

            return Object.assign({}, state, {
                pathways: pathwayArray,
                isLoading: false
            });
        }
        case ADD_PATHWAY: {
            console.log('Pathway reducer ', action);

            const pathway = JSON.parse(action.pathway.template);
            return Object.assign({}, state, {
                pathways: [...state.pathways, pathway],
                pathwayList: [...state.pathwayList, action.pathwayListItem],
                isLoading: false,
                updateSuccess: true
            });
        }
        case UPDATE_PATHWAY:{
            console.log('Pathway reducer ', action);
            const pathway = JSON.parse(action.pathway.template);
            var updatedArray = updatePathwayInArray(state.pathways, pathway);
            return Object.assign({}, state, {
                pathways: updatedArray,
                isLoading: false,
                updateSuccess: true
            });
        }
        case DELETE_PATHWAY: {
            console.log('Pathway reducer ', action);
            const deletedPathway = action.pathway;
            const updatedPathways = state.pathways.filter(pathway => pathway.id !== deletedPathway.id);
            const updatedPathwayList = state.pathwayList.filter(pathway => pathway.id !== deletedPathway.id);

            return Object.assign({}, state, {
                pathways: updatedPathways,
                pathwayList: updatedPathwayList,
                currentTotal: updatedPathwayList.length - 1,
                total: state.total - 1,
                isLoading: false,
                updateSuccess: true
            });
        }
        case LOGOUT:
            return initialState;
        case PATHWAY_UPDATE_ERROR:
            console.log('Pathway reducer - haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                updateSuccess: false
            });
        case PATHWAY_LOADING:
            console.log('Pathway reducer - isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case PATHWAY_SUCCESS_CLEAR: {
            console.log('Pathway reducer - sucess clear', action);

            return Object.assign({}, state, {
                updateSuccess: false,
                updateSuccessMessage:null
            });
        }
        default:
            return state;
    }
}

export default pathwaysReducer;