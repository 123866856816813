import { Configuration } from 'promsApi';

const serverUrl = window._env_.REACT_APP_API_SERVER_URL;
const clientCredServerUrl = window._env_.REACT_APP_KEYCLOAK_TOKEN_URL;

const clientId = window._env_.REACT_APP_CLIENT_ID;
const clientSecret = window._env_.REACT_APP_CLIENT_SECRET;

const getAccessToken = (string, []) => {
        console.log("Fetching access code");
        return fetch(clientCredServerUrl, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: "grant_type=client_credentials&client_id=" + clientId + "&client_secret=" + clientSecret,
        })
            .then(response => response.json())
            .then(res => { 
                console.log(res);
                return "Bearer " + res.access_token;
            });
               
  };

const timeoutFetch = (input, init) => {
    var timeoutPromise = new Promise(function (resolve, reject) {
        setTimeout(resolve, 30000, {
            status: 'error',
            code: 666,
            data: 'TIMEOUT',
        });
    });

    return Promise.race([
        timeoutPromise,
        fetch(input, init)
    ]);
}

const getConfiguation = () => {
    var config = new Configuration(
        {
            "basePath": serverUrl,
            "fetchApi": timeoutFetch,
            "accessToken": getAccessToken
        }
    );
    return config;
}

export default getConfiguation;