/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RescheduleNotificationRequest
 */
export interface RescheduleNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    ehrId?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    pathwayUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    patientId?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    patientIdNamespace?: string;
}

/**
 * Check if a given object implements the RescheduleNotificationRequest interface.
 */
export function instanceOfRescheduleNotificationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RescheduleNotificationRequestFromJSON(json: any): RescheduleNotificationRequest {
    return RescheduleNotificationRequestFromJSONTyped(json, false);
}

export function RescheduleNotificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RescheduleNotificationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ehrId': !exists(json, 'ehrId') ? undefined : json['ehrId'],
        'pathwayUuid': !exists(json, 'pathwayUuid') ? undefined : json['pathwayUuid'],
        'patientId': !exists(json, 'patientId') ? undefined : json['patientId'],
        'patientIdNamespace': !exists(json, 'patientIdNamespace') ? undefined : json['patientIdNamespace'],
    };
}

export function RescheduleNotificationRequestToJSON(value?: RescheduleNotificationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ehrId': value.ehrId,
        'pathwayUuid': value.pathwayUuid,
        'patientId': value.patientId,
        'patientIdNamespace': value.patientIdNamespace,
    };
}

