import { UPDATE_HCP_PROFILE } from './Actions';

import { HCP_DATA_ERROR } from './Actions';
import { HCP_DATA_IS_LOADING } from './Actions';
import { HCP_DATA_SUCCESS_CLEAR } from './Actions';

import { HcpApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const hasError = (bool, errorMsg) => {
    return {
        type: HCP_DATA_ERROR,
        hasError: bool,
        errorMsg: errorMsg
    }
};

const isLoading = (bool) => {
    return {
        type: HCP_DATA_IS_LOADING,
        isLoading: bool
    }
};

const updateHcpProfile = (payload) => {
    return { 
        type: UPDATE_HCP_PROFILE,
        payload: payload
    };
};

const saveHcpProfileSuccess = (payload, profile) => {
    return {
        type: UPDATE_HCP_PROFILE,
        payload: profile,
        success: true
    }
};

const clearHcpUpdateSuccess = () => {
    return {
        type: HCP_DATA_SUCCESS_CLEAR
    }
};

const getHcpProfile= () => {
    console.log('Fetching hcp profile');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var hcpApi = new HcpApi(config);
        var request = hcpApi.getProfile1();
        onRequest(request, dispatch, updateHcpProfile, hasError, isLoading, null, "Error fetching user profile");
    };
};

const saveHcpProfile= (profile) => {
    console.log('Saving hcp profile');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var hcpApi = new HcpApi(config);
        var request = hcpApi.createUpdateProfile({"hcpDto": profile});
        onRequest(request, dispatch, saveHcpProfileSuccess, hasError, isLoading, profile, "Error saving user profile");
    };
};


export default {
    getHcpProfile,
    saveHcpProfile,
    hasError,
    clearHcpUpdateSuccess
}