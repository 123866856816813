import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoginView from 'src/views/auth/LoginView';
import LoginLayout from 'src/layouts/LoginLayout';

import { useRoutes } from 'react-router-dom';

import PermissionMappings from 'src/PermissionMappings';

const SettingsView = lazy(() => import('src/views/settings/SettingsView'));

const PatientsView = lazy(() => import('src/views/PatientsView'));
const PatientDetailView = lazy(() => import('src/views/PatientDetailView'));

const PromsDev = lazy(() => import('src/views/PromsView'));
const QuestionnaireDev = lazy(() => import('src/views/QuestionnaireView'));

const DashBoardScreenView = lazy(() => import('src/views/DashBoardScreenView'));

const StaffView = lazy(() => import('src/views/StaffView'));

const ProceduresView = lazy(() => import('src/views/ProceduresView'));
const ProcedureDetailView = lazy(() => import('src/views/ProcedureDetailView'));

const PathwaysView = lazy(() => import('src/views/PathwaysView'));
const PathwayDetailView = lazy(() => import('src/views/PathwayDetailView'));
const PatientPathwayView = lazy(() => import('src/views/PatientPathwayView'));

const ReportsView = lazy(() => import('src/views/ReportsView'));

const ConfigAdminView = lazy(() => import('src/views/ConfigAdmin'));

const TasksView = lazy(() => import('src/views/TasksView'));

const AuditView = lazy(() => import('src/views/AuditView'));

export const staffRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'profile', element: <SettingsView /> },
      { path: 'help', element: <Navigate to="/app/dashboard" /> },

      { path: '*', element: <Navigate to="/404" /> },

      { path: 'dashboard', element: <DashBoardScreenView /> },
      { path: '', element: <Navigate to="dashboard" /> },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Navigate to="/app/dashboard" /> },
      { path: '404', element: <NotFoundView /> },
      { path: '', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export const nonAuthRoutes = [
  {
    path: '/',
    element: <LoginLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <LoginView /> }
    ]
  }
];

const permissionBasedRoutes = [
  {
    route: "staff",
    items: [{ path: 'users', element: <StaffView /> }]
  },
  {
    route: "pathways",
    items: [
      { path: 'pathways', element: <PathwaysView /> },
      { path: 'pathway/:id', element: <PathwayDetailView /> }
    ]
  },
  {
    route: "procedures",
    items: [
      { path: 'procedures', element: <ProceduresView /> },
      { path: 'procedures/:id', element: <ProcedureDetailView /> }
    ]
  },
  {
    route: "patients",
    items: [
      { path: 'patients', element: <PatientsView /> },
      { path: 'patient', element: <PatientDetailView /> },
      { path: 'patient/pathway/:pathwayId', element: <PatientPathwayView /> }
    ]
  },
  {
    route: "reports",
    items: [{ path: 'reports/:name', element: <ReportsView /> }]
  },
  {
    route: "proms",
    items: [
      { path: 'proms', element: <PromsDev /> },
      { path: 'questionnaire/:id', element: <QuestionnaireDev /> }
    ]
  },
  {
    route: "config",
    items: [
      { path: 'config', element: <ConfigAdminView /> },
      { path: 'config/:tabId', element: <ConfigAdminView /> },
    ]
  },
  {
    route: "audit",
    items: [
      { path: 'audit', element: <AuditView /> }
    ]
  },
  {
    route: "tasks",
    items: [
      { path: 'tasks', element: <TasksView /> }
    ]
  }
];

export const GetRoutes = (isLoggedIn, permissions) => {
  var routes = nonAuthRoutes;
  if (isLoggedIn) {
    routes = staffRoutes;

    console.log(permissions);

    var permittedRoutes = permissions.map((permission) => {
      var item = PermissionMappings.find((mapping) => mapping.permission === permission);
      if (item != null) {
        return item.route;
      } else {
        return null;
      }
    }
    ).filter((filter) => filter != null); // Removed nulls from array

    permissionBasedRoutes.map((route) => {
      if (permittedRoutes.indexOf(route.route) > -1) {
        Array.prototype.push.apply(routes[0].children, route.items);
      }
    });
  }

  console.log(routes);
  return useRoutes(routes);
}

