/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompositionResultDto } from './CompositionResultDto';
import {
    CompositionResultDtoFromJSON,
    CompositionResultDtoFromJSONTyped,
    CompositionResultDtoToJSON,
} from './CompositionResultDto';
import type { EhrQueryResultDto } from './EhrQueryResultDto';
import {
    EhrQueryResultDtoFromJSON,
    EhrQueryResultDtoFromJSONTyped,
    EhrQueryResultDtoToJSON,
} from './EhrQueryResultDto';

/**
 * Results for a query for pathway data
 * @export
 * @interface PathwayQueryResultsDto
 */
export interface PathwayQueryResultsDto {
    /**
     * 
     * @type {EhrQueryResultDto}
     * @memberof PathwayQueryResultsDto
     */
    promResults?: EhrQueryResultDto;
    /**
     * Procedure compositions
     * @type {Array<CompositionResultDto>}
     * @memberof PathwayQueryResultsDto
     */
    procedureCompositions?: Array<CompositionResultDto>;
    /**
     * The uuid of the pathway composition that is being updated
     * @type {string}
     * @memberof PathwayQueryResultsDto
     */
    pathwayUUID?: string;
}

/**
 * Check if a given object implements the PathwayQueryResultsDto interface.
 */
export function instanceOfPathwayQueryResultsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PathwayQueryResultsDtoFromJSON(json: any): PathwayQueryResultsDto {
    return PathwayQueryResultsDtoFromJSONTyped(json, false);
}

export function PathwayQueryResultsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayQueryResultsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promResults': !exists(json, 'promResults') ? undefined : EhrQueryResultDtoFromJSON(json['promResults']),
        'procedureCompositions': !exists(json, 'procedureCompositions') ? undefined : ((json['procedureCompositions'] as Array<any>).map(CompositionResultDtoFromJSON)),
        'pathwayUUID': !exists(json, 'pathwayUUID') ? undefined : json['pathwayUUID'],
    };
}

export function PathwayQueryResultsDtoToJSON(value?: PathwayQueryResultsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promResults': EhrQueryResultDtoToJSON(value.promResults),
        'procedureCompositions': value.procedureCompositions === undefined ? undefined : ((value.procedureCompositions as Array<any>).map(CompositionResultDtoToJSON)),
        'pathwayUUID': value.pathwayUUID,
    };
}

