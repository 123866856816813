/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Result from a request for an EHR Composition
 * @export
 * @interface CompositionResultDto
 */
export interface CompositionResultDto {
    /**
     * The composition uid requested
     * @type {string}
     * @memberof CompositionResultDto
     */
    uid?: string;
    /**
     * The resulting ehr composition
     * @type {string}
     * @memberof CompositionResultDto
     */
    composition?: string;
    /**
     * Uid of next version if this is not the latest version of the composition
     * @type {string}
     * @memberof CompositionResultDto
     */
    nextVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompositionResultDto
     */
    latest?: boolean;
}

/**
 * Check if a given object implements the CompositionResultDto interface.
 */
export function instanceOfCompositionResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompositionResultDtoFromJSON(json: any): CompositionResultDto {
    return CompositionResultDtoFromJSONTyped(json, false);
}

export function CompositionResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompositionResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'composition': !exists(json, 'composition') ? undefined : json['composition'],
        'nextVersion': !exists(json, 'nextVersion') ? undefined : json['nextVersion'],
        'latest': !exists(json, 'latest') ? undefined : json['latest'],
    };
}

export function CompositionResultDtoToJSON(value?: CompositionResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'composition': value.composition,
        'nextVersion': value.nextVersion,
        'latest': value.latest,
    };
}

