/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a user task
 * @export
 * @interface TaskDto
 */
export interface TaskDto {
    /**
     * Id
     * @type {number}
     * @memberof TaskDto
     */
    id: number;
    /**
     * Task type
     * @type {string}
     * @memberof TaskDto
     */
    taskType: string;
    /**
     * Task class
     * @type {string}
     * @memberof TaskDto
     */
    taskClass: string;
    /**
     * Task status
     * @type {string}
     * @memberof TaskDto
     */
    status: string;
    /**
     * Patient first names
     * @type {string}
     * @memberof TaskDto
     */
    patientFirstNames: string;
    /**
     * Patient last name
     * @type {string}
     * @memberof TaskDto
     */
    patientLastName: string;
    /**
     * Patient id
     * @type {string}
     * @memberof TaskDto
     */
    patientId: string;
    /**
     * Patient id namespace
     * @type {string}
     * @memberof TaskDto
     */
    patientIdNamespace: string;
    /**
     * Name of responsible clinician
     * @type {string}
     * @memberof TaskDto
     */
    clinicianName?: string;
    /**
     * Id of responsible clinician
     * @type {string}
     * @memberof TaskDto
     */
    clinicianId?: string;
    /**
     * Id of procedure
     * @type {string}
     * @memberof TaskDto
     */
    procedureId?: string;
    /**
     * Procedure name
     * @type {string}
     * @memberof TaskDto
     */
    procedureName?: string;
    /**
     * Pathway name
     * @type {string}
     * @memberof TaskDto
     */
    pathwayName?: string;
    /**
     * Pathway uuid
     * @type {string}
     * @memberof TaskDto
     */
    pathwayUID?: string;
    /**
     * Pathway id
     * @type {string}
     * @memberof TaskDto
     */
    pathwayId?: string;
    /**
     * Proms name
     * @type {string}
     * @memberof TaskDto
     */
    promsName?: string;
    /**
     * Step id
     * @type {string}
     * @memberof TaskDto
     */
    stepId?: string;
    /**
     * Due date
     * @type {string}
     * @memberof TaskDto
     */
    dueDate?: string;
    /**
     * Date completed
     * @type {string}
     * @memberof TaskDto
     */
    completionDate?: string;
}

/**
 * Check if a given object implements the TaskDto interface.
 */
export function instanceOfTaskDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "taskType" in value;
    isInstance = isInstance && "taskClass" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "patientFirstNames" in value;
    isInstance = isInstance && "patientLastName" in value;
    isInstance = isInstance && "patientId" in value;
    isInstance = isInstance && "patientIdNamespace" in value;

    return isInstance;
}

export function TaskDtoFromJSON(json: any): TaskDto {
    return TaskDtoFromJSONTyped(json, false);
}

export function TaskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'taskType': json['taskType'],
        'taskClass': json['taskClass'],
        'status': json['status'],
        'patientFirstNames': json['patientFirstNames'],
        'patientLastName': json['patientLastName'],
        'patientId': json['patientId'],
        'patientIdNamespace': json['patientIdNamespace'],
        'clinicianName': !exists(json, 'clinicianName') ? undefined : json['clinicianName'],
        'clinicianId': !exists(json, 'clinicianId') ? undefined : json['clinicianId'],
        'procedureId': !exists(json, 'procedureId') ? undefined : json['procedureId'],
        'procedureName': !exists(json, 'procedureName') ? undefined : json['procedureName'],
        'pathwayName': !exists(json, 'pathwayName') ? undefined : json['pathwayName'],
        'pathwayUID': !exists(json, 'pathwayUID') ? undefined : json['pathwayUID'],
        'pathwayId': !exists(json, 'pathwayId') ? undefined : json['pathwayId'],
        'promsName': !exists(json, 'promsName') ? undefined : json['promsName'],
        'stepId': !exists(json, 'stepId') ? undefined : json['stepId'],
        'dueDate': !exists(json, 'dueDate') ? undefined : json['dueDate'],
        'completionDate': !exists(json, 'completionDate') ? undefined : json['completionDate'],
    };
}

export function TaskDtoToJSON(value?: TaskDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taskType': value.taskType,
        'taskClass': value.taskClass,
        'status': value.status,
        'patientFirstNames': value.patientFirstNames,
        'patientLastName': value.patientLastName,
        'patientId': value.patientId,
        'patientIdNamespace': value.patientIdNamespace,
        'clinicianName': value.clinicianName,
        'clinicianId': value.clinicianId,
        'procedureId': value.procedureId,
        'procedureName': value.procedureName,
        'pathwayName': value.pathwayName,
        'pathwayUID': value.pathwayUID,
        'pathwayId': value.pathwayId,
        'promsName': value.promsName,
        'stepId': value.stepId,
        'dueDate': value.dueDate,
        'completionDate': value.completionDate,
    };
}

