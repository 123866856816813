/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HospitalSiteDto } from './HospitalSiteDto';
import {
    HospitalSiteDtoFromJSON,
    HospitalSiteDtoFromJSONTyped,
    HospitalSiteDtoToJSON,
} from './HospitalSiteDto';

/**
 * Returns hospital site search results 
 * @export
 * @interface HospitalSiteSearchResponse
 */
export interface HospitalSiteSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof HospitalSiteSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof HospitalSiteSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof HospitalSiteSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof HospitalSiteSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof HospitalSiteSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof HospitalSiteSearchResponse
     */
    orderDirection?: string;
    /**
     * List of hospital sites
     * @type {Array<HospitalSiteDto>}
     * @memberof HospitalSiteSearchResponse
     */
    sites?: Array<HospitalSiteDto>;
}

/**
 * Check if a given object implements the HospitalSiteSearchResponse interface.
 */
export function instanceOfHospitalSiteSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HospitalSiteSearchResponseFromJSON(json: any): HospitalSiteSearchResponse {
    return HospitalSiteSearchResponseFromJSONTyped(json, false);
}

export function HospitalSiteSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalSiteSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'sites': !exists(json, 'sites') ? undefined : ((json['sites'] as Array<any>).map(HospitalSiteDtoFromJSON)),
    };
}

export function HospitalSiteSearchResponseToJSON(value?: HospitalSiteSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'total': value.total,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'sites': value.sites === undefined ? undefined : ((value.sites as Array<any>).map(HospitalSiteDtoToJSON)),
    };
}

