/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcedureTemplateDto } from './ProcedureTemplateDto';
import {
    ProcedureTemplateDtoFromJSON,
    ProcedureTemplateDtoFromJSONTyped,
    ProcedureTemplateDtoToJSON,
} from './ProcedureTemplateDto';

/**
 * Returns a procedure template search
 * @export
 * @interface ProcedureTemplateSearchResponse
 */
export interface ProcedureTemplateSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof ProcedureTemplateSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof ProcedureTemplateSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof ProcedureTemplateSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof ProcedureTemplateSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof ProcedureTemplateSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof ProcedureTemplateSearchResponse
     */
    orderDirection?: string;
    /**
     * List of procedure templates items
     * @type {Array<ProcedureTemplateDto>}
     * @memberof ProcedureTemplateSearchResponse
     */
    procedureList?: Array<ProcedureTemplateDto>;
}

/**
 * Check if a given object implements the ProcedureTemplateSearchResponse interface.
 */
export function instanceOfProcedureTemplateSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcedureTemplateSearchResponseFromJSON(json: any): ProcedureTemplateSearchResponse {
    return ProcedureTemplateSearchResponseFromJSONTyped(json, false);
}

export function ProcedureTemplateSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcedureTemplateSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'procedureList': !exists(json, 'procedureList') ? undefined : ((json['procedureList'] as Array<any>).map(ProcedureTemplateDtoFromJSON)),
    };
}

export function ProcedureTemplateSearchResponseToJSON(value?: ProcedureTemplateSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'total': value.total,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'procedureList': value.procedureList === undefined ? undefined : ((value.procedureList as Array<any>).map(ProcedureTemplateDtoToJSON)),
    };
}

