/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Procedure } from './Procedure';
import {
    ProcedureFromJSON,
    ProcedureFromJSONTyped,
    ProcedureToJSON,
} from './Procedure';

/**
 * Request for an update to a pathway procedure
 * @export
 * @interface PathwayProcedureDto
 */
export interface PathwayProcedureDto {
    /**
     * 
     * @type {Procedure}
     * @memberof PathwayProcedureDto
     */
    procedure?: Procedure;
    /**
     * List of ids of subprocedure to be re-scheduled with the procedure
     * @type {Array<string>}
     * @memberof PathwayProcedureDto
     */
    subProcedureRescheduleList?: Array<string>;
    /**
     * List of ids of prom events to be re-scheduled with a sub procedure
     * @type {Array<string>}
     * @memberof PathwayProcedureDto
     */
    promRescheduleList?: Array<string>;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    userIdNamespace?: string;
    /**
     * The uuid of the pathway composition that is being updated
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    pathwayUUID?: string;
    /**
     * The id of the pathway that is being updated
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    pathwayId?: string;
    /**
     * The name of the pathway that is being updated
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    pathwayName?: string;
}

/**
 * Check if a given object implements the PathwayProcedureDto interface.
 */
export function instanceOfPathwayProcedureDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PathwayProcedureDtoFromJSON(json: any): PathwayProcedureDto {
    return PathwayProcedureDtoFromJSONTyped(json, false);
}

export function PathwayProcedureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayProcedureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'procedure': !exists(json, 'procedure') ? undefined : ProcedureFromJSON(json['procedure']),
        'subProcedureRescheduleList': !exists(json, 'subProcedureRescheduleList') ? undefined : json['subProcedureRescheduleList'],
        'promRescheduleList': !exists(json, 'promRescheduleList') ? undefined : json['promRescheduleList'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdNamespace': !exists(json, 'userIdNamespace') ? undefined : json['userIdNamespace'],
        'pathwayUUID': !exists(json, 'pathwayUUID') ? undefined : json['pathwayUUID'],
        'pathwayId': !exists(json, 'pathwayId') ? undefined : json['pathwayId'],
        'pathwayName': !exists(json, 'pathwayName') ? undefined : json['pathwayName'],
    };
}

export function PathwayProcedureDtoToJSON(value?: PathwayProcedureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'procedure': ProcedureToJSON(value.procedure),
        'subProcedureRescheduleList': value.subProcedureRescheduleList,
        'promRescheduleList': value.promRescheduleList,
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
        'pathwayUUID': value.pathwayUUID,
        'pathwayId': value.pathwayId,
        'pathwayName': value.pathwayName,
    };
}

