import { PROMS_DEV_IS_LOADING } from '../actions/Actions';
import { PROMS_DEV_FETCH_OPT_LIST } from '../actions/Actions';
import { PROMS_DEV_UPLOAD_TEMPLATE } from '../actions/Actions';
import { PROMS_DEV_HAS_ERROR } from '../actions/Actions';
import { PROMS_DEV_SUCCESS_CLEAR } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    optTemplateList: {
        proms: {
            list: [],
            currentTotal: 0
        },
        procedure: {
            list: [],
            currentTotal: 0
        }
    },
    hasError: false,
    isLoading: false,
    errorMsg: null,
    templateUpdateSuccess: false,
    templateUpdateMessage: null,
};

const templatesReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case PROMS_DEV_IS_LOADING: {
            console.log("proms dev loading", action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        }
        case PROMS_DEV_FETCH_OPT_LIST: {
            console.log("proms dev fetch opt list", action);

            const templateType = action.templateType;
            var optTemplateList = state.optTemplateList;
           
            if (action.pageStart > 0) {
                const updatedList = [...optTemplateList[templateType].list, ...action.list];

                const updatedOptTemplateList = Object.assign({}, optTemplateList[templateType], {
                    list: updatedList,
                    currentTotal: updatedList.length,
                    isLoading: false
                });

                optTemplateList[templateType] = updatedOptTemplateList;
            } else {
                console.log("**** LIST");
                const list = action.list;
                console.log(list);

                const updatedOptTemplateList = Object.assign({}, state.optTemplateList[templateType], {
                    list: list,
                    currentTotal: list.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });

                optTemplateList[templateType] = updatedOptTemplateList;
            }

            console.log(optTemplateList);

            return Object.assign({}, state, {
                optTemplateList: optTemplateList
            });
        }
        case PROMS_DEV_UPLOAD_TEMPLATE: {
            console.log("proms dev upload opt template", action);

            const templateType = action.templateType;
            const optTemplateList = state.optTemplateList;

            console.log(optTemplateList[templateType]);
            console.log(optTemplateList[templateType].list);
   
            const list = [...optTemplateList[templateType].list, action.payload];

            const updatedTemplateList = Object.assign({}, state.optTemplateList[templateType], {
                list: list ,
                currentTotal: list.length,
                total: optTemplateList[templateType].total + 1,
                isLoading: false
            });

            optTemplateList[templateType] = updatedTemplateList;

            console.log(optTemplateList);

            return Object.assign({}, state, {
                optTemplateList: optTemplateList,
                templateUpdateSuccess: true,
                templateUpdateMessage: "Opt template successfully uploaded"
            });
        }
        case PROMS_DEV_HAS_ERROR: {
            console.log("proms dev has error", action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                templateUpdateSuccess: false,
                templateUpdateMessage: null
            });
        }
        case PROMS_DEV_SUCCESS_CLEAR: {
            console.log("proms dev clear success", action);

            return Object.assign({}, state, {
                templateUpdateSuccess: false,
                templateUpdateMessage: null
            });
        }
        case LOGOUT:
        default:
            return state;
    }
}

export default templatesReducer;