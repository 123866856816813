import { UPDATE_SERVER_CONFIG } from './Actions';
import { Base64 } from 'js-base64';

const updateServerConfig = (serverUrl) => {
  return {
    type: UPDATE_SERVER_CONFIG,
    payload: {
      serverUrl: serverUrl
    }
  }
}

export default {
  updateServerConfig 
}