import { FETCH_SITES_LIST } from './Actions';
import { ADD_SITE } from './Actions';
import { UPDATE_SITE } from './Actions';
import { DELETE_SITE } from './Actions';
import { CONFIG_LOADING } from './Actions';
import { CONFIG_ERROR } from './Actions';
import { SITE_SUCCESS_CLEAR } from './Actions';
import { FETCH_ROLE_DEFAULT_PERMISSIONS } from './Actions';
import { FETCH_PROMS_EMAIL_CONFIG } from './Actions';
import { UPDATE_PROMS_EMAIL_CONFIG } from './Actions';
import { FETCH_PROMS_PRINT_CONFIG } from './Actions';
import { UPDATE_PROMS_PRINT_CONFIG } from './Actions';
import { FETCH_LANGUAGE_LIST  } from './Actions';
import { LANGUAGE_ENABLE_STATE  } from './Actions';
import { FETCH_ENABLED_LANGUAGE_LIST } from './Actions';
import { REPORTING_URLS } from './Actions';

import { v4 as uuid } from 'uuid';

import { ConfigDataEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const hasError = (bool, errorMsg) => {
    return {
        type: CONFIG_ERROR,
        hasError: bool,
        errorMsg: errorMsg
    }
};

const isLoading = (bool) => {
    return {
        type: CONFIG_LOADING,
        isLoading: bool
    }
};

const clearUpdateSuccess = () => {
    return {
        type: SITE_SUCCESS_CLEAR
    }
};


const onFetchSitesSuccess = (payload, searchTerm) => {
    console.log(payload);
    return {
        type: FETCH_SITES_LIST,
        sites: payload == null ? {} : payload.sites,
        total: payload.total,
        pageStart: payload.start,
        searchTerm: searchTerm,
        orderTerm: payload.orderTerm,
        orderDirection: payload.orderDirection,
        fetchTime: new Date().getTime()
    }
}

const fetchSites = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
    console.log('Fetching sites');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        const searchRequest  = {
            "searchTerms": {"ALL" : searchTerm},
            "pageSize": pageSize,
            "start": start,
            "orderTerm": orderTerm,
            "orderDirection": orderDirection
        }


        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.findHospitalSites({"searchRequest" : searchRequest});
        onRequest(request, dispatch, onFetchSitesSuccess, hasError, isLoading, searchTerm);
    };
};

const onAddSiteSuccess = (response, site) => {
    return {
        type: ADD_SITE,
        site: site
    }
};

const addSite = (site) => {
    console.log('Add site');

    site.id = uuid();

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.addHospitalSite({ hospitalSiteDto: site });
        onRequest(request, dispatch, onAddSiteSuccess, hasError, isLoading, site);
    };
};

const onEditSiteSuccess = (response, site) => {
    return {
        type: UPDATE_SITE,
        site: site
    }
};

const editSite = (site) => {
    console.log('Edit site');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.addHospitalSite({ hospitalSiteDto: site });
        onRequest(request, dispatch, onEditSiteSuccess, hasError, isLoading, site);
    };
};

const onDeleteSiteSuccess = (response, site) => {
    return {
        type: DELETE_SITE,
        site: site
    }
};

const deleteSite = (site) => {
    console.log('Delete site');
    console.log(site);

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.deleteHospitalSite({ siteId: site.id, siteName: site.site });
        onRequest(request, dispatch, onDeleteSiteSuccess, hasError, isLoading, site);
    };
};

const onFetchRolePermissionsConfigSuccess = (response) => {
    console.log(response);
    return {
        type: FETCH_ROLE_DEFAULT_PERMISSIONS,
        rolePermissions: response.rolePermissions,
        permissionDescriptions: response.permissionDescriptions
    }
}

const fetchRolePermissionsConfig = () => {
    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.getPermissionConfig();
        onRequest(request, dispatch, onFetchRolePermissionsConfigSuccess, hasError, isLoading);
    };
};

const onFetchPromsEmailConfigSuccess = (response) => {
    console.log(response);
    return {
        type: FETCH_PROMS_EMAIL_CONFIG,
        promsEmailConfigs: response
    }
}

const getPromsEmailConfig = () => {
    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.getPromsEmailConfig();
        onRequest(request, dispatch, onFetchPromsEmailConfigSuccess, hasError, isLoading);
    };
}

const onUpdatePromsEmailConfigSuccess = (response, promsEmailConfig) => {
    return {
        type: UPDATE_PROMS_EMAIL_CONFIG,
        promsEmailConfig: promsEmailConfig
    }
};

const setPromsEmailConfig = (promsEmailConfig) => {
    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.addPromsEmailConfig({ promsEmailConfigDto: promsEmailConfig });
        onRequest(request, dispatch, onUpdatePromsEmailConfigSuccess, hasError, isLoading, promsEmailConfig);
    };
}

const onFetchPromsPrintConfigSuccess = (response) => {
    console.log(response);
    return {
        type: FETCH_PROMS_PRINT_CONFIG,
        promsPrintConfig: response
    }
}

const getPromsPrintConfig = () => {
    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.getPromsPrintingConfig();
        onRequest(request, dispatch, onFetchPromsPrintConfigSuccess, hasError, isLoading);
    };
}

const onSetPrintedPromsConfigSuccess = (response, promsPrintConfig) => {
    return {
        type: UPDATE_PROMS_PRINT_CONFIG,
        promsPrintConfig: promsPrintConfig
    }
};

const setPrintedPromsConfig = (promsPrintedConfig) => {
    console.log(promsPrintedConfig);
    
    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.addPromsPrintingConfig({ promsPrintingConfigDto: promsPrintedConfig });
        onRequest(request, dispatch, onSetPrintedPromsConfigSuccess, hasError, isLoading, promsPrintedConfig);
    };
}

const onFetchEnabledLanguagesSuccess = (response) => {
    console.log(response);
    return {
        type: FETCH_ENABLED_LANGUAGE_LIST,
        enabledLanguages: response
    }
}

const getEnabledLanguages = () => {
    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.getEnabledSupportedLanguages();
        onRequest(request, dispatch, onFetchEnabledLanguagesSuccess, hasError, isLoading);
    };
}

const onFetchLanguagesSuccess = (payload, searchTerm) => {
    console.log(payload);
    return {
        type: FETCH_LANGUAGE_LIST,
        supportedLanguages: payload == null ? {} : payload.languageDtos,
        total: payload.total,
        pageStart: payload.start,
        searchTerm: searchTerm,
        orderTerm: payload.orderTerm,
        orderDirection: payload.orderDirection,
        fetchTime: new Date().getTime()
    }
}

const fetchLanguages = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
    console.log('Fetching languages');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        const searchRequest  = {
            "searchTerms": {"ALL" : searchTerm},
            "pageSize": pageSize,
            "start": start,
            "orderTerm": orderTerm,
            "orderDirection": orderDirection
        }


        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.getSupportedLanguages({"searchRequest" : searchRequest});
        onRequest(request, dispatch, onFetchLanguagesSuccess, hasError, isLoading, searchTerm);
    };
};

const onEnabledLanguagesSuccess = (response, languageDto) => {
    console.log(response);
    return {
        type: LANGUAGE_ENABLE_STATE,
        enabledLanguages: response,
        languageCode: languageDto.languageCode,
        enable: languageDto.enabled
    }
}

const enableLanguage = (languageCode, enable) => {
    console.log('Enable language');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        const languageDto  = {
            "languageCode": languageCode,
            "enabled": enable
        }

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.updateSupportedLanguage({"updateSupportedLanguageDto" : languageDto});
        onRequest(request, dispatch, onEnabledLanguagesSuccess, hasError, isLoading, languageDto);
    };
};


const onFetchReportingUrlsSuccess = (payload) => {
    console.log(payload);
    return {
        type: REPORTING_URLS,
        reportUrls: payload
    }
}

const fetchReportingUrls = () => {
    console.log('Fetch reporting urls');

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);
        var configDataApi = new ConfigDataEndpointApi(config);
        var request = configDataApi.getReportUrls();
        onRequest(request, dispatch, onFetchReportingUrlsSuccess, hasError, isLoading);
    };
};

export default {
    fetchRolePermissionsConfig,
    fetchSites,
    addSite,
    editSite,
    deleteSite,
    clearUpdateSuccess,
    getPromsEmailConfig,
    setPromsEmailConfig,
    hasError,
    getPromsPrintConfig,
    setPrintedPromsConfig,
    getEnabledLanguages,
    fetchLanguages,
    enableLanguage,
    fetchReportingUrls
}