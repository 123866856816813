/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents an audit entry
 * @export
 * @interface ExternalAuditDto
 */
export interface ExternalAuditDto {
    /**
     * Type
     * @type {string}
     * @memberof ExternalAuditDto
     */
    type: string;
    /**
     * Action
     * @type {string}
     * @memberof ExternalAuditDto
     */
    action: string;
    /**
     * Attributes
     * @type {{ [key: string]: string; }}
     * @memberof ExternalAuditDto
     */
    attributes: { [key: string]: string; };
}

/**
 * Check if a given object implements the ExternalAuditDto interface.
 */
export function instanceOfExternalAuditDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "attributes" in value;

    return isInstance;
}

export function ExternalAuditDtoFromJSON(json: any): ExternalAuditDto {
    return ExternalAuditDtoFromJSONTyped(json, false);
}

export function ExternalAuditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAuditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'action': json['action'],
        'attributes': json['attributes'],
    };
}

export function ExternalAuditDtoToJSON(value?: ExternalAuditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'action': value.action,
        'attributes': value.attributes,
    };
}

