import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import { Provider } from 'react-redux';

import configureStore from './Store';
import { PersistGate } from 'redux-persist/integration/react'

import './i18n/config';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./KeyCloak"

import { Provider as RollbarProvider, ErrorBoundary, LEVEL_WARN } from '@rollbar/react';

const { persistor, store } = configureStore();

var rollbarConfig = {
  enabled: window._env_.REACT_APP_ROLLBAR_ACCESS_TOKEN !== "",
  accessToken: window._env_.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
      environment: window._env_.REACT_APP_ROLLBAR_ENVIRONMENT
  }
};

const eventLogger = (event, error) => {
  console.log('*** onKeycloakEvent', event, error);
};

ReactDOM.render((
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary level={LEVEL_WARN}>
      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ReactKeycloakProvider>
    </ErrorBoundary>
  </RollbarProvider>
), document.getElementById('root'));

serviceWorker.unregister();
