import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';

import { connect } from 'react-redux';
import { LoginActions, AuditActions } from 'src/comms';

import { useKeycloak } from "@react-keycloak/web";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 192
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  }
}));

const DashboardLayout = (props) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const onLogout = () => {
    navigate('/app/dashboard', { replace: true });
    props.dispatch(AuditActions.userLoggedOut());
    keycloak.logout();
    props.dispatch(LoginActions.logout());
  }

  return (
    <div className={classes.root}>
      <TopBar 
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onLogout={() => onLogout()}
        />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        adminMode={props.adminMode}
        permissions={props.permissions}
        rolePermissions={props.rolePermissions}
        staffProfile={props.staffProfile}
        reportingUrls={props.reportingUrls}
        onLogout={() => onLogout()}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adminMode: state.login.adminMode,
    permissions: state.login.permissions,
    staffProfile: state.hcpData.hcpProfile,
    rolePermissions: state.configResources.rolePermissions,
    reportingUrls: state.configResources.reportingUrls
  };
}

const connectedDashboardLayout = connect(mapStateToProps)(DashboardLayout);
export default connectedDashboardLayout;
