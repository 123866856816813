/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseRateDto } from './ResponseRateDto';
import {
    ResponseRateDtoFromJSON,
    ResponseRateDtoFromJSONTyped,
    ResponseRateDtoToJSON,
} from './ResponseRateDto';
import type { TaskTypeCountDto } from './TaskTypeCountDto';
import {
    TaskTypeCountDtoFromJSON,
    TaskTypeCountDtoFromJSONTyped,
    TaskTypeCountDtoToJSON,
} from './TaskTypeCountDto';

/**
 * 
 * @export
 * @interface WidgetDataResponse
 */
export interface WidgetDataResponse {
    /**
     * 
     * @type {Array<TaskTypeCountDto>}
     * @memberof WidgetDataResponse
     */
    taskTypeCountList?: Array<TaskTypeCountDto>;
    /**
     * 
     * @type {Array<ResponseRateDto>}
     * @memberof WidgetDataResponse
     */
    responseRateList?: Array<ResponseRateDto>;
    /**
     * 
     * @type {number}
     * @memberof WidgetDataResponse
     */
    overDueProms?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetDataResponse
     */
    expiringProms?: number;
}

/**
 * Check if a given object implements the WidgetDataResponse interface.
 */
export function instanceOfWidgetDataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WidgetDataResponseFromJSON(json: any): WidgetDataResponse {
    return WidgetDataResponseFromJSONTyped(json, false);
}

export function WidgetDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskTypeCountList': !exists(json, 'taskTypeCountList') ? undefined : ((json['taskTypeCountList'] as Array<any>).map(TaskTypeCountDtoFromJSON)),
        'responseRateList': !exists(json, 'responseRateList') ? undefined : ((json['responseRateList'] as Array<any>).map(ResponseRateDtoFromJSON)),
        'overDueProms': !exists(json, 'overDueProms') ? undefined : json['overDueProms'],
        'expiringProms': !exists(json, 'expiringProms') ? undefined : json['expiringProms'],
    };
}

export function WidgetDataResponseToJSON(value?: WidgetDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskTypeCountList': value.taskTypeCountList === undefined ? undefined : ((value.taskTypeCountList as Array<any>).map(TaskTypeCountDtoToJSON)),
        'responseRateList': value.responseRateList === undefined ? undefined : ((value.responseRateList as Array<any>).map(ResponseRateDtoToJSON)),
        'overDueProms': value.overDueProms,
        'expiringProms': value.expiringProms,
    };
}

