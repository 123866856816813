/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiError,
  ApiValidationErrors,
  PatientSearchResponse,
  ProfileDto,
  PromsEmailDto,
  SearchRequest,
} from '../models';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    PatientSearchResponseFromJSON,
    PatientSearchResponseToJSON,
    ProfileDtoFromJSON,
    ProfileDtoToJSON,
    PromsEmailDtoFromJSON,
    PromsEmailDtoToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
} from '../models';

export interface Create1Request {
    profileDto: ProfileDto;
}

export interface FindPatientsRequest {
    searchRequest: SearchRequest;
}

export interface FindPatients1Request {
    start: number;
    pageSize: number;
}

export interface GetProfile2Request {
    userId: string;
    userIdNamespace: string;
}

export interface SendPromsEmailRequest {
    promsEmailDto: PromsEmailDto;
}

export interface UpdateRequest {
    profileDto: ProfileDto;
}

/**
 * 
 */
export class PatientsEndpointApi extends runtime.BaseAPI {

    /**
     * Creates a new user
     * create
     */
    async create1Raw(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.profileDto === null || requestParameters.profileDto === undefined) {
            throw new runtime.RequiredError('profileDto','Required parameter requestParameters.profileDto was null or undefined when calling create1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileDtoToJSON(requestParameters.profileDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new user
     * create
     */
    async create1(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.create1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of patients with search terms
     * patients
     */
    async findPatientsRaw(requestParameters: FindPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientSearchResponse>> {
        if (requestParameters.searchRequest === null || requestParameters.searchRequest === undefined) {
            throw new runtime.RequiredError('searchRequest','Required parameter requestParameters.searchRequest was null or undefined when calling findPatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of patients with search terms
     * patients
     */
    async findPatients(requestParameters: FindPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientSearchResponse> {
        const response = await this.findPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of patients
     * patients
     */
    async findPatients1Raw(requestParameters: FindPatients1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientSearchResponse>> {
        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling findPatients1.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling findPatients1.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of patients
     * patients
     */
    async findPatients1(requestParameters: FindPatients1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientSearchResponse> {
        const response = await this.findPatients1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches current profile details for user
     * profile
     */
    async getProfile2Raw(requestParameters: GetProfile2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getProfile2.');
        }

        if (requestParameters.userIdNamespace === null || requestParameters.userIdNamespace === undefined) {
            throw new runtime.RequiredError('userIdNamespace','Required parameter requestParameters.userIdNamespace was null or undefined when calling getProfile2.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.userIdNamespace !== undefined) {
            queryParameters['userIdNamespace'] = requestParameters.userIdNamespace;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Fetches current profile details for user
     * profile
     */
    async getProfile2(requestParameters: GetProfile2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.getProfile2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used to send proms email to patient
     * promsEmail
     */
    async sendPromsEmailRaw(requestParameters: SendPromsEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.promsEmailDto === null || requestParameters.promsEmailDto === undefined) {
            throw new runtime.RequiredError('promsEmailDto','Required parameter requestParameters.promsEmailDto was null or undefined when calling sendPromsEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/promsEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PromsEmailDtoToJSON(requestParameters.promsEmailDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to send proms email to patient
     * promsEmail
     */
    async sendPromsEmail(requestParameters: SendPromsEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendPromsEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing user
     * update
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.profileDto === null || requestParameters.profileDto === undefined) {
            throw new runtime.RequiredError('profileDto','Required parameter requestParameters.profileDto was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileDtoToJSON(requestParameters.profileDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Update an existing user
     * update
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
