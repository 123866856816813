/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubProcedureCode
 */
export interface SubProcedureCode {
    /**
     * 
     * @type {string}
     * @memberof SubProcedureCode
     */
    exportMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof SubProcedureCode
     */
    specificCode?: string;
}

/**
 * Check if a given object implements the SubProcedureCode interface.
 */
export function instanceOfSubProcedureCode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubProcedureCodeFromJSON(json: any): SubProcedureCode {
    return SubProcedureCodeFromJSONTyped(json, false);
}

export function SubProcedureCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubProcedureCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportMethod': !exists(json, 'exportMethod') ? undefined : json['exportMethod'],
        'specificCode': !exists(json, 'specificCode') ? undefined : json['specificCode'],
    };
}

export function SubProcedureCodeToJSON(value?: SubProcedureCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportMethod': value.exportMethod,
        'specificCode': value.specificCode,
    };
}

