/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Number of occurrences of a task type
 * @export
 * @interface TaskTypeCountDto
 */
export interface TaskTypeCountDto {
    /**
     * Task type
     * @type {string}
     * @memberof TaskTypeCountDto
     */
    taskType: string;
    /**
     * Task class
     * @type {number}
     * @memberof TaskTypeCountDto
     */
    count: number;
}

/**
 * Check if a given object implements the TaskTypeCountDto interface.
 */
export function instanceOfTaskTypeCountDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskType" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function TaskTypeCountDtoFromJSON(json: any): TaskTypeCountDto {
    return TaskTypeCountDtoFromJSONTyped(json, false);
}

export function TaskTypeCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskTypeCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskType': json['taskType'],
        'count': json['count'],
    };
}

export function TaskTypeCountDtoToJSON(value?: TaskTypeCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskType': value.taskType,
        'count': value.count,
    };
}

