/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Accessible name localisation
 * @export
 * @interface AccessibleNameDto
 */
export interface AccessibleNameDto {
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    localisation?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    languageName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    localisedName?: string;
}

/**
 * Check if a given object implements the AccessibleNameDto interface.
 */
export function instanceOfAccessibleNameDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccessibleNameDtoFromJSON(json: any): AccessibleNameDto {
    return AccessibleNameDtoFromJSONTyped(json, false);
}

export function AccessibleNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessibleNameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localisation': !exists(json, 'localisation') ? undefined : json['localisation'],
        'languageCode': !exists(json, 'languageCode') ? undefined : json['languageCode'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'languageName': !exists(json, 'languageName') ? undefined : json['languageName'],
        'localisedName': !exists(json, 'localisedName') ? undefined : json['localisedName'],
    };
}

export function AccessibleNameDtoToJSON(value?: AccessibleNameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localisation': value.localisation,
        'languageCode': value.languageCode,
        'language': value.language,
        'languageName': value.languageName,
        'localisedName': value.localisedName,
    };
}

