/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents the proms email configuration
 * @export
 * @interface PromsEmailConfigDto
 */
export interface PromsEmailConfigDto {
    /**
     * Enabled
     * @type {boolean}
     * @memberof PromsEmailConfigDto
     */
    enabled: boolean;
    /**
     * Communication type
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    type: PromsEmailConfigDtoTypeEnum;
    /**
     * Id
     * @type {number}
     * @memberof PromsEmailConfigDto
     */
    id: number;
    /**
     * Name
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    name: string;
    /**
     * Number days before or after the operation the email should be sent
     * @type {number}
     * @memberof PromsEmailConfigDto
     */
    days: number;
    /**
     * Procedure type; Used for email being sent before or after procedure
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    procedureType: PromsEmailConfigDtoProcedureTypeEnum;
    /**
     * Index
     * @type {number}
     * @memberof PromsEmailConfigDto
     */
    index: number;
    /**
     * Email body template
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailBody?: string;
    /**
     * Email subject template
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailSubject?: string;
    /**
     * Email sender's email address
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailSender?: string;
    /**
     * Email sender
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailSenderAddress?: string;
    /**
     * If this must be sent
     * @type {boolean}
     * @memberof PromsEmailConfigDto
     */
    mandatory?: boolean;
}


/**
 * @export
 */
export const PromsEmailConfigDtoTypeEnum = {
    Long: 'Long',
    Short: 'Short'
} as const;
export type PromsEmailConfigDtoTypeEnum = typeof PromsEmailConfigDtoTypeEnum[keyof typeof PromsEmailConfigDtoTypeEnum];

/**
 * @export
 */
export const PromsEmailConfigDtoProcedureTypeEnum = {
    None: 'None',
    Pre: 'Pre',
    Post: 'Post'
} as const;
export type PromsEmailConfigDtoProcedureTypeEnum = typeof PromsEmailConfigDtoProcedureTypeEnum[keyof typeof PromsEmailConfigDtoProcedureTypeEnum];


/**
 * Check if a given object implements the PromsEmailConfigDto interface.
 */
export function instanceOfPromsEmailConfigDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "days" in value;
    isInstance = isInstance && "procedureType" in value;
    isInstance = isInstance && "index" in value;

    return isInstance;
}

export function PromsEmailConfigDtoFromJSON(json: any): PromsEmailConfigDto {
    return PromsEmailConfigDtoFromJSONTyped(json, false);
}

export function PromsEmailConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromsEmailConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'type': json['type'],
        'id': json['id'],
        'name': json['name'],
        'days': json['days'],
        'procedureType': json['procedureType'],
        'index': json['index'],
        'emailBody': !exists(json, 'emailBody') ? undefined : json['emailBody'],
        'emailSubject': !exists(json, 'emailSubject') ? undefined : json['emailSubject'],
        'emailSender': !exists(json, 'emailSender') ? undefined : json['emailSender'],
        'emailSenderAddress': !exists(json, 'emailSenderAddress') ? undefined : json['emailSenderAddress'],
        'mandatory': !exists(json, 'mandatory') ? undefined : json['mandatory'],
    };
}

export function PromsEmailConfigDtoToJSON(value?: PromsEmailConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'type': value.type,
        'id': value.id,
        'name': value.name,
        'days': value.days,
        'procedureType': value.procedureType,
        'index': value.index,
        'emailBody': value.emailBody,
        'emailSubject': value.emailSubject,
        'emailSender': value.emailSender,
        'emailSenderAddress': value.emailSenderAddress,
        'mandatory': value.mandatory,
    };
}

