/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a hospital site
 * @export
 * @interface HospitalSiteDto
 */
export interface HospitalSiteDto {
    /**
     * Id
     * @type {string}
     * @memberof HospitalSiteDto
     */
    id: string;
    /**
     * Hospital site
     * @type {string}
     * @memberof HospitalSiteDto
     */
    site: string;
    /**
     * Location of the hospital site
     * @type {string}
     * @memberof HospitalSiteDto
     */
    location: string;
}

/**
 * Check if a given object implements the HospitalSiteDto interface.
 */
export function instanceOfHospitalSiteDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "site" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function HospitalSiteDtoFromJSON(json: any): HospitalSiteDto {
    return HospitalSiteDtoFromJSONTyped(json, false);
}

export function HospitalSiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalSiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'site': json['site'],
        'location': json['location'],
    };
}

export function HospitalSiteDtoToJSON(value?: HospitalSiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'site': value.site,
        'location': value.location,
    };
}

