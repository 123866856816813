/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';

/**
 * Request for an update to a pathway step
 * @export
 * @interface PathwayStepDto
 */
export interface PathwayStepDto {
    /**
     * 
     * @type {Step}
     * @memberof PathwayStepDto
     */
    step?: Step;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayStepDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayStepDto
     */
    userIdNamespace?: string;
    /**
     * The uuid of the pathway composition that is being updated
     * @type {string}
     * @memberof PathwayStepDto
     */
    pathwayUUID?: string;
    /**
     * The id of the pathway that is being updated
     * @type {string}
     * @memberof PathwayStepDto
     */
    pathwayId?: string;
    /**
     * The name of the pathway that is being updated
     * @type {string}
     * @memberof PathwayStepDto
     */
    pathwayName?: string;
}

/**
 * Check if a given object implements the PathwayStepDto interface.
 */
export function instanceOfPathwayStepDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PathwayStepDtoFromJSON(json: any): PathwayStepDto {
    return PathwayStepDtoFromJSONTyped(json, false);
}

export function PathwayStepDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayStepDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': !exists(json, 'step') ? undefined : StepFromJSON(json['step']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdNamespace': !exists(json, 'userIdNamespace') ? undefined : json['userIdNamespace'],
        'pathwayUUID': !exists(json, 'pathwayUUID') ? undefined : json['pathwayUUID'],
        'pathwayId': !exists(json, 'pathwayId') ? undefined : json['pathwayId'],
        'pathwayName': !exists(json, 'pathwayName') ? undefined : json['pathwayName'],
    };
}

export function PathwayStepDtoToJSON(value?: PathwayStepDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': StepToJSON(value.step),
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
        'pathwayUUID': value.pathwayUUID,
        'pathwayId': value.pathwayId,
        'pathwayName': value.pathwayName,
    };
}

