/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents the parameters for a query openEhr.
 * @export
 * @interface EhrQueryDto
 */
export interface EhrQueryDto {
    /**
     * The columns to select in the query, if no query template is supplied. Comma separators will be inserted between each item.
     * @type {Array<string>}
     * @memberof EhrQueryDto
     */
    selectColumns?: Array<string>;
    /**
     * The conditional clauses for the query, including AQL contains or where terms, if no parameter map is supplied
     * @type {Array<string>}
     * @memberof EhrQueryDto
     */
    whereClauses?: Array<string>;
    /**
     * The primaryEntityName of the query template, if one exists, which has previously been registered with the server for the app to request a set of query terms
     * @type {string}
     * @memberof EhrQueryDto
     */
    queryTemplate?: string;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof EhrQueryDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof EhrQueryDto
     */
    userIdNamespace?: string;
    /**
     * The primaryEntityName of the parameter map, if one exists, which has previously been registered with the server for the app to map simple query terms to openEhr where clauses
     * @type {string}
     * @memberof EhrQueryDto
     */
    parameterMap?: string;
    /**
     * The map of parameters to use with the parameter map
     * @type {{ [key: string]: string; }}
     * @memberof EhrQueryDto
     */
    parameters?: { [key: string]: string; };
    /**
     * The paging start value
     * @type {number}
     * @memberof EhrQueryDto
     */
    start?: number;
    /**
     * Paging page size
     * @type {number}
     * @memberof EhrQueryDto
     */
    pagesize?: number;
    /**
     * Start range for time bounded queries
     * @type {number}
     * @memberof EhrQueryDto
     */
    startDateTime?: number;
    /**
     * End range for time bounded queries
     * @type {number}
     * @memberof EhrQueryDto
     */
    endDateTime?: number;
}

/**
 * Check if a given object implements the EhrQueryDto interface.
 */
export function instanceOfEhrQueryDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EhrQueryDtoFromJSON(json: any): EhrQueryDto {
    return EhrQueryDtoFromJSONTyped(json, false);
}

export function EhrQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EhrQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectColumns': !exists(json, 'selectColumns') ? undefined : json['selectColumns'],
        'whereClauses': !exists(json, 'whereClauses') ? undefined : json['whereClauses'],
        'queryTemplate': !exists(json, 'queryTemplate') ? undefined : json['queryTemplate'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdNamespace': !exists(json, 'userIdNamespace') ? undefined : json['userIdNamespace'],
        'parameterMap': !exists(json, 'parameterMap') ? undefined : json['parameterMap'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'pagesize': !exists(json, 'pagesize') ? undefined : json['pagesize'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : json['startDateTime'],
        'endDateTime': !exists(json, 'endDateTime') ? undefined : json['endDateTime'],
    };
}

export function EhrQueryDtoToJSON(value?: EhrQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectColumns': value.selectColumns,
        'whereClauses': value.whereClauses,
        'queryTemplate': value.queryTemplate,
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
        'parameterMap': value.parameterMap,
        'parameters': value.parameters,
        'start': value.start,
        'pagesize': value.pagesize,
        'startDateTime': value.startDateTime,
        'endDateTime': value.endDateTime,
    };
}

