import { FETCH_STAFF_LIST } from '../actions/Actions';
import { ADD_STAFF_PROFILE } from '../actions/Actions';
import { UPDATE_STAFF_PROFILE } from '../actions/Actions';
import { DELETE_STAFF_PROFILE } from '../actions/Actions';
import { STAFF_UPDATE_ERROR } from '../actions/Actions';
import { STAFF_LOADING } from '../actions/Actions';
import { STAFF_SUCCESS_CLEAR } from '../actions/Actions';
import { STAFF_PASSWORD_RESET_SUCCESS } from '../actions/Actions';
import { DISABLE_STAFF_PROFILE } from '../actions/Actions';
import { FETCH_CLINICAL_STAFF_LIST } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    staffList: [],

    hasError: false,
    isLoading: false,
    errorMsg: null,
    updateSuccess: false,
    updateSuccessMessage: null
};

function updateStaffProfileInArray(array, staff) {
    return array.map((item, index) => {
      if (item.username !== staff.username) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
  
      return {
        ...item,
        ...staff
      };
    });
  }

const staffReducer = (state = initialState, action) => {
    const { type } = action;
    
    switch (type) {
        case FETCH_STAFF_LIST: {
            console.log('Staff reducer ', action);
            const staffList = action.staffList;

            if (action.pageStart > 0) {
                const updatedStaffArray = [...state.staffList, ...staffList];

                return Object.assign({}, state, {
                    staffList: updatedStaffArray,
                    currentTotal: updatedStaffArray.length,
                    isLoading: false
                });
            } else {
                return Object.assign({}, state, {
                    staffList: staffList,
                    currentTotal: staffList.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });
            }
        }
        case ADD_STAFF_PROFILE: {
            console.log('Staff reducer ', action);
            console.log(state.staffList);
            return Object.assign({}, state, {
                staffList: [...state.staffList, action.staffProfile],
                isLoading: false
            });
        }
        case UPDATE_STAFF_PROFILE:{
            console.log('Staff reducer ', action);
            var updatedArray = updateStaffProfileInArray(state.staffList, action.staffProfile);
            return Object.assign({}, state, {
                staffList: updatedArray,
                isLoading: false
            });
        }
        case DELETE_STAFF_PROFILE: {
            console.log('Staff reducer ', action);
            var deletedProfile = action.staffProfile;
            var updatedArray = state.staffList.filter(profile => profile.username !== deletedProfile.username);
            return Object.assign({}, state, {
                staffList: updatedArray,
                isLoading: false
            });
        }
        case DISABLE_STAFF_PROFILE: {
            console.log('profile disabled success', action);
            var updatedArray = updateStaffProfileInArray(state.staffList, action.staffProfile);
            return Object.assign({}, state, {
                staffList: updatedArray,
                isLoading: false
            });
        }
        case LOGOUT:
            console.log('Staff reducer ', action);
            return initialState;
        case STAFF_UPDATE_ERROR:
            console.log('Staff reducer - haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                updateSuccess: false
            });
        case STAFF_LOADING:
            console.log('Staff reducer - isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case STAFF_SUCCESS_CLEAR: {
            console.log('Staff reducer - sucess clear', action);

            return Object.assign({}, state, {
                updateSuccess: false,
                updateSuccessMessage:null
            });
        }
        case STAFF_PASSWORD_RESET_SUCCESS: {
            console.log('Staff reducer - password reset success', action);
            return Object.assign({}, state, {
                updateSuccess: true,
                updateSuccessMessage: "Password request made"
            });
        }
        default:
            return state;
    }
}

export default staffReducer;