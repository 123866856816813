/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PathwayDto } from './PathwayDto';
import {
    PathwayDtoFromJSON,
    PathwayDtoFromJSONTyped,
    PathwayDtoToJSON,
} from './PathwayDto';

/**
 * Request for an Pathway composition
 * @export
 * @interface PathwayCompositionDto
 */
export interface PathwayCompositionDto {
    /**
     * 
     * @type {PathwayDto}
     * @memberof PathwayCompositionDto
     */
    pathway?: PathwayDto;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayCompositionDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayCompositionDto
     */
    userIdNamespace?: string;
}

/**
 * Check if a given object implements the PathwayCompositionDto interface.
 */
export function instanceOfPathwayCompositionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PathwayCompositionDtoFromJSON(json: any): PathwayCompositionDto {
    return PathwayCompositionDtoFromJSONTyped(json, false);
}

export function PathwayCompositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayCompositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pathway': !exists(json, 'pathway') ? undefined : PathwayDtoFromJSON(json['pathway']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdNamespace': !exists(json, 'userIdNamespace') ? undefined : json['userIdNamespace'],
    };
}

export function PathwayCompositionDtoToJSON(value?: PathwayCompositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pathway': PathwayDtoToJSON(value.pathway),
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
    };
}

