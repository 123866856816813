/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupportedLanguageDto } from './SupportedLanguageDto';
import {
    SupportedLanguageDtoFromJSON,
    SupportedLanguageDtoFromJSONTyped,
    SupportedLanguageDtoToJSON,
} from './SupportedLanguageDto';

/**
 * Returns supported languages search results 
 * @export
 * @interface SupportedLanguagesSearchResponse
 */
export interface SupportedLanguagesSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof SupportedLanguagesSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof SupportedLanguagesSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof SupportedLanguagesSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof SupportedLanguagesSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof SupportedLanguagesSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof SupportedLanguagesSearchResponse
     */
    orderDirection?: string;
    /**
     * List of supported languages
     * @type {Array<SupportedLanguageDto>}
     * @memberof SupportedLanguagesSearchResponse
     */
    languageDtos?: Array<SupportedLanguageDto>;
}

/**
 * Check if a given object implements the SupportedLanguagesSearchResponse interface.
 */
export function instanceOfSupportedLanguagesSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupportedLanguagesSearchResponseFromJSON(json: any): SupportedLanguagesSearchResponse {
    return SupportedLanguagesSearchResponseFromJSONTyped(json, false);
}

export function SupportedLanguagesSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedLanguagesSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'languageDtos': !exists(json, 'languageDtos') ? undefined : ((json['languageDtos'] as Array<any>).map(SupportedLanguageDtoFromJSON)),
    };
}

export function SupportedLanguagesSearchResponseToJSON(value?: SupportedLanguagesSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'total': value.total,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'languageDtos': value.languageDtos === undefined ? undefined : ((value.languageDtos as Array<any>).map(SupportedLanguageDtoToJSON)),
    };
}

