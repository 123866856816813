import { FETCH_PATHWAY_LIST } from './Actions';
import { FETCH_PATHWAYS } from "./Actions";
import { ADD_PATHWAY } from './Actions';
import { UPDATE_PATHWAY  } from './Actions';
import { DELETE_PATHWAY } from './Actions';
import { PATHWAY_LOADING  } from './Actions';
import { PATHWAY_UPDATE_ERROR  } from './Actions';
import { PATHWAY_SUCCESS_CLEAR  } from './Actions';

import { v4 as uuid } from 'uuid';

import { PathwayTemplateEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const clearError = () => {
    return {
      type: PATHWAY_UPDATE_ERROR,
      hasError: false
    }
  };

const hasError = (bool, errorMsg) => {
    return {
        type: PATHWAY_UPDATE_ERROR,
        hasError: bool,
        errorMsg: errorMsg
    }
};

const isLoading = (bool) => {
    return {
        type: PATHWAY_LOADING,
        isLoading: bool
    }
};

const clearUpdateSuccess = () => {
    return {
        type: PATHWAY_SUCCESS_CLEAR
    }
};

const fetchPathwayListSuccessHandler = (payload, searchTerm) => {
    console.log("++++++++++++");
    console.log(payload);
    return {
        type: FETCH_PATHWAY_LIST,
        pathwayList: payload == null ? {} : payload.pathwayList,
        total: payload.total,
        pageStart: payload.start,
        searchTerm: searchTerm,
        orderTerm: payload.orderTerm,
        orderDirection: payload.orderDirection,
        fetchTime: new Date().getTime()
    }
};


const fetchPathwayList = (searchTerm, pageSize, start, orderTerm, orderDirection, storeSearchTerm) => {
    console.log('Fetching pathway list');
    console.log(searchTerm);

    return (dispatch, getState) => {
        dispatch(isLoading(true));

        var config = getConfiguation(dispatch, getState);

        // Split search term 
        var searchTermMapping = {
            "NAME" : searchTerm   
        };

        const searchRequest = {
            "searchTerms": searchTermMapping,
            "pageSize": pageSize,
            "start": start,
            "orderTerm": orderTerm,
            "orderDirection": orderDirection
        }

        var pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
        var request = pathwayTemplateApi.getTemplateList({ "searchRequest": searchRequest });

        onRequest(request, dispatch, fetchPathwayListSuccessHandler, hasError, isLoading, searchTerm, "Error fetching pathways")
    };
};


const onFetchPathwaysSuccess  = (response) => {
    return {
      type: FETCH_PATHWAYS,
      pathways: response,
      success: true
    }
  };

const fetchPathways = (templateIds) => {
    console.log("Fetch template");
    console.log(templateIds);

    return (dispatch, getState) => {
      dispatch(isLoading(true));

      var  ids = [templateIds];
  
      var config = getConfiguation(dispatch, getState);
      var pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
      var request = pathwayTemplateApi.getPathwayTemplateJson({ids : ids});
      onRequest(request, dispatch, onFetchPathwaysSuccess,hasError, isLoading, null, "Error fetching pathways");
  };
}

const addPathwaySuccess = (pathway, body) => {
    return {
        type: ADD_PATHWAY,
        pathway: pathway,
        pathwayListItem: {
            id: body.id,
            description: body.description,
            name: body.name,
            summary: body.summary
        }
    }
};

const addPathway= (pathwayTemplate) => {
    console.log("upload pathway Template");
    console.log(pathwayTemplate);

    pathwayTemplate.id = uuid();

    var summary = pathwayTemplate.steps.map((step) => step.title).join(" -> ");

    const primaryProcedure = pathwayTemplate.steps.find((step) => step.id === 0);
    const prepopulatedFields = primaryProcedure.prepopulatedFields;

    const body = {
        name: pathwayTemplate.title,
        description: pathwayTemplate.description,
        id: pathwayTemplate.id,
        template : JSON.stringify(pathwayTemplate),
        summary: summary,
        prepopulatedFields: prepopulatedFields
    }

    return (dispatch, getState) => {
      dispatch(isLoading(true));
  
      var config = getConfiguation(dispatch, getState);
      var pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
      var request = pathwayTemplateApi.importPathwayTemplate({pathwayTemplateDto: body})
      onRequest(request, dispatch, addPathwaySuccess, hasError, isLoading, body, "Error adding pathway");
  };
  }


const editPathwaySuccess = (pathway) => {
    return {
        type: UPDATE_PATHWAY,
        pathway: pathway
    }
};

const editPathway= (pathwayTemplate) => {
    console.log("upload pathway Template");

    var summary = pathwayTemplate.steps.map((step) => step.title).join(" -> ");

    const body = {
        name: pathwayTemplate.title,
        description: pathwayTemplate.description,
        id: pathwayTemplate.id,
        template : JSON.stringify(pathwayTemplate),
        summary: summary
    }
  
    return (dispatch, getState) => {
      dispatch(isLoading(true));
  
      var config = getConfiguation(dispatch, getState);
      var pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
      var request = pathwayTemplateApi.importPathwayTemplate({pathwayTemplateDto: body})
      onRequest(request, dispatch, editPathwaySuccess, hasError, isLoading, null, "Error saving edits");
  };
  }

const onDeletePathwaySuccess = (response, pathway) => {
    return {
        type: DELETE_PATHWAY,
        pathway: pathway
    }
};

const deletePathway = (pathway) => {
    console.log("delete pathway Template");

    return (dispatch, getState) => {
      dispatch(isLoading(true));
  
      var config = getConfiguation(dispatch, getState);
      var pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
      var request = pathwayTemplateApi.deletePathway({pathwayId : pathway.id, pathwayName: pathway.name});
      onRequest(request, dispatch, onDeletePathwaySuccess,hasError, isLoading, pathway, "Error deleting pathway");
  };
};

export default {
    fetchPathwayList,
    fetchPathways,
    addPathway,
    editPathway,
    deletePathway,
    clearUpdateSuccess,
    clearError
}