/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EhrQueryDto } from './EhrQueryDto';
import {
    EhrQueryDtoFromJSON,
    EhrQueryDtoFromJSONTyped,
    EhrQueryDtoToJSON,
} from './EhrQueryDto';

/**
 * A wrapper which allows you to sent multiple queries to the server to be processed together, each associated with a name
 * @export
 * @interface MultiEhrQueryDto
 */
export interface MultiEhrQueryDto {
    /**
     * The queries to run
     * @type {{ [key: string]: EhrQueryDto; }}
     * @memberof MultiEhrQueryDto
     */
    queries?: { [key: string]: EhrQueryDto; };
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof MultiEhrQueryDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof MultiEhrQueryDto
     */
    userIdNamespace?: string;
}

/**
 * Check if a given object implements the MultiEhrQueryDto interface.
 */
export function instanceOfMultiEhrQueryDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MultiEhrQueryDtoFromJSON(json: any): MultiEhrQueryDto {
    return MultiEhrQueryDtoFromJSONTyped(json, false);
}

export function MultiEhrQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiEhrQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queries': !exists(json, 'queries') ? undefined : (mapValues(json['queries'], EhrQueryDtoFromJSON)),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdNamespace': !exists(json, 'userIdNamespace') ? undefined : json['userIdNamespace'],
    };
}

export function MultiEhrQueryDtoToJSON(value?: MultiEhrQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queries': value.queries === undefined ? undefined : (mapValues(value.queries, EhrQueryDtoToJSON)),
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
    };
}

