/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a user role and default permissions
 * @export
 * @interface RoleDefaultPermissionsDto
 */
export interface RoleDefaultPermissionsDto {
    /**
     * Role
     * @type {string}
     * @memberof RoleDefaultPermissionsDto
     */
    role: string;
    /**
     * Label
     * @type {string}
     * @memberof RoleDefaultPermissionsDto
     */
    label: string;
    /**
     * Default Permissions
     * @type {Array<string>}
     * @memberof RoleDefaultPermissionsDto
     */
    defaultPermissions: Array<string>;
}

/**
 * Check if a given object implements the RoleDefaultPermissionsDto interface.
 */
export function instanceOfRoleDefaultPermissionsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "defaultPermissions" in value;

    return isInstance;
}

export function RoleDefaultPermissionsDtoFromJSON(json: any): RoleDefaultPermissionsDto {
    return RoleDefaultPermissionsDtoFromJSONTyped(json, false);
}

export function RoleDefaultPermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDefaultPermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'label': json['label'],
        'defaultPermissions': json['defaultPermissions'],
    };
}

export function RoleDefaultPermissionsDtoToJSON(value?: RoleDefaultPermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'label': value.label,
        'defaultPermissions': value.defaultPermissions,
    };
}

