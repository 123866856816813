import { WIDGET_DATA_FETCH } from '../actions/Actions';
import { WIDGET_DATA_IS_LOADING } from '../actions/Actions';
import { WIDGET_DATA_ERROR } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    data: {
        taskTypeCountList : [],
        responseRateList: [],
        expiringProms: 0,
        overDueProms: 0
    },    

    hasError: false,
    isLoading: false,
    errorMsg: null,
};


const widgetDataReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case WIDGET_DATA_FETCH: {
            console.log("Fetch dashboard widget data", action);

            var payload = action.payload;

            return Object.assign({}, state, {
                data: payload,
                isLoading: false
            });
        }
        case LOGOUT:
            return initialState;
        case WIDGET_DATA_IS_LOADING:
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case WIDGET_DATA_ERROR:
            console.log('haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg
            });
        default:
            return state;
    }
}

export default widgetDataReducer;