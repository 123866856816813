/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageableObject } from './PageableObject';
import {
    PageableObjectFromJSON,
    PageableObjectFromJSONTyped,
    PageableObjectToJSON,
} from './PageableObject';

/**
 * Details of a search request
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * Search terms
     * @type {{ [key: string]: string; }}
     * @memberof SearchRequest
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof SearchRequest
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof SearchRequest
     */
    start?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof SearchRequest
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof SearchRequest
     */
    orderDirection?: string;
    /**
     * 
     * @type {PageableObject}
     * @memberof SearchRequest
     */
    pageable?: PageableObject;
}

/**
 * Check if a given object implements the SearchRequest interface.
 */
export function instanceOfSearchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerms': !exists(json, 'searchTerms') ? undefined : json['searchTerms'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'orderTerm': !exists(json, 'orderTerm') ? undefined : json['orderTerm'],
        'orderDirection': !exists(json, 'orderDirection') ? undefined : json['orderDirection'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableObjectFromJSON(json['pageable']),
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerms': value.searchTerms,
        'pageSize': value.pageSize,
        'start': value.start,
        'orderTerm': value.orderTerm,
        'orderDirection': value.orderDirection,
        'pageable': PageableObjectToJSON(value.pageable),
    };
}

