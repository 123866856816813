/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HcpDto
 */
export interface HcpDto {
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    nhsId: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    telNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof HcpDto
     */
    disabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof HcpDto
     */
    permissions?: Array<string>;
}

/**
 * Check if a given object implements the HcpDto interface.
 */
export function instanceOfHcpDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "emailAddress" in value;
    isInstance = isInstance && "nhsId" in value;
    isInstance = isInstance && "firstNames" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function HcpDtoFromJSON(json: any): HcpDto {
    return HcpDtoFromJSONTyped(json, false);
}

export function HcpDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'emailAddress': json['emailAddress'],
        'nhsId': json['nhsId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstNames': json['firstNames'],
        'lastName': json['lastName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'telNumber': !exists(json, 'telNumber') ? undefined : json['telNumber'],
        'role': json['role'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function HcpDtoToJSON(value?: HcpDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'emailAddress': value.emailAddress,
        'nhsId': value.nhsId,
        'title': value.title,
        'firstNames': value.firstNames,
        'lastName': value.lastName,
        'jobTitle': value.jobTitle,
        'location': value.location,
        'telNumber': value.telNumber,
        'role': value.role,
        'disabled': value.disabled,
        'permissions': value.permissions,
    };
}

