/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverrideMessageDto
 */
export interface OverrideMessageDto {
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    pasValue?: string;
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    overrideValue?: string;
}

/**
 * Check if a given object implements the OverrideMessageDto interface.
 */
export function instanceOfOverrideMessageDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OverrideMessageDtoFromJSON(json: any): OverrideMessageDto {
    return OverrideMessageDtoFromJSONTyped(json, false);
}

export function OverrideMessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverrideMessageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'pasValue': !exists(json, 'pasValue') ? undefined : json['pasValue'],
        'overrideValue': !exists(json, 'overrideValue') ? undefined : json['overrideValue'],
    };
}

export function OverrideMessageDtoToJSON(value?: OverrideMessageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'code': value.code,
        'pasValue': value.pasValue,
        'overrideValue': value.overrideValue,
    };
}

