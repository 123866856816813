import { createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import {reducer} from './comms';


const configureStore = () => {
  const middlewares = [thunk];
  const enhancer = applyMiddleware(...middlewares);

  // create store
  return createStore(reducer, enhancer);
};

export default () => {
  let store = configureStore()
  let persistor = persistStore(store)
  return { store, persistor }
}