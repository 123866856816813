/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Procedure } from './Procedure';
import {
    ProcedureFromJSON,
    ProcedureFromJSONTyped,
    ProcedureToJSON,
} from './Procedure';

/**
 * Represents the data for a composition which should be stored in openEhr.
 * @export
 * @interface CompositionDto
 */
export interface CompositionDto {
    /**
     * The date associated with the record
     * @type {number}
     * @memberof CompositionDto
     */
    recordDate: number;
    /**
     * The template used to store the composition
     * @type {string}
     * @memberof CompositionDto
     */
    ehrTemplateName: string;
    /**
     * Collections of key value pairs representing the content to be stored. Each top level key represents a collection prefix. Each prefix may store an array of objects, and each object contains a map of key value pairs. The prefix key will be added to the key to create the full ehr path.
     * @type {{ [key: string]: Array<{ [key: string]: string; }>; }}
     * @memberof CompositionDto
     */
    contents: { [key: string]: Array<{ [key: string]: string; }>; };
    /**
     * The primaryEntityName of the parameter map, if one exists, which has previously been registered with the server for the app to map simple content keys to openEhr AQL paths
     * @type {string}
     * @memberof CompositionDto
     */
    parameterMap: string;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof CompositionDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof CompositionDto
     */
    userIdNamespace?: string;
    /**
     * The template root node id
     * @type {string}
     * @memberof CompositionDto
     */
    rootNodeId: string;
    /**
     * The scheduled date and time
     * @type {number}
     * @memberof CompositionDto
     */
    scheduledDateTime: number;
    /**
     * The description of the composition
     * @type {string}
     * @memberof CompositionDto
     */
    description: string;
    /**
     * The care pathway id
     * @type {string}
     * @memberof CompositionDto
     */
    carePathwayId?: string;
    /**
     * The care pathway compositionUUid
     * @type {string}
     * @memberof CompositionDto
     */
    carePathwayCompositionUUid?: string;
    /**
     * The careflow step state
     * @type {string}
     * @memberof CompositionDto
     */
    careflowStepState?: string;
    /**
     * The careflow completion date and time
     * @type {number}
     * @memberof CompositionDto
     */
    careflowStepCompletionDateTime?: number;
    /**
     * 
     * @type {Procedure}
     * @memberof CompositionDto
     */
    procedure?: Procedure;
    /**
     * List of ids of subprocedure to be re-scheduled with the procedure
     * @type {Array<string>}
     * @memberof CompositionDto
     */
    subProcedureRescheduleList?: Array<string>;
    /**
     * The step id
     * @type {string}
     * @memberof CompositionDto
     */
    stepId?: string;
    /**
     * The step title
     * @type {string}
     * @memberof CompositionDto
     */
    stepTitle?: string;
    /**
     * The care pathway name
     * @type {string}
     * @memberof CompositionDto
     */
    pathwayName?: string;
}

/**
 * Check if a given object implements the CompositionDto interface.
 */
export function instanceOfCompositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordDate" in value;
    isInstance = isInstance && "ehrTemplateName" in value;
    isInstance = isInstance && "contents" in value;
    isInstance = isInstance && "parameterMap" in value;
    isInstance = isInstance && "rootNodeId" in value;
    isInstance = isInstance && "scheduledDateTime" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function CompositionDtoFromJSON(json: any): CompositionDto {
    return CompositionDtoFromJSONTyped(json, false);
}

export function CompositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordDate': json['recordDate'],
        'ehrTemplateName': json['ehrTemplateName'],
        'contents': json['contents'],
        'parameterMap': json['parameterMap'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userIdNamespace': !exists(json, 'userIdNamespace') ? undefined : json['userIdNamespace'],
        'rootNodeId': json['rootNodeId'],
        'scheduledDateTime': json['scheduledDateTime'],
        'description': json['description'],
        'carePathwayId': !exists(json, 'carePathwayId') ? undefined : json['carePathwayId'],
        'carePathwayCompositionUUid': !exists(json, 'carePathwayCompositionUUid') ? undefined : json['carePathwayCompositionUUid'],
        'careflowStepState': !exists(json, 'careflowStepState') ? undefined : json['careflowStepState'],
        'careflowStepCompletionDateTime': !exists(json, 'careflowStepCompletionDateTime') ? undefined : json['careflowStepCompletionDateTime'],
        'procedure': !exists(json, 'procedure') ? undefined : ProcedureFromJSON(json['procedure']),
        'subProcedureRescheduleList': !exists(json, 'subProcedureRescheduleList') ? undefined : json['subProcedureRescheduleList'],
        'stepId': !exists(json, 'stepId') ? undefined : json['stepId'],
        'stepTitle': !exists(json, 'stepTitle') ? undefined : json['stepTitle'],
        'pathwayName': !exists(json, 'pathwayName') ? undefined : json['pathwayName'],
    };
}

export function CompositionDtoToJSON(value?: CompositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordDate': value.recordDate,
        'ehrTemplateName': value.ehrTemplateName,
        'contents': value.contents,
        'parameterMap': value.parameterMap,
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
        'rootNodeId': value.rootNodeId,
        'scheduledDateTime': value.scheduledDateTime,
        'description': value.description,
        'carePathwayId': value.carePathwayId,
        'carePathwayCompositionUUid': value.carePathwayCompositionUUid,
        'careflowStepState': value.careflowStepState,
        'careflowStepCompletionDateTime': value.careflowStepCompletionDateTime,
        'procedure': ProcedureToJSON(value.procedure),
        'subProcedureRescheduleList': value.subProcedureRescheduleList,
        'stepId': value.stepId,
        'stepTitle': value.stepTitle,
        'pathwayName': value.pathwayName,
    };
}

