import { FETCH_SITES_LIST  } from '../actions/Actions';
import { ADD_SITE } from '../actions/Actions';
import { UPDATE_SITE  } from '../actions/Actions';
import { DELETE_SITE } from '../actions/Actions';
import { CONFIG_LOADING  } from '../actions/Actions';
import { CONFIG_ERROR  } from '../actions/Actions';
import { SITE_SUCCESS_CLEAR  } from '../actions/Actions';
import { FETCH_ROLE_DEFAULT_PERMISSIONS } from '../actions/Actions';
import { FETCH_PROMS_EMAIL_CONFIG } from '../actions/Actions';
import { UPDATE_PROMS_EMAIL_CONFIG } from '../actions/Actions';
import { FETCH_PROMS_PRINT_CONFIG } from '../actions/Actions';
import { UPDATE_PROMS_PRINT_CONFIG } from '../actions/Actions';
import { FETCH_LANGUAGE_LIST  } from '../actions/Actions';
import { FETCH_ENABLED_LANGUAGE_LIST } from '../actions/Actions';
import { LANGUAGE_ENABLE_STATE  } from '../actions/Actions';
import { REPORTING_URLS } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
   sites: {list:[]},
   rolePermissions: [],
   permissionCategories: [],
   promsEmailConfig: null,
   promsSmsConfig: null,
   promsPrintConfig: null,
   supportedLanguages: {list:[]},
   enabledLanguages: [],
   reportingUrls: []
};

function updateSiteInArray(array, site) {
    return array.map((item, index) => {
      if (item.id !== site.id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
  
      return {
        ...item,
        ...site
      };
    });
  }

  function updatePromsEmailConfigInArray(array, emailConfig) {
    return array.map((item, index) => {
      if (item.id !== emailConfig.id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
  
      return emailConfig;
    }).sort((a,b) => a.id - b.id);
  }

function groupPermissionByCategory(permissionDescriptions){
   return permissionDescriptions.reduce(function (r, a) {
        r[a.category] = r[a.category] || [];
        r[a.category].push(a);
        return r;
    }, Object.create(null));
}  

const configResourcesReducer = ( state = initialState, action) => {
    const { type, payload } = action;
    
    switch (type) {
        case FETCH_ROLE_DEFAULT_PERMISSIONS: {
            console.log('config reducer ', action);

            const permissionCategories = groupPermissionByCategory(action.permissionDescriptions);
            console.log(permissionCategories);
            return Object.assign({}, state, {
                rolePermissions: action.rolePermissions,
                permissionCategories: permissionCategories,
                isLoading: false
            });
        }
        case FETCH_SITES_LIST: {
            console.log('config reducer ', action);
            const sites = action.sites;

            var updatedSites = state.sites; 
            if (action.pageStart > 0) {
                const updatedSitesArray = [...updatedSites.list, ...sites];

                updatedSites = Object.assign({}, state.sites, {
                    list: updatedSitesArray ,
                    currentTotal: updatedSitesArray.length,
                    isLoading: false
                });
            } else {
                updatedSites = Object.assign({}, state, {
                    list: action.sites,
                    currentTotal: sites.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });
            }

            return Object.assign({}, state, {
                sites: updatedSites 
            });
        }
        case ADD_SITE: {
            console.log('config reducer ', action);

            const updatedSitesArray = [...state.sites.list, action.site];
            const updatedSites = Object.assign({}, state.sites, {
                list: updatedSitesArray ,
                currentTotal: updatedSitesArray.length,
                isLoading: false
            });

            return Object.assign({}, state, {
                sites: updatedSites,
                isLoading: false
            });
        }
        case UPDATE_SITE:{
            console.log('config reducer ', action);
            var updatedArray = updateSiteInArray(state.sites.list, action.site);
            const updatedSites = Object.assign({}, state.sites, {
                list: updatedArray ,
                isLoading: false
            });

            return Object.assign({}, state, {
                sites: updatedSites,
                isLoading: false
            });
        }
        case DELETE_SITE: {
            console.log('config reducer ', action);
            var deletedSite = action.site;
            var updatedArray = state.sites.list.filter(site => site.id != deletedSite.id);
            const updatedSites = Object.assign({}, state.sites, {
                list: updatedArray ,
                currentTotal: updatedArray.length,
                isLoading: false
            });

            return Object.assign({}, state, {
                sites: updatedSites,
                isLoading: false
            });
        }
        case LOGOUT:
            return initialState;
        case CONFIG_ERROR:
            console.log('config reducer - haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                updateSuccess: false
            });
        case CONFIG_LOADING:
            console.log('config reducer - isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SITE_SUCCESS_CLEAR: {
            console.log('config reducer - success clear', action);

            return Object.assign({}, state, {
                updateSuccess: false,
                updateSuccessMessage:null
            });
        }
        case FETCH_PROMS_EMAIL_CONFIG:{
            console.log('config reducer - fetch proms email config', action);

            const sortedArray = action.promsEmailConfigs.sort((a,b) => a.id - b.id);

            return Object.assign({}, state, {
                promsEmailConfig: sortedArray.filter(a => a.type === "Long"),
                promsSmsConfig: sortedArray.filter(a => a.type === "Short"),
                isLoading: false
            });
        }
        case FETCH_PROMS_PRINT_CONFIG: {
            console.log('config reducer - fetch proms print config', action);

            return Object.assign({}, state, {
                promsPrintConfig: action.promsPrintConfig,
                isLoading: false
            });
        }
        case UPDATE_PROMS_EMAIL_CONFIG:{
            console.log('config reducer - fetch proms email config', action);

            var promsEmailConfig = state.promsEmailConfig;
            var promsSmsConfig = state.promsSmsConfig;
            if (action.promsEmailConfig.type === "Long"){
                promsEmailConfig = updatePromsEmailConfigInArray(state.promsEmailConfig, action.promsEmailConfig);
            } else {
                promsSmsConfig = updatePromsEmailConfigInArray(state.promsSmsConfig, action.promsEmailConfig);
            }

            return Object.assign({}, state, {
                promsEmailConfig: promsEmailConfig,
                promsSmsConfig: promsSmsConfig,
                isLoading: false,
                updateSuccess: true,
                updateSuccessMessage:"Updated proms email config"
            });
        }
        case UPDATE_PROMS_PRINT_CONFIG:{
            console.log('config reducer - fetch proms print config', action);

            return Object.assign({}, state, {
                promsPrintConfig: action.promsPrintConfig,
                isLoading: false,
                updateSuccess: true,
                updateSuccessMessage:"Updated proms printing config"
            });
        }
        case FETCH_LANGUAGE_LIST : {
            console.log('config reducer ', action);
            const supportedLanguages = action.supportedLanguages;

            var updatedSupportedLanguages = state.supportedLanguages; 
            if (action.pageStart > 0) {
                const updatedSupportedLanguagesArray = [...updatedSupportedLanguages.list, ...supportedLanguages];

                console.log(updatedSupportedLanguagesArray);

                updatedSupportedLanguages = Object.assign({}, state.supportedLanguages, {
                    list: updatedSupportedLanguagesArray ,
                    currentTotal: updatedSupportedLanguagesArray.length,
                    isLoading: false
                });
            } else {
                updatedSupportedLanguages = Object.assign({}, state, {
                    list: action.supportedLanguages,
                    currentTotal: supportedLanguages.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });
            }

            return Object.assign({}, state, {
                supportedLanguages: updatedSupportedLanguages 
            });
        }
        case FETCH_ENABLED_LANGUAGE_LIST: {
            return Object.assign({}, state, {
                enabledLanguages: action.enabledLanguages,
                isLoading: false,
            });
        }
        case LANGUAGE_ENABLE_STATE: {
            const languageCode = action.languageCode;
            const enabled = action.enable;

            const supportedLanguages = state.supportedLanguages;

            const updatedLanguageArray = supportedLanguages.list.map(sp => {
                if ( sp.languageCode == languageCode ){
                    console.log("MATCHING CODE")
                    sp.enabled = enabled;
                }
                return sp;
            });

            const updatedSupportedLanguages = Object.assign({}, state.supportedLanguages, {
                list: updatedLanguageArray
            });

            return Object.assign({}, state, {
                enabledLanguages: action.enabledLanguages,
                isLoading: false,
                supportedLanguages: updatedSupportedLanguages 
            });
        }
        case REPORTING_URLS: {
            return Object.assign({}, state, {
                reportingUrls: action.reportUrls,
                isLoading: false,
            });
        }
        default:
            return state;
    }
}

export default configResourcesReducer;