/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a pathway template json
 * @export
 * @interface PathwayTemplateDto
 */
export interface PathwayTemplateDto {
    /**
     * Id
     * @type {string}
     * @memberof PathwayTemplateDto
     */
    id: string;
    /**
     * The pathway template
     * @type {string}
     * @memberof PathwayTemplateDto
     */
    template: string;
    /**
     * The template name
     * @type {string}
     * @memberof PathwayTemplateDto
     */
    name: string;
    /**
     * The template description
     * @type {string}
     * @memberof PathwayTemplateDto
     */
    description: string;
    /**
     * The pathway summary
     * @type {string}
     * @memberof PathwayTemplateDto
     */
    summary: string;
    /**
     * Prepopulated fields
     * @type {Array<string>}
     * @memberof PathwayTemplateDto
     */
    prepopulatedFields?: Array<string>;
}

/**
 * Check if a given object implements the PathwayTemplateDto interface.
 */
export function instanceOfPathwayTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "summary" in value;

    return isInstance;
}

export function PathwayTemplateDtoFromJSON(json: any): PathwayTemplateDto {
    return PathwayTemplateDtoFromJSONTyped(json, false);
}

export function PathwayTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'template': json['template'],
        'name': json['name'],
        'description': json['description'],
        'summary': json['summary'],
        'prepopulatedFields': !exists(json, 'prepopulatedFields') ? undefined : json['prepopulatedFields'],
    };
}

export function PathwayTemplateDtoToJSON(value?: PathwayTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'template': value.template,
        'name': value.name,
        'description': value.description,
        'summary': value.summary,
        'prepopulatedFields': value.prepopulatedFields,
    };
}

