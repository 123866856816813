// Permission based routing
export default [
  { "id" :	1	, "permission" : "VIEW_STAFF", "route" : "staff" , "screen" : "staff"},
  { "id" :	2	, "permission" : "VIEW_SUMMARY_REPORTS", "route" : "reports" , "screen" : "reports"},
  { "id" :	3	, "permission" : "VIEW_ALL_PERSONAL_REPORTS", "route" : "reports" , "screen" : "reports"},
  { "id" :	4	, "permission" : "VIEW_OWN_PERSONAL_REPORTS", "route" : "reports" , "screen" : "reports"},
  { "id" :	6	, "permission" : "VIEW_PROCEDURE", "route" : "procedures" , "screen" : "procedures"},
  { "id" :	7	, "permission" : "VIEW_PATHWAY", "route" : "pathways" , "screen" : "pathways"},
  { "id" :	8	, "permission" : "VIEW_PATIENT", "route" : "patients" , "screen" : "patients"},
  { "id" :	9	, "permission" : "VIEW_CONFIG", "route" : "config" , "screen" : "config"},
  { "id" : 10	, "permission" : "VIEW_PROMS_TEMPLATES", "route" : "proms" , "screen" : "proms"},
  { "id" : 11 , "permission" : "VIEW_TASKS", "route" : "tasks", "screen" : "tasks"},
  { "id" : 12 , "permission" : "VIEW_AUDIT", "route" : "audit", "screen" : "audit"}
];
