import { FETCH_STAFF_LIST } from './Actions';
import { ADD_STAFF_PROFILE } from './Actions';
import { UPDATE_STAFF_PROFILE } from './Actions';
import { DELETE_STAFF_PROFILE } from './Actions';
import { STAFF_UPDATE_ERROR } from './Actions';
import { STAFF_LOADING } from './Actions';
import { STAFF_SUCCESS_CLEAR } from './Actions';
import { STAFF_PASSWORD_RESET_SUCCESS } from './Actions';
import { DISABLE_STAFF_PROFILE } from './Actions';

import { HcpApi } from 'promsApi';

import getConfiguation from './Configuration';
import onRequest from './Request';

const staffError = (bool, errorMsg) => {
    return {
        type: STAFF_UPDATE_ERROR,
        hasError: bool,
        errorMsg: errorMsg
    }
};

const staffLoading = (bool) => {
    return {
        type: STAFF_LOADING,
        isLoading: bool
    }
};

const clearStaffSuccess = () => {
    return {
        type: STAFF_SUCCESS_CLEAR
    }
};

const fetchStaffListSuccessHandler = (payload, searchTerm) => {
    console.log("++++++++++++");
    console.log(payload);
    return {
        type: FETCH_STAFF_LIST,
        staffList: payload == null ? {} : payload.profiles,
        total: payload.total,
        pageStart: payload.start,
        searchTerm: searchTerm,
        orderTerm: payload.orderTerm,
        orderDirection: payload.orderDirection,
        fetchTime: new Date().getTime()
    }
};

const fetchStaffList = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
    console.log('Fetching staff list');
    console.log(searchTerm);

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);

        // Split search term for name or nhsId
        var searchTermMapping = {};
        const containsNumber = /\d/.test(searchTerm);
        if (containsNumber) {
            searchTermMapping = { "nhsId": searchTerm }
        } else if (searchTerm != ""){
            searchTermMapping = { "lastName": searchTerm };
        }

        const staffSearchRequest = {
            "searchTerms": searchTermMapping,
            "pageSize": pageSize,
            "start": start,
            "orderTerm": orderTerm,
            "orderDirection": orderDirection
        }

        var hcpApi = new HcpApi(config);
        var request = hcpApi.findStaff({ "searchRequest": staffSearchRequest });

        onRequest(request, dispatch, fetchStaffListSuccessHandler, staffError, staffLoading, searchTerm, "Error fetching users")
    };
};

const fetchClinicalStaffList = (searchTerm, pageSize, start) => {
    console.log('Fetching clinical staff list');

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);

        const staffSearchRequest = {
            "searchTerms": {
                "permission": "PERFORM_CLINICAL_PROCEDURES",
                "lastName": searchTerm
            },
            "pageSize": pageSize,
            "start": start,
            "orderTerm": "lastName",
            "orderDirection": "asc"
        }

        var hcpApi = new HcpApi(config);
        var request = hcpApi.findStaff({ "searchRequest": staffSearchRequest });
        onRequest(request, dispatch, fetchStaffListSuccessHandler,  staffError, staffLoading, null, "Error fetching staff list");
    };
};

const fetchAuditStaffList = (searchTerm, pageSize, start) => {
    console.log('Fetching clinical staff list');

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);

        const staffSearchRequest = {
            "pageSize": pageSize,
            "start": start,
            "orderTerm": "lastName",
            "orderDirection": "asc"
        }

        if ( searchTerm != ""){
            staffSearchRequest.searchTerms =  {
                "lastName": searchTerm
            }
        }

        var hcpApi = new HcpApi(config);
        var request = hcpApi.findStaff({ "searchRequest": staffSearchRequest });
        onRequest(request, dispatch, fetchStaffListSuccessHandler,  staffError, staffLoading, null, "Error fetching staff list");
    };
};

const createStaffProfileSuccessHandler = (response) => {
    return {
        type: ADD_STAFF_PROFILE,
        staffProfile: response
    }
};

const createStaffProfile = (profile) => {
    console.log("Create Staff Member");

    profile.title = profile.title;
    profile.username = profile.emailAddress; // TODO Check if email address should be used as username 

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);
        var hcpApi = new HcpApi(config);
        var request = hcpApi.create({ "hcpDto": profile });
        onRequest(request, dispatch, createStaffProfileSuccessHandler,  staffError, staffLoading, null,  "Error creating user")
    };
};

const updateStaffProfileSuccessHandler = (response, profile) => {
    return {
        type: UPDATE_STAFF_PROFILE,
        staffProfile: profile
    }
};

const updateStaffProfile = (profile) => {
    console.log("Update Staff Member");

    console.log(profile);

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);
        var hcpApi = new HcpApi(config);
        var request = hcpApi.updateProfile({ "hcpDto": profile });
        onRequest(request, dispatch, updateStaffProfileSuccessHandler, staffError, staffLoading, profile, "Error updating user")
    };
};

const deleteStaffProfileSuccessHandler = (response, profile) => {
    return {
        type: DELETE_STAFF_PROFILE,
        staffProfile: profile
    }
};

const deleteStaffProfile = (profile) => {
    console.log("Delete Staff Member");

    console.log(profile);

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);
        var hcpApi = new HcpApi(config);
        var request = hcpApi.deleteProfile({ "username": profile.username });
        onRequest(request, dispatch, deleteStaffProfileSuccessHandler, staffError, staffLoading, profile, "Error deleting user")
    };
}

const disableStaffProfileSuccessHandler = (response, profile) => {
    return {
        type: DISABLE_STAFF_PROFILE,
        staffProfile: profile
    }
};

const disableStaffProfile = (profile, disable) => {
    var successProfile = Object.assign({}, profile);
    successProfile.disabled = disable;

    return (dispatch, getState) => {
        dispatch(staffLoading(true));

        var config = getConfiguation(dispatch, getState);
        var hcpApi = new HcpApi(config);
        var request = disable ?
            hcpApi.disableProfile({ "hcpDto": profile }) : hcpApi.enableProfile({ "hcpDto": profile });
        onRequest(request, dispatch, disableStaffProfileSuccessHandler, staffError, staffLoading, successProfile,  "Error updating user")
    };
};


const sendPasswordRequestSuccessHandler = (response) => {
    return {
        type: STAFF_PASSWORD_RESET_SUCCESS,
    }
};

const sendPasswordRequest = (profile) => {
    console.log("Send password request Staff Member");

    // TODO Does functionality need to be removed
};

export default {
    fetchStaffList,
    updateStaffProfile,
    createStaffProfile,
    deleteStaffProfile,
    sendPasswordRequest,
    clearStaffSuccess,
    disableStaffProfile,
    fetchClinicalStaffList,
    fetchAuditStaffList
}

