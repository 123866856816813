import { xor } from "lodash";

const GREEN_TINT = "#C9F0B8"; 
const CYAN_TINT = "#CDF9F1";
const PURPLE_TINT = "#E5D9FC";
const PINK_TINT = "#FFC5C5";
const ORANGE_TINT = "#FFDFCA";

const SOLID_GREEN = "#3DC172";
const SOLID_CYAN = "#00A19A";
const SOLID_PURPLE = "#AB85E5";
const SOLID_RED = "#E53C3C";
const SOLID_ORANGE = "#E96D18";

export default {
  scheduleTableHeader: CYAN_TINT,
  pathwayTableHeader: PURPLE_TINT,
  tableHover: "#eeeeee",

  tabIndicatorColor: SOLID_CYAN,

  eventComplete: GREEN_TINT,
  eventScheduled: "lightGrey",
  eventOverdue: ORANGE_TINT,
  eventExpired: PINK_TINT,
  eventCancelled: "lightGrey",

  outcomesHeader: CYAN_TINT,

  gradientStart: CYAN_TINT,
  gradientEnd: "white",

  background: '#e8edee',

  alternateRowColor: "ghostwhite",

  headerColours: [
    CYAN_TINT,
    PURPLE_TINT,
    PINK_TINT,
    ORANGE_TINT
  ],

  solidColours: [
    SOLID_GREEN,
    SOLID_CYAN,
    SOLID_PURPLE,
    SOLID_RED,
    SOLID_ORANGE
  ]
}