/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessibleNameDto } from './AccessibleNameDto';
import {
    AccessibleNameDtoFromJSON,
    AccessibleNameDtoFromJSONTyped,
    AccessibleNameDtoToJSON,
} from './AccessibleNameDto';
import type { SubProcedureTemplateDto } from './SubProcedureTemplateDto';
import {
    SubProcedureTemplateDtoFromJSON,
    SubProcedureTemplateDtoFromJSONTyped,
    SubProcedureTemplateDtoToJSON,
} from './SubProcedureTemplateDto';

/**
 * Represents procedure and it's templates
 * @export
 * @interface ProcedureTemplateDto
 */
export interface ProcedureTemplateDto {
    /**
     * Procedure id
     * @type {string}
     * @memberof ProcedureTemplateDto
     */
    id: string;
    /**
     * Procedure name
     * @type {string}
     * @memberof ProcedureTemplateDto
     */
    procedureName: string;
    /**
     * Procedure generic code
     * @type {string}
     * @memberof ProcedureTemplateDto
     */
    genericCode?: string;
    /**
     * template id
     * @type {string}
     * @memberof ProcedureTemplateDto
     */
    templateId: string;
    /**
     * UI template id
     * @type {string}
     * @memberof ProcedureTemplateDto
     */
    uiTemplateId: string;
    /**
     * Description of procedure
     * @type {string}
     * @memberof ProcedureTemplateDto
     */
    description: string;
    /**
     * Sub procedure list
     * @type {Array<SubProcedureTemplateDto>}
     * @memberof ProcedureTemplateDto
     */
    subProcedureTemplateDtoList?: Array<SubProcedureTemplateDto>;
    /**
     * Prepopulated fields
     * @type {Array<string>}
     * @memberof ProcedureTemplateDto
     */
    prepopulatedFields?: Array<string>;
    /**
     * Accessible name localisation
     * @type {Array<AccessibleNameDto>}
     * @memberof ProcedureTemplateDto
     */
    accessibleNames: Array<AccessibleNameDto>;
}

/**
 * Check if a given object implements the ProcedureTemplateDto interface.
 */
export function instanceOfProcedureTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "procedureName" in value;
    isInstance = isInstance && "templateId" in value;
    isInstance = isInstance && "uiTemplateId" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "accessibleNames" in value;

    return isInstance;
}

export function ProcedureTemplateDtoFromJSON(json: any): ProcedureTemplateDto {
    return ProcedureTemplateDtoFromJSONTyped(json, false);
}

export function ProcedureTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcedureTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'procedureName': json['procedureName'],
        'genericCode': !exists(json, 'genericCode') ? undefined : json['genericCode'],
        'templateId': json['templateId'],
        'uiTemplateId': json['uiTemplateId'],
        'description': json['description'],
        'subProcedureTemplateDtoList': !exists(json, 'subProcedureTemplateDtoList') ? undefined : ((json['subProcedureTemplateDtoList'] as Array<any>).map(SubProcedureTemplateDtoFromJSON)),
        'prepopulatedFields': !exists(json, 'prepopulatedFields') ? undefined : json['prepopulatedFields'],
        'accessibleNames': ((json['accessibleNames'] as Array<any>).map(AccessibleNameDtoFromJSON)),
    };
}

export function ProcedureTemplateDtoToJSON(value?: ProcedureTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'procedureName': value.procedureName,
        'genericCode': value.genericCode,
        'templateId': value.templateId,
        'uiTemplateId': value.uiTemplateId,
        'description': value.description,
        'subProcedureTemplateDtoList': value.subProcedureTemplateDtoList === undefined ? undefined : ((value.subProcedureTemplateDtoList as Array<any>).map(SubProcedureTemplateDtoToJSON)),
        'prepopulatedFields': value.prepopulatedFields,
        'accessibleNames': ((value.accessibleNames as Array<any>).map(AccessibleNameDtoToJSON)),
    };
}

