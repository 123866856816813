import { ADD_PROMS_TEMPLATE } from '../actions/Actions';
import { PROMS_TEMPLATE_LOADING } from '../actions/Actions';
import { PROMS_TEMPLATE_FETCH } from '../actions/Actions';
import { PROMS_TEMPLATE_FETCH_ALL } from '../actions/Actions';
import { PROMS_TEMPLATE_FETCH_LIST } from '../actions/Actions';
import { PROMS_TEMPLATE_ERROR } from '../actions/Actions';
import { PROMS_TEMPLATE_PDF_UPLOAD } from '../actions/Actions';
import { PROMS_TEMPLATE_PDF_DELETION } from '../actions/Actions';
import { PROMS_TEMPLATE_SUCCESS_CLEAR } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    list: [],
    promQuestionnaires: [],
    fetchAllTime: 0,
    searchTerm: '',

    hasError: false,
    isLoading: false,
    errorMsg: null,

    updateSuccess: false
};

function replacePromsTemplates(oldArray, newArray) {
    const filteredArray = oldArray.filter((item) => {
        const foundItem = newArray.find((t) => t.id == item.id);
        return foundItem === undefined;
    });

    return filteredArray.concat(newArray);
}

function updateQuestionnaire(array, templateId, questionnaire) {
    return array.map((item) => {
        if (item.id !== templateId) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        const updatedArray = item.questionnaires.map((q) => {
            if (q.id !== questionnaire.id) {
                return q;
            }

            return questionnaire;
        })

        item.questionnaires = updatedArray;

        return {
            ...item
        };
    });
}

function updateQuestionnairePdfFile(array, templateId, promsId, fileName) {
    return array.map((item) => {
        console.log(item.id);
        if (item.id !== templateId) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        const updatedArray = item.children.map((q) => {
            if (q.id !== promsId) {
                return q;
            }
            
            if ( fileName ){
                q.fileName = fileName;
            } else {
                q.fileName = null;
            }

            return q;
        })

        item.questionnaires = updatedArray;

        return {
            ...item
        };
    });
}

const questionnairesReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case PROMS_TEMPLATE_FETCH_LIST: {
            console.log("fetch proms templates", action);
            const templateList = action.templateList;

            if (action.pageStart > 0) {
                const updatedTemplateArray = [...state.list, ...templateList];

                return Object.assign({}, state, {
                    list: updatedTemplateArray,
                    currentTotal: updatedTemplateArray.length,
                    isLoading: false
                });
            } else {
                return Object.assign({}, state, {
                    list: templateList,
                    currentTotal: templateList.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });
            }
        }
        case PROMS_TEMPLATE_FETCH: {
            console.log("fetched proms template", action);

            const payload = action.payload;

            const promQuestionnaires = payload.map((t) => {
                if ( t !== null ){
                    const pq = JSON.parse(t.template);
                    pq.children.forEach(child => {
                        const pdfItem = t.pdfList.find(pd => pd.promsId === child.id);
                        if ( pdfItem ){
                            child.fileName = pdfItem.fileName;
                        } else {
                            child.fileName = null;
                        }
                    });
                    return pq;
                } else {
                    return null;
                }
            }).filter(filter => filter !== null);

            const newList = replacePromsTemplates(state.promQuestionnaires, promQuestionnaires);

            return Object.assign({}, state, {
                promQuestionnaires: newList,
                isLoading: false
            });
        }
        case PROMS_TEMPLATE_FETCH_ALL: {
            console.log("fetched proms template", action);

            const payload = action.payload;

            const promQuestionnaires = payload.map((t) => {
                if ( t !== null ){
                    const pq = JSON.parse(t.template);
                    pq.children.forEach(child => {
                        const pdfItem = t.pdfList.find(pd => pd.promsId === child.id);
                        if ( pdfItem ){
                            child.fileName = pdfItem.fileName;
                        } else {
                            child.fileName = null;
                        }
                    });
                    return pq;
                } else {
                    return null;
                }
            }).filter(filter => filter !== null);

            return Object.assign({}, state, {
                promQuestionnaires: promQuestionnaires,
                isLoading: false,
                fetchAllTime: action.fetchAllTime
            });
        }
        case PROMS_TEMPLATE_ERROR:{
            console.log("Proms template error", action);
            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                updateSuccess: false
            });
        }
        case PROMS_TEMPLATE_LOADING:{
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        }
        case ADD_PROMS_TEMPLATE: {
            console.log("Add proms template", action);

            return Object.assign({}, state, {
                updateSuccess: true,
                isLoading: false
            });
        }
        case PROMS_TEMPLATE_SUCCESS_CLEAR:{
            console.log("Clear update success", action);

            return Object.assign({}, state, {
                updateSuccess: false,
            });
        }
        case PROMS_TEMPLATE_PDF_UPLOAD:{
            console.log("Upload proms pdf", action);

            const fileName = action.payload.fileName;
            const templateId = action.payload.templateId;
            const promsId = action.payload.promsId;

            var updatedArray = updateQuestionnairePdfFile(state.promQuestionnaires, templateId, promsId, fileName);
            return Object.assign({}, state, {
                promQuestionnaires: updatedArray
            });
        }
        case PROMS_TEMPLATE_PDF_DELETION:{
            console.log("Remove proms pdf", action);

            const templateId = action.payload.templateId;
            const promsId = action.payload.promsId;

            var updatedArray = updateQuestionnairePdfFile(state.promQuestionnaires, templateId, promsId, null);
            return Object.assign({}, state, {
                promQuestionnaires: updatedArray
            });
        }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export default questionnairesReducer;