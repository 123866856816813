import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import ColourPalette from './ColourPalette'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lm: 1200,
      lg: 1380,
      xl: 1920,
    },
    keys: [
      "xs",
      "sm",
      "md",
      "lm",
      "lg",
      "xl",
    ],
  },
  palette: {
    background: {
      dark: '#e8edee',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#005EB8'
    },
    secondary: {
      main: '#1b800b'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography,
  overrides: {
    MuiTableRow: {
      hover: {
        "&&:hover": {
          backgroundColor: ColourPalette.tableHover
        }
      }
   }
  }
});

export default theme;
