import { AUDIT_FETCH_LIST } from './Actions';
import { AUDIT_IS_LOADING } from './Actions';
import { AUDIT_ERROR } from './Actions';

import { AuditEndpointApi, ClientTokenAuditEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import getTokenConfiguation from './ClientTokenConfiguation';
import onRequest from './Request';

var moment = require('moment');

const auditError = (bool, errorMsg) => {
  return {
    type: AUDIT_ERROR,
    hasError: bool,
    errorMsg: errorMsg
  }
};

const auditLoading = (bool) => {
  return {
    type: AUDIT_IS_LOADING,
    isLoading: bool
  }
};

const clearAuditError = () => {
  return {
    type: AUDIT_ERROR,
    hasError: false,
    errorMsg: null
  }
};

const fetchAuditSuccessHandler = (payload, searchTermMapping) => {
  return {
    type: AUDIT_FETCH_LIST,
    audit: payload == null ? [] : payload.auditList,
    total: payload.total,
    pageStart: payload.start,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    searchTerms: searchTermMapping
  }
};

const fetchAudit = (searchTerms, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetching audit list');

  return (dispatch, getState) => {
    dispatch(auditLoading(true));

    var config = getConfiguation(dispatch, getState);
    var auditApi = new AuditEndpointApi(config); // TODO

    const searchTermMapping = {...searchTerms};
    if ( searchTerms.user ){
      searchTermMapping.username = searchTerms.user.username;
      delete searchTermMapping.user;
    }

    searchTermMapping.start = moment(searchTerms.start).utc();
    searchTermMapping.end = moment(searchTerms.end).utc();

    const auditSearchRequest = {
      "pageSize": pageSize,
      "start": start,
      "searchTerms": searchTermMapping,
      "orderTerm": orderTerm,
      "orderDirection": orderDirection
    };

    var request = auditApi.findAuditEntries({ "searchRequest": auditSearchRequest })
    onRequest(request, dispatch, fetchAuditSuccessHandler, auditError, auditLoading, searchTerms);
  };
};

const createAuditSuccessHandler = (payload) => {
  console.log(payload);
  return {
    type: "IGNORE"
  }
};

const userLoggedIn = () => {
  console.log("User logged in");

  return (dispatch, getState) => {
    const config = getConfiguation(dispatch, getState);
    const auditApi = new AuditEndpointApi(config); // TODO

    const createAuditRequest = {
      type: "USER",
      action: "LOGGED_IN",
      attributes: {"action": "LOGGED_IN"}
    }

    const request = auditApi.createAuditEntry({ "externalAuditDto": createAuditRequest });
    onRequest(request, dispatch, createAuditSuccessHandler, auditError, auditLoading);
  }
}

const userLoggedOut = () => {
  console.log("User logged in");

  return (dispatch, getState) => {
    const config = getConfiguation(dispatch, getState);
    const auditApi = new AuditEndpointApi(config); // TODO

    const createAuditRequest = {
      type: "USER",
      action: "LOGGED_IN",
      attributes: {"action": "LOGGED_OUT"}
    }

    const request = auditApi.createAuditEntry({ "externalAuditDto": createAuditRequest });
    onRequest(request, dispatch, createAuditSuccessHandler, auditError, auditLoading);
  }
}

const userTimeOutLogout = () => {
    console.log("User timeout logout");

    return (dispatch, getState) => {
      const config = getTokenConfiguation();
      const auditApi = new ClientTokenAuditEndpointApi(config);

      const userName = getState().hcpData.hcpProfile.username;

      console.log("User name = " + userName);
      console.log(getState());

      const request = auditApi.createAuditEntryForUserLogout({body: userName});
      onRequest(request, dispatch, createAuditSuccessHandler, auditError, auditLoading);
    }
}
 
export default {
  clearAuditError,
  fetchAudit,
  userLoggedIn,
  userLoggedOut,
  userTimeOutLogout
}