import { TASKS_FETCH_LIST } from '../actions/Actions';
import { TASKS_MARK_AS_COMPLETE } from '../actions/Actions';
import { TASKS_GET_TYPE_COUNT_LIST } from '../actions/Actions';
import { TASKS_IS_LOADING  } from '../actions/Actions';
import { TASKS_ERROR  } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    tasks: [],
    total: 0,
    currentTotal: 0,
    searchTerm: "",
    orderTerm: "",
    orderDirection: "asc",
    fetchTime: null,

    taskCountList: [],

    hasError: false,
    isLoading: false,
    errorMsg: null,

};


const tasksReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case TASKS_FETCH_LIST: {
            console.log("Fetch tasks", action);

            var tasks = action.tasks;

            if (action.pageStart > 0) {
                const updatedTaskArray = [...state.tasks, ...tasks];

                return Object.assign({}, state, {
                    tasks: updatedTaskArray,
                    currentTotal: updatedTaskArray.length,
                    isLoading: false
                });
            } else {
                return Object.assign({}, state, {
                    tasks: tasks,
                    currentTotal: tasks.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false
                });
            }
        }
        case TASKS_MARK_AS_COMPLETE: {
            console.log("Mark task as complete");
    
            const taskId = action.taskId;

            console.log(taskId);


            var updatedArray = state.tasks.filter(task => task.id !== taskId);
            return Object.assign({}, state, {
                tasks: updatedArray,
                isLoading: false
            });
        }
        case TASKS_GET_TYPE_COUNT_LIST: {
            console.log("Get task count list");

            const taskCountList = action.taskCountList;

            return Object.assign({}, state, {
                taskCountList: taskCountList,
                isLoading: false
            });
        }
        case LOGOUT:
            return initialState;
        case TASKS_IS_LOADING:
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case TASKS_ERROR:
            console.log('haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg
            });
        default:
            return state;
    }
}

export default tasksReducer;