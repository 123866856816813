/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Clinician } from './Clinician';
import {
    ClinicianFromJSON,
    ClinicianFromJSONTyped,
    ClinicianToJSON,
} from './Clinician';
import type { Procedure } from './Procedure';
import {
    ProcedureFromJSON,
    ProcedureFromJSONTyped,
    ProcedureToJSON,
} from './Procedure';
import type { Site } from './Site';
import {
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
} from './Site';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';

/**
 * Request for an Pathway composition
 * @export
 * @interface PathwayDto
 */
export interface PathwayDto {
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    pathway?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    pathwayId?: string;
    /**
     * 
     * @type {Procedure}
     * @memberof PathwayDto
     */
    primaryProcedure?: Procedure;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    status?: string;
    /**
     * 
     * @type {Array<Step>}
     * @memberof PathwayDto
     */
    steps?: Array<Step>;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    initialAppointmentTime?: string;
    /**
     * 
     * @type {Array<Procedure>}
     * @memberof PathwayDto
     */
    secondaryProcedures?: Array<Procedure>;
    /**
     * 
     * @type {Clinician}
     * @memberof PathwayDto
     */
    consultant?: Clinician;
    /**
     * 
     * @type {Site}
     * @memberof PathwayDto
     */
    site?: Site;
}

/**
 * Check if a given object implements the PathwayDto interface.
 */
export function instanceOfPathwayDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PathwayDtoFromJSON(json: any): PathwayDto {
    return PathwayDtoFromJSONTyped(json, false);
}

export function PathwayDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pathway': !exists(json, 'pathway') ? undefined : json['pathway'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'pathwayId': !exists(json, 'pathwayId') ? undefined : json['pathwayId'],
        'primaryProcedure': !exists(json, 'primaryProcedure') ? undefined : ProcedureFromJSON(json['primaryProcedure']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'steps': !exists(json, 'steps') ? undefined : ((json['steps'] as Array<any>).map(StepFromJSON)),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'initialAppointmentTime': !exists(json, 'initialAppointmentTime') ? undefined : json['initialAppointmentTime'],
        'secondaryProcedures': !exists(json, 'secondaryProcedures') ? undefined : ((json['secondaryProcedures'] as Array<any>).map(ProcedureFromJSON)),
        'consultant': !exists(json, 'consultant') ? undefined : ClinicianFromJSON(json['consultant']),
        'site': !exists(json, 'site') ? undefined : SiteFromJSON(json['site']),
    };
}

export function PathwayDtoToJSON(value?: PathwayDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pathway': value.pathway,
        'description': value.description,
        'pathwayId': value.pathwayId,
        'primaryProcedure': ProcedureToJSON(value.primaryProcedure),
        'status': value.status,
        'steps': value.steps === undefined ? undefined : ((value.steps as Array<any>).map(StepToJSON)),
        'uuid': value.uuid,
        'initialAppointmentTime': value.initialAppointmentTime,
        'secondaryProcedures': value.secondaryProcedures === undefined ? undefined : ((value.secondaryProcedures as Array<any>).map(ProcedureToJSON)),
        'consultant': ClinicianToJSON(value.consultant),
        'site': SiteToJSON(value.site),
    };
}

