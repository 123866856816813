import { ADD_PROCEDURE_TEMPLATE } from '../actions/Actions';
import { PROCEDURE_TEMPLATE_LOADING } from '../actions/Actions';
import { PROCEDURE_TEMPLATE_FETCH } from '../actions/Actions';
import { PROCEDURE_TEMPLATE_FETCH_LIST } from '../actions/Actions';
import { PROCEDURE_TEMPLATE_ERROR } from '../actions/Actions';
import { PROCEDURE_TEMPLATE_SUCCESS_CLEAR } from '../actions/Actions';
import { ADD_PROCEDURE } from '../actions/Actions';
import { ADD_SUBPROCEDURE } from '../actions/Actions';
import { PROCEDURE_FETCH_ALL } from '../actions/Actions';
import { PROCEDURE_FETCH } from '../actions/Actions';
import { DELETE_PROCEDURE } from '../actions/Actions';
import { DELETE_SUBPROCEDURE } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    procedureTemplateList: {list : [], searchTerm: ''},
    procedureTemplates: [],
    procedureList: {list : [], searchTerm: ''},
    fetchAllTime: 0,

    hasError: false,
    isLoading: false,
    errorMsg: null,
    success: false,
    updateSuccess: false,
    updateSuccessMessage: null
};

function updateProcedureTemplateList(array, procedure) {
    var found = false;
    var updatedArray = array.map((item) => {
        if (item.id !== procedure.id) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        found = true;
        return {
            ...procedure
        };
    });

    if ( !found ){
        updatedArray = [...array, procedure];
    }

    return updatedArray;
}

function updateProcedureList(array, procedure) {
    return array.map((item) => {
        if (item.id !== procedure.id) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
            ...procedure
        };
    });
}

function replaceProcedureTemplates(oldArray, newArray) {
    const filteredArray = oldArray.filter((item) => {
        const foundItem = newArray.find((t) => t.id == item.id);
        return foundItem === undefined;
    });

    return filteredArray.concat(newArray);
}

const proceduresReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case PROCEDURE_FETCH_ALL:{
            console.log("fetch PROCEDUREs", action);

            var procedureList = action.procedures;

            if (action.pageStart > 0) {
                const updatedTemplateArray = [...state.procedureList.list, ...procedureList];

                procedureList = Object.assign({}, state.procedureList, {
                    list : updatedTemplateArray,
                    currentTotal: updatedTemplateArray.length,
                });
            }  else {
                procedureList =  Object.assign({}, state.procedureList, {
                    list: procedureList,
                    currentTotal: procedureList.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    fetchTime: action.fetchTime,
                });
            }

                return Object.assign({}, state, {
                    procedureList: procedureList,
                    isLoading: false, 
                    success: true
                });
        }
        case PROCEDURE_FETCH: {
            const updatedTemplateArray = [...state.procedureList.list, ...action.procedures];
            const procedureList = Object.assign({}, state.procedureList, {
                list: updatedTemplateArray,
                currentTotal: updatedTemplateArray.length,
                total: action.total + action.procedures.length
            });

            return Object.assign({}, state, {
                procedureList: procedureList,
                isLoading: false,
                updateSuccess: action.updateSuccess
            });
        }
        case ADD_PROCEDURE:
            const newProcedure = action.newProcedure;
            if ( newProcedure ){
                const updatedTemplateArray = [...state.procedureList.list, action.procedure];
                const procedureList =  Object.assign({}, state.procedureList, {
                    list: updatedTemplateArray,
                    currentTotal: updatedTemplateArray.length,
                    total: action.total + 1
                });

                return Object.assign({}, state, {
                    procedureList: procedureList,
                    isLoading: false, 
                    updateSuccess: action.updateSuccess
                });
            } else {
                return state;
            }
            break;
        case DELETE_PROCEDURE: {   
            console.log("Delete procedure", action);
            console.log(state.procedureList.list);
            const procedureId = action.procedureId;
                const updatedTemplateArray = state.procedureList.list.filter(p => p.id !== procedureId);
                const procedureList =  Object.assign({}, state.procedureList, {
                    list: updatedTemplateArray,
                    currentTotal: updatedTemplateArray.length,
                    total: state.total - 1
                });

                return Object.assign({}, state, {
                    procedureList: procedureList,
                    isLoading: false, 
                    updateSuccess: action.updateSuccess
                });   
            }
        case ADD_SUBPROCEDURE:
        case DELETE_SUBPROCEDURE: {
            console.log("Update sub procedures", action);

            const procedureList =  Object.assign({}, state.procedureList, {
                list: updateProcedureList(state.procedureList.list, action.procedure)
            });
               
            return Object.assign({}, state, {
                procedureList: procedureList,
                isLoading: false, 
                updateSuccess: true
            });
        }
        case PROCEDURE_TEMPLATE_FETCH_LIST: {
            console.log("fetch PROCEDURE templates", action);

            const templateList = action.templateList;
            var procedureTemplateList = state.procedureTemplateList;

            if (action.pageStart > 0) {
                const updatedTemplateArray = [...state.procedureTemplateList.list, ...templateList];

                procedureTemplateList = Object.assign({}, procedureTemplateList, {
                    list: updatedTemplateArray,
                    currentTotal: updatedTemplateArray.length,
                });
            } else {
                procedureTemplateList = Object.assign({}, procedureTemplateList, {
                    list: templateList,
                    currentTotal: templateList.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    fetchTime: action.fetchTime,
                });
            }
            
            return Object.assign({}, state, {
                procedureTemplateList: procedureTemplateList,
                isLoading: false,
                success: true
            });
        }
        case PROCEDURE_TEMPLATE_FETCH: {
            console.log("fetched PROCEDURE template", action);

            const payload = action.payload;

            const fetchTime = new Date().getTime();
            const procedureTemplates = payload.map((t) => {
                const template = t == null ? null : JSON.parse(t.template);
                if ( template !== null ){
                    template.fetchTime = fetchTime;
                }
                return template;
            }).filter(filter => filter !== null);

            const newList = replaceProcedureTemplates(state.procedureTemplates, procedureTemplates);

            return Object.assign({}, state, {
                procedureTemplates: newList,
                isLoading: false, 
                success: true
            });
        }
        case PROCEDURE_TEMPLATE_ERROR:{
            console.log("PROCEDURE template error", action);
            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                success: false
            });
        }
        case PROCEDURE_TEMPLATE_LOADING:{
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        }
        case ADD_PROCEDURE_TEMPLATE: {
            console.log("Add PROCEDURE template", action);
            const template = action.procedureTemplate;

            const updatedTemplateArray =  updateProcedureTemplateList(state.procedureTemplateList.list, template);

            const procedureTemplateList = Object.assign({}, state.procedureTemplateList, {
                list : updatedTemplateArray,
                currentTotal: updatedTemplateArray.length,
            });

            return Object.assign({}, state, {
                procedureTemplateList: procedureTemplateList,
                isLoading: false, 
                success: true
            });
        }
        case PROCEDURE_TEMPLATE_SUCCESS_CLEAR:{
            console.log("Clear success flag");

            return Object.assign({}, state, {
                success: false,
                updateSuccess: false
            });
        }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export default proceduresReducer;