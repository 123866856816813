import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsEN from './en/en.json';
import errorsEN from './en/errors.json';

const resources = {
  en: {
    translations: translationsEN,
    errors: errorsEN
  }
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: resources,
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en'];

export default i18n;
