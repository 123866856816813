import { TASKS_FETCH_LIST } from './Actions';
import { TASKS_MARK_AS_COMPLETE } from './Actions';
import { TASKS_GET_TYPE_COUNT_LIST } from './Actions';
import { TASKS_IS_LOADING  } from './Actions';
import { TASKS_ERROR  } from './Actions';

import { TasksEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

var moment = require('moment');

const tasksError = (bool, errorMsg) => {
  return {
      type: TASKS_ERROR,
      hasError: bool,
      errorMsg: errorMsg
  }
};

const tasksLoading = (bool) => {
  return {
      type: TASKS_IS_LOADING,
      isLoading: bool
  }
};


const clearTasksError = () => {
  return {
      type: TASKS_ERROR,
      hasError: false,
      errorMsg: null
  }
};

const fetchTasksSuccessHandler = (payload, searchTerm) => {
  return {
      type: TASKS_FETCH_LIST,
      tasks: payload == null ? {} : payload.tasks,
      total: payload.total,
      pageStart: payload.start,
      searchTerm: searchTerm,
      orderTerm: payload.orderTerm,
      orderDirection: payload.orderDirection
  }
};

const fetchTasks = (searchTerm, searchTermMapping, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetching task list');

  return (dispatch, getState) => {
      dispatch(tasksLoading(true));

      var config = getConfiguation(dispatch, getState);
      var tasksApi = new TasksEndpointApi(config);

      const tasksSearchRequest = {
          "pageSize": pageSize,
          "start": start,
          "searchTerms": searchTermMapping ? searchTermMapping : [],
          "orderTerm": orderTerm,
          "orderDirection": orderDirection
      };

   var request = tasksApi.findTasks({ "searchRequest": tasksSearchRequest })
   onRequest(request, dispatch, fetchTasksSuccessHandler,  tasksError, tasksLoading, searchTerm)
  };
};

const markTaskAsCompleteSuccessHandler = (payload, taskId) => {
  return {
      type: TASKS_MARK_AS_COMPLETE,
      taskId: taskId,
  }
};

const markTaskAsComplete = (taskId) => {
  console.log("Mark task as complete");

  var completeDate = moment(new Date()).format('YYYY-MM-DD');

  const taskComplete = { 
    "id": taskId,
    "complete" : completeDate 
    }

    console.log(taskComplete);

  return (dispatch, getState) => {
      dispatch(tasksLoading(true));

      var config = getConfiguation(dispatch, getState);
      var tasksApi = new TasksEndpointApi(config);
      var request = tasksApi.markTaskComplete({ "taskCompleteDto" : taskComplete});
      onRequest(request, dispatch, markTaskAsCompleteSuccessHandler,  tasksError, tasksLoading,  taskId)
  };
};

const fetchTaskCountListSuccessHandler = (payload) => {
  return {
      type: TASKS_GET_TYPE_COUNT_LIST,
      taskCountList : payload
  }
};

const fetchTaskCountList = () => {
  console.log("Fetch task count");

  return (dispatch, getState) => {
      dispatch(tasksLoading(true));

      var config = getConfiguation(dispatch, getState);
      var tasksApi = new TasksEndpointApi(config);
      var request = tasksApi.getTaskTypeCountList();
      onRequest(request, dispatch, fetchTaskCountListSuccessHandler, tasksError, tasksLoading)
  };
};

export default {
  clearTasksError,
  fetchTasks,
  markTaskAsComplete,
  fetchTaskCountList
}