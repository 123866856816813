/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Prom
 */
export interface Prom {
    /**
     * 
     * @type {string}
     * @memberof Prom
     */
    promsId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Prom
     */
    promsSubTemplates?: Array<string>;
}

/**
 * Check if a given object implements the Prom interface.
 */
export function instanceOfProm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PromFromJSON(json: any): Prom {
    return PromFromJSONTyped(json, false);
}

export function PromFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promsId': !exists(json, 'promsId') ? undefined : json['promsId'],
        'promsSubTemplates': !exists(json, 'promsSubTemplates') ? undefined : json['promsSubTemplates'],
    };
}

export function PromToJSON(value?: Prom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promsId': value.promsId,
        'promsSubTemplates': value.promsSubTemplates,
    };
}

