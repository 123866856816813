import { ADD_PROMS_TEMPLATE } from './Actions';
import { PROMS_TEMPLATE_ERROR } from './Actions';
import { PROMS_TEMPLATE_LOADING } from './Actions';
import { PROMS_TEMPLATE_SUCCESS_CLEAR } from './Actions';
import { PROMS_TEMPLATE_FETCH } from './Actions';
import { PROMS_TEMPLATE_FETCH_ALL } from './Actions';
import { PROMS_TEMPLATE_FETCH_LIST } from './Actions';
import { PROMS_TEMPLATE_PDF_UPLOAD } from './Actions';
import { PROMS_TEMPLATE_PDF_DELETION } from './Actions';

import { PromsUiTemplateEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';


const clearError = () => {
  return {
    type: PROMS_TEMPLATE_ERROR,
    hasError: false
  }
};

const onError = (bool, errorMsg) => {
  return {
    type: PROMS_TEMPLATE_ERROR,
    hasError: bool,
    errorMsg: errorMsg
  }
};

const isLoading = (bool) => {
  return {
    type: PROMS_TEMPLATE_LOADING,
    isLoading: bool
  }
};


const clearSuccess = () => {
  return {
    type: PROMS_TEMPLATE_SUCCESS_CLEAR
  }
};

const onFetchListSuccess = (payload, searchTerm) => {
  return {
    type: PROMS_TEMPLATE_FETCH_LIST,
    templateList: payload == null ? {} : payload.templateList,
        total: payload.total,
        pageStart: payload.start,
        searchTerm: searchTerm,
        orderTerm: payload.orderTerm,
        orderDirection: payload.orderDirection,
        fetchTime: new Date().getTime(),
        success: true
  }
};


const fetchTemplateList = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log("Fetch template list");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest  = {
      "searchTerms": {"NAME" : searchTerm},
      "pageSize": pageSize,
      "start": start,
      "orderTerm": orderTerm,
      "orderDirection": orderDirection
  }

    var config = getConfiguation(dispatch, getState);
    var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
    var request = uiTemplateApi.getPromUiTemplateList({"searchRequest" : searchRequest});
    onRequest(request, dispatch, onFetchListSuccess, onError, isLoading, searchTerm, "Error fetching templates");
  };
}

const onUploadTemplateSuccess = (response, body) => {
  return {
    type: ADD_PROMS_TEMPLATE,
    success: true
  }
};

const addPromsTemplate = (template) => {
  console.log("upload Template");

  const body = {
    name: template.name,
    description: template.description,
    templateId: template.templateId,
    id: template.id,
    template: JSON.stringify(template)
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
    var request = uiTemplateApi.importUiTemplate({ uiTemplateDto: body })
    onRequest(request, dispatch, onUploadTemplateSuccess, onError, isLoading, body, "Error importing template");
  };
}

const onFetchTemplateSuccess = (response) => {
  return {
    type: PROMS_TEMPLATE_FETCH,
    payload: response,
    success: true
  }
};

const fetchTemplates = (ids) => {
  console.log("Fetch template");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
    var request = uiTemplateApi.getAllPromUiTemplateJson({ ids: ids })
    onRequest(request, dispatch, onFetchTemplateSuccess, onError, isLoading, null, "Error fetching template");
  };
}

const onFetchAllTemplatesSuccess = (response) => {
  return {
    type: PROMS_TEMPLATE_FETCH_ALL,
    payload: response,
    success: true,
    fetchAllTime: new Date().getTime()
  }
};

const fetchAllTemplates = () => {
  console.log("Fetch all templates");

  return (dispatch, getState) => {
    // Check time since templates last fetched.
    const lastFetchTime = getState().questionnaires.fetchAllTime;
    const timeSinceFetch = new Date().getTime() - lastFetchTime;

    console.log(lastFetchTime);
    console.log(timeSinceFetch);
    console.log(window._env_.REACT_APP_RESOURCE_CACHE_TIME);


    if (timeSinceFetch > window._env_.REACT_APP_RESOURCE_CACHE_TIME) {
      dispatch(isLoading(true));

      console.log("SENDING");

      var config = getConfiguation(dispatch, getState);
      var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
      var request = uiTemplateApi.getAllPromUiTemplateJson();
      onRequest(request, dispatch, onFetchAllTemplatesSuccess, onError, isLoading, null, "Error fetching templates");
    }
  };
}

const onUploadQuestionnairePdfSuccess = (response, successData) => {
  return {
    type: PROMS_TEMPLATE_PDF_UPLOAD,
    payload: successData
  }
};

const uploadQuestionnairePdf = (file, templateId, promsId) => {
  console.log(file);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const requestBody = {
      promsId: promsId,
      templateId: templateId,
      file: file
    }

    const successData = {
      promsId: promsId,
      templateId: templateId,
      fileName: file.name
    }

    var config = getConfiguation(dispatch, getState);
    var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
    var request = uiTemplateApi.uploadPromsQuestionnaire(requestBody)
    onRequest(request, dispatch, onUploadQuestionnairePdfSuccess, onError, isLoading, successData, "Error uploading file");
  };
}

const onNonReducerRequest = (promise, dispatch, successHandler, errorMessage, successData, errorHandler) => {
    promise.then((response) => {
      dispatch(isLoading(false));
      successHandler(response, successData);
    }
    ).catch((e) => {
      // Report error
      console.log("Error");
      console.log(e);
      dispatch(isLoading(false));
      errorHandler(errorMessage);
    });
  }

const fetchQuestionnairePdf = (fileName, templateId, promsId, successHandler, errorHandler) => {
  console.log(fileName);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const requestBody = {
      fileName: fileName,
      templateId: templateId,
      promsId: promsId
    }

    var config = getConfiguation(dispatch, getState);
    var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
    var request = uiTemplateApi.downloadPromsQuestionnaire(requestBody)
    onNonReducerRequest(request, dispatch, successHandler, "Error fetching file", null, errorHandler);
  };
}

const onDeleteQuestionnairePdfSuccess = (response, successData) => {
  return {
      type: PROMS_TEMPLATE_PDF_DELETION,
      payload: successData
  }
};

const deleteQuestionnairePdf = (fileName, templateId, promsId) => {
  console.log(fileName);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const requestBody = {
      fileName: fileName,
      templateId: templateId,
      promsId: promsId
    }

    var config = getConfiguation(dispatch, getState);
    var uiTemplateApi = new PromsUiTemplateEndpointApi(config);
    var request = uiTemplateApi.deletePromsQuestionnaire(requestBody)
    onRequest(request, dispatch, onDeleteQuestionnairePdfSuccess,  onError, isLoading, requestBody, "Error deleting file");
  };
}

export default {
  addPromsTemplate,
  fetchTemplateList,
  fetchTemplates,
  fetchAllTemplates,
  clearError,
  clearSuccess,
  uploadQuestionnairePdf,
  fetchQuestionnairePdf,
  deleteQuestionnairePdf
}

