/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';

/**
 * 
 * @export
 * @interface PermissionsDto
 */
export interface PermissionsDto {
    /**
     * 
     * @type {Array<Permission>}
     * @memberof PermissionsDto
     */
    grantedPermissions?: Array<Permission>;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof PermissionsDto
     */
    removedPermissions?: Array<Permission>;
}

/**
 * Check if a given object implements the PermissionsDto interface.
 */
export function instanceOfPermissionsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PermissionsDtoFromJSON(json: any): PermissionsDto {
    return PermissionsDtoFromJSONTyped(json, false);
}

export function PermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grantedPermissions': !exists(json, 'grantedPermissions') ? undefined : ((json['grantedPermissions'] as Array<any>).map(PermissionFromJSON)),
        'removedPermissions': !exists(json, 'removedPermissions') ? undefined : ((json['removedPermissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

export function PermissionsDtoToJSON(value?: PermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grantedPermissions': value.grantedPermissions === undefined ? undefined : ((value.grantedPermissions as Array<any>).map(PermissionToJSON)),
        'removedPermissions': value.removedPermissions === undefined ? undefined : ((value.removedPermissions as Array<any>).map(PermissionToJSON)),
    };
}

