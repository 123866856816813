import { PROMS_DEV_IS_LOADING } from './Actions';
import { PROMS_DEV_FETCH_OPT_LIST } from './Actions';
import { PROMS_DEV_UPLOAD_TEMPLATE } from './Actions';
import { PROMS_DEV_HAS_ERROR } from './Actions';
import { PROMS_DEV_SUCCESS_CLEAR } from './Actions';

import { TemplateDevEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const onError = (bool, errorMsg) => {
  return {
    type: PROMS_DEV_HAS_ERROR,
    hasError: bool,
    errorMsg: errorMsg
  }
};

const isLoading = (bool) => {
  return {
    type: PROMS_DEV_IS_LOADING,
    isLoading: bool
  }
};


const clearSuccess = () => {
  return {
    type: PROMS_DEV_SUCCESS_CLEAR
  }
};

const onFetchListSuccess = (payload, templateType) => {
  return {
    type: PROMS_DEV_FETCH_OPT_LIST,
    templateType: templateType,
    list: payload == null ? {} : payload.templateDtoList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm: payload.searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime()
  }
};

const fetchTemplateList = (templateType, searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log("Fetch template list");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest = {
      "searchTerms": { "TEMPLATEID": searchTerm },
      "pageSize": pageSize,
      "start": start,
      "orderTerm": orderTerm,
      "orderDirection": orderDirection
    }

    var config = getConfiguation(dispatch, getState);
    var templateApi = new TemplateDevEndpointApi(config);
    var request = templateApi.getOptTemplateList({ templateType: templateType, searchRequest: searchRequest });
    onRequest(request, dispatch, onFetchListSuccess, onError, isLoading, templateType, "Error fetching templates");
  };
}

const onUploadTemplateSuccess = (response, templateType) => {
  return {
    type: PROMS_DEV_UPLOAD_TEMPLATE,
    payload: response,
    success: true,
    templateType: templateType
  }
};


const uploadOptTemplate = (optTemplate, templateType) => {
  console.log("upload Template");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var templateApi = new TemplateDevEndpointApi(config);
    var request = templateApi.importOptTemplate({ templateType: templateType, optTemplateDto: { template: optTemplate } });
    onRequest(request, dispatch, onUploadTemplateSuccess, onError, isLoading, templateType, "Error uploading template");
  };
}

export default {
  fetchTemplateList,
  uploadOptTemplate,
  clearSuccess,
  onError,

  PROMS: 'proms',
  PROCEDURE: 'procedure'
}

