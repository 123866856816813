/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessibleNameDto } from './AccessibleNameDto';
import {
    AccessibleNameDtoFromJSON,
    AccessibleNameDtoFromJSONTyped,
    AccessibleNameDtoToJSON,
} from './AccessibleNameDto';
import type { SubProcedureCode } from './SubProcedureCode';
import {
    SubProcedureCodeFromJSON,
    SubProcedureCodeFromJSONTyped,
    SubProcedureCodeToJSON,
} from './SubProcedureCode';

/**
 * 
 * @export
 * @interface Procedure
 */
export interface Procedure {
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    procedureId?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    scheduled?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    performed?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    performedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    title?: string;
    /**
     * 
     * @type {Array<SubProcedureCode>}
     * @memberof Procedure
     */
    subProcedureCodes?: Array<SubProcedureCode>;
    /**
     * 
     * @type {Array<AccessibleNameDto>}
     * @memberof Procedure
     */
    accessibleNames?: Array<AccessibleNameDto>;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    subProcedureId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Procedure
     */
    updatePendingProms?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    additionalInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    procedureSide?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    uiTemplate?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    compositionUUID?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    procedureDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Procedure
     */
    prepopulatedFields?: Array<string>;
}

/**
 * Check if a given object implements the Procedure interface.
 */
export function instanceOfProcedure(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProcedureFromJSON(json: any): Procedure {
    return ProcedureFromJSONTyped(json, false);
}

export function ProcedureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Procedure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'procedureId': !exists(json, 'procedureId') ? undefined : json['procedureId'],
        'scheduled': !exists(json, 'scheduled') ? undefined : json['scheduled'],
        'performed': !exists(json, 'performed') ? undefined : json['performed'],
        'performedBy': !exists(json, 'performedBy') ? undefined : json['performedBy'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subProcedureCodes': !exists(json, 'subProcedureCodes') ? undefined : ((json['subProcedureCodes'] as Array<any>).map(SubProcedureCodeFromJSON)),
        'accessibleNames': !exists(json, 'accessibleNames') ? undefined : ((json['accessibleNames'] as Array<any>).map(AccessibleNameDtoFromJSON)),
        'subProcedureId': !exists(json, 'subProcedureId') ? undefined : json['subProcedureId'],
        'updatePendingProms': !exists(json, 'updatePendingProms') ? undefined : json['updatePendingProms'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
        'procedureSide': !exists(json, 'procedureSide') ? undefined : json['procedureSide'],
        'uiTemplate': !exists(json, 'uiTemplate') ? undefined : json['uiTemplate'],
        'compositionUUID': !exists(json, 'compositionUUID') ? undefined : json['compositionUUID'],
        'procedureDescription': !exists(json, 'procedureDescription') ? undefined : json['procedureDescription'],
        'prepopulatedFields': !exists(json, 'prepopulatedFields') ? undefined : json['prepopulatedFields'],
    };
}

export function ProcedureToJSON(value?: Procedure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'procedureId': value.procedureId,
        'scheduled': value.scheduled,
        'performed': value.performed,
        'performedBy': value.performedBy,
        'title': value.title,
        'subProcedureCodes': value.subProcedureCodes === undefined ? undefined : ((value.subProcedureCodes as Array<any>).map(SubProcedureCodeToJSON)),
        'accessibleNames': value.accessibleNames === undefined ? undefined : ((value.accessibleNames as Array<any>).map(AccessibleNameDtoToJSON)),
        'subProcedureId': value.subProcedureId,
        'updatePendingProms': value.updatePendingProms,
        'additionalInfo': value.additionalInfo,
        'procedureSide': value.procedureSide,
        'uiTemplate': value.uiTemplate,
        'compositionUUID': value.compositionUUID,
        'procedureDescription': value.procedureDescription,
        'prepopulatedFields': value.prepopulatedFields,
    };
}

