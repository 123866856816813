import { FETCH_PATIENTS } from '../actions/Actions';
import { ADD_PATIENT } from '../actions/Actions';
import { UPDATE_PATIENT } from '../actions/Actions';
import { PATIENT_UPDATE_ERROR } from '../actions/Actions';
import { PATIENTS_LOADING } from '../actions/Actions';
import { PATIENTS_SUCCESS_CLEAR } from '../actions/Actions';
import { FETCH_PATIENT_DETAILS } from '../actions/Actions';
import { PATIENT_EMAIL_SENT_SUCCESS } from '../actions/Actions';
import { PATIENT_SMS_SENT_SUCCESS } from '../actions/Actions';
import { PATIENT_DETAILS_CLEAR } from '../actions/Actions';

import { LOGOUT } from '../actions/Actions';

const initialState = {
    patients: [],
    total: 0,
    currentTotal: 0,
    searchTerm: "",
    orderTerm: "",
    orderDirection: "asc",
    fetchTime: null,

    patientDetails: null,

    hasError: false,
    isLoading: false,
    errorMsg: null,

    patientUpdateSuccess: false,
    patientEmailSentSuccess: false,
    patientSmsSentSuccess: false
};

function updatePatientDataInArray(array, patient) {
    return array.map((item, index) => {
        if (item.primaryId !== patient.primaryId
            || item.primaryIdNamespace !== patient.primaryIdNamespace
        ) {
            // This isn't the item we care about - keep it as-is
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        console.log("Found");
        console.log(patient);
        console.log(item);
        return {
            ...item,
            ...patient
        };
    });
}

const patientPrimaryIdPopulator = (patients) => {
    var updatedPatients = patients.map((patient) => {
        return patientPrimaryId(patient);
    });

    return updatedPatients;
}

const hospitalNumberId = window._env_.REACT_APP_HOSPITAL_ID;

const patientPrimaryId = (patient) => {
    var value = patient.userIds[hospitalNumberId];
    if ( value ){
        patient.primaryId = value;
        patient.primaryIdNamespace = hospitalNumberId;
    } else {
        const [firstKey] = Object.keys(patient.userIds);
        const firstValue = patient.userIds[firstKey];
        patient.primaryId = firstValue;
        patient.primaryIdNamespace = firstKey;
    }

    return patient;
}

const patientsReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case FETCH_PATIENTS: {
            console.log("Fetch patients", action);

            var patients = patientPrimaryIdPopulator(action.patients);

            if (action.pageStart > 0) {
                const updatedPatientArray = [...state.patients, ...patients];

                return Object.assign({}, state, {
                    patients: updatedPatientArray,
                    currentTotal: updatedPatientArray.length,
                    isLoading: false
                });
            } else {
                return Object.assign({}, state, {
                    patients: patients,
                    currentTotal: patients.length,
                    total: action.total,
                    searchTerm: action.searchTerm,
                    orderTerm: action.orderTerm,
                    orderDirection: action.orderDirection,
                    isLoading: false,
                    fetchTime: action.fetchTime
                });
            }
        }
        case FETCH_PATIENT_DETAILS: {
            console.log("Fetch patients details", action);

            var patientDetails = patientPrimaryId(action.patientDetails);

            return Object.assign({}, state, {
                patientDetails: patientDetails,
                isLoading: false
            });
        }
        case PATIENT_DETAILS_CLEAR: {
            console.log("Clear patients details", action);

            return Object.assign({}, state, {
                patientDetails: null
            });
        }
        case ADD_PATIENT: {
            console.log("Add patient", action);

            const patient = patientPrimaryId(action.patient)

            return Object.assign({}, state, {
                patients: [...state.patients, patient]
            });
        }
        case UPDATE_PATIENT: {
            console.log("Update patient", action);

            var patients = state.patients;

            const patient = patientPrimaryId(action.patient)

            if (action.patient !== null) {
                patients = updatePatientDataInArray(state.patients, patient);
            }

            var patientDetails = state.patientDetails;
            if ( patientDetails ){
            if ( patientDetails.primaryId === patient.primaryId
                && patientDetails.primaryIdNamespace === patientDetails.primaryIdNamespace){
                    patientDetails = patient;
                }
            }

            return Object.assign({}, state, {
                patients: patients,
                patientUpdateSuccess: true,
                patientDetails: patientDetails
            });
        }
        case PATIENT_EMAIL_SENT_SUCCESS: {
            console.log("Patient email sent success", action);

            return Object.assign({}, state, {
                patientEmailSentSuccess: true
            });
        }
        case PATIENT_SMS_SENT_SUCCESS: {
            console.log("Patient SMS sent success", action);

            return Object.assign({}, state, {
                patientSmsSentSuccess: true
            });
        }
        case LOGOUT:
            return initialState;
        case PATIENTS_SUCCESS_CLEAR:
            return Object.assign({}, state, {
                patientUpdateSuccess: false,
                patientEmailSentSuccess: false,
                patientSmsSentSuccess: false
            });
        case PATIENTS_LOADING:
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case PATIENT_UPDATE_ERROR:
            console.log('haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg,
                updateSuccess: false
            });
        default:
            return state;
    }
}

export default patientsReducer;