import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import patientsReducer from './reducers/PatientsReducer';
import configResourcesReducer from './reducers/ConfigResourcesReducer';
import questionnairesReducer from './reducers/QuestionnaireReducer';
import loginReducer from './reducers/LoginReducer';
import serverComfigReducer from './reducers/ServerConfigReducer';
import patientDataReducer from './reducers/PatientDataReducer';
import staffReducer from './reducers/StaffReducer';
import hcpDataReducer from './reducers/HCPDataReducer';
import proceduresReducer from './reducers/ProceduresReducer';
import pathwaysReducer from './reducers/PathwaysReducer';
import promTemplateDevReducer from './reducers/PromTemplateDevReducer';
import tasksReducer from './reducers/TasksReducer';
import auditReducer from './reducers/AuditReducer';
import widgetDataReducer from './reducers/WidgetDataReducer';

// Export actions
export { default as PatientActions } from './actions/PatientActions';
export { default as QuestionnaireActions } from './actions/QuestionnaireActions';
export { default as LoginActions } from './actions/Login';
export { default as ServerConfigActions } from './actions/ServerConfig';
export { default as PatientDataActions } from './actions/PatientData';
export { default as StaffActions } from './actions/StaffActions';
export { default as HCPDataActions } from './actions/HCPDataActions';
export { default as ProcedureActions } from './actions/ProcedureActions';
export { default as PathwayActions } from './actions/PathwayActions';
export { default as ConfigActions } from './actions/ConfigActions';
export { default as PromTemplateDevActions } from './actions/PromTemplateDevActions';
export { default as TasksActions } from './actions/TaskActions';
export { default as AuditActions } from './actions/AuditActions';
export { default as WidgeDataActions } from './actions/WidgetDataActions';

// Create login persist config
const loginPersistConfig = {
  key: 'login',
  storage: storage,
  blacklist: ['hasError', 'isLoading', 'tokenRefreshed']
}

// export combined reducer
export const reducer = combineReducers({
  patients: patientsReducer,
  questionnaires: questionnairesReducer,
  patientData: patientDataReducer,
  configResources: configResourcesReducer,
  login: persistReducer(loginPersistConfig, loginReducer),
  serverConfig: serverComfigReducer,
  staffData: staffReducer,
  hcpData: hcpDataReducer,
  proceduresData: proceduresReducer,
  pathwaysData: pathwaysReducer,
  promDevTemplates: promTemplateDevReducer,
  tasks: tasksReducer,
  audit: auditReducer,
  widgetData: widgetDataReducer
});


