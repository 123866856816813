import { IS_LOGGED } from './Actions';
import { LOGIN_HAS_ERROR } from './Actions';
import { LOGIN_IS_LOADING } from './Actions';
import { LOGIN } from './Actions';
import { LOGOUT } from './Actions';
import { USER_PERMISSIONS } from './Actions';

import { UserApi } from 'promsApi';
import getConfiguation from './Configuration';


const isLogged = (bool) => {
    return {
        type: IS_LOGGED,
        isLogged: bool
    }
};

const loginHasError = (bool, errorMsg) => {
    return {
        type: LOGIN_HAS_ERROR,
        hasError: bool,
        errorMsg: errorMsg
    }
};

const loginIsLoading = (bool) => {
    return {
        type: LOGIN_IS_LOADING,
        isLoading: bool
    }
};

const updateTokens = (payload) => {
    return {
        type: LOGIN,
        payload: payload
    }
};

const updatePermissions = (response) => {
    console.log(response);
    return {
        type: USER_PERMISSIONS,
        permissions: response.permissions
    }
};

const logout = () => {
    return {
        type: LOGOUT
    }
};

const timeOutLogout = () => {
    return {
        type: LOGOUT,
        timeOut: true
    }
};

const getPermissions = (payload, successFunction, errorFunction) => {
    console.log('Fetching permissions');
    
    return (dispatch, getState) => {
        dispatch(loginIsLoading(true));
        dispatch(updateTokens(payload));

        var config = getConfiguation(dispatch, getState);
        var userApi = new UserApi(config);
        var request = userApi.getPermissions();

        request.then((response) => {
            console.log(response);
            dispatch(loginIsLoading(false));
            dispatch(updatePermissions(response));
            dispatch(successFunction);
        }
        ).catch((e) => {
            // Report error
            console.log("Error");
            console.log(e);
            dispatch(loginIsLoading(false));
            dispatch(loginHasError(true));
        });
    };
};

export default {
    logout,
    timeOutLogout,
    getPermissions
}
