
import { ADD_PROCEDURE_TEMPLATE } from './Actions';
import { PROCEDURE_TEMPLATE_ERROR } from './Actions';
import { PROCEDURE_TEMPLATE_LOADING } from './Actions';
import { PROCEDURE_TEMPLATE_SUCCESS_CLEAR } from './Actions';
import { PROCEDURE_TEMPLATE_FETCH } from './Actions';
import { PROCEDURE_TEMPLATE_FETCH_LIST } from './Actions';
import { ADD_PROCEDURE } from './Actions';
import { ADD_SUBPROCEDURE } from './Actions';
import { PROCEDURE_FETCH_ALL } from './Actions';
import { PROCEDURE_FETCH } from './Actions';
import { DELETE_PROCEDURE } from './Actions';
import { DELETE_SUBPROCEDURE } from './Actions';

import { ProceduresEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const clearError = () => {
  return {
    type: PROCEDURE_TEMPLATE_ERROR,
    hasError: false
  }
};

const onError = (bool, errorMsg) => {
  return {
    type: PROCEDURE_TEMPLATE_ERROR,
    hasError: bool,
    errorMsg: errorMsg
  }
};

const isLoading = (bool) => {
  return {
    type: PROCEDURE_TEMPLATE_LOADING,
    isLoading: bool
  }
};

const clearSuccess = () => {
  return {
    type: PROCEDURE_TEMPLATE_SUCCESS_CLEAR
  }
};

const onFetchListSuccess = (payload, searchTerm) => {
  return {
    type: PROCEDURE_TEMPLATE_FETCH_LIST,
    templateList: payload == null ? {} : payload.templateList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm: searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime(),
    success: true
  }
};

const fetchTemplateList = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log("Fetch template list");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest  = {
      "searchTerms": {"NAME" : searchTerm},
      "pageSize": pageSize,
      "start": start,
      "orderTerm": orderTerm,
      "orderDirection": orderDirection
  }

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.getProcedureUiTemplateList({"searchRequest" : searchRequest});
    onRequest(request, dispatch, onFetchListSuccess, onError, isLoading, searchTerm,  "Error fetching templates");
  };
}

const onUploadTemplateSuccess = (response, body) => {
  return {
    type: ADD_PROCEDURE_TEMPLATE,
    procedureTemplate: body,
    success: true
  }
};

const addProcedureTemplate = (template) => {
  console.log("upload Template");

  console.log(template);

  const body = {
    name: template.name,
    description: template.description,
    templateId: template.templateId,
    id: template.id,
    template: JSON.stringify(template)
  }

  if ( template.bodySiteDefaultValueField ){
    body.prepopulatedFields = ["body_site"];
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.importUiTemplate1({ uiTemplateDto: body })
    onRequest(request, dispatch, onUploadTemplateSuccess,  onError, isLoading, body, "Error importing template");
  };
}

const onFetchTemplateSuccess = (response) => {
  return {
    type: PROCEDURE_TEMPLATE_FETCH,
    payload: response,
    success: true
  }
};

const fetchTemplates = (ids) => {
  return (dispatch, getState) => {
    const templateList = getState().proceduresData.procedureTemplates;

    console.log(ids);
    const filteredIds = ids.filter((id) => {
      const template = templateList.find((p) => {
        return p.id === id;
      });
      console.log(template);

      return template === undefined || ((new Date().getTime() - template.FetchTime) > window._env_.REACT_APP_RESOURCE_CACHE_TIME)
    })

    console.log(filteredIds);
    if (filteredIds.length > 0) {
      dispatch(isLoading(true));
      dispatch(clearSuccess());

      var config = getConfiguation(dispatch, getState);
      var proceduresTemplateApi = new ProceduresEndpointApi(config);
      var request = proceduresTemplateApi.getUiTemplateJson1({ ids: ids });
      onRequest(request, dispatch, onFetchTemplateSuccess,  onError, isLoading, null, "Error fetching templates");
    } else {
      dispatch(onFetchTemplateSuccess([]));
    }
  };
}

const onAddProcedureSuccess = (response, newProcedure) => {
  return {
    type: ADD_PROCEDURE,
    procedure: response,
    newProcedure: newProcedure,
    success: true,
    updateSuccess: true,
    fetchAllTime: new Date().getTime()
  }
};

const addProcedure = (procedureData, templateId, uiTemplateId, prepopulatedFields) => {
  console.log("Add procedure");

  const body = {
    id: procedureData.id,
    procedureName: procedureData.procedureName,
    accessibleNames: procedureData.accessibleNames,
    genericCode: procedureData.genericCode,
    description: procedureData.description,
    templateId: templateId,
    uiTemplateId: uiTemplateId,
    prepopulatedFields: prepopulatedFields
  }

  const newProcedure = procedureData.id === undefined;

  if ( newProcedure ){
    // Add default subtemplate
    const exportMethodDto = {
      name: "openOutcomes SNOMED",
      validity: true,
      genericCode: procedureData.genericCode,
      specificCode: "Enter SNOMED CODE"
    };

    const subTemplateBody = {
      subProcedureName: procedureData.procedureName,
      exportMethodDtoList: [exportMethodDto]
    }

    body.subProcedureTemplateDtoList = [subTemplateBody];
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.addProcedure({ procedureTemplateDto: body })
    onRequest(request, dispatch, onAddProcedureSuccess,  onError, isLoading, newProcedure, "Error adding procedure");
  };
}

const onAddSubProcedureSuccess = (response) => {
  return {
    type: ADD_SUBPROCEDURE,
    procedure: response,
    success: true,
    fetchAllTime: new Date().getTime()
  }
};

const addSubProcedure = (subProcedureData, procedureId, parentProcedureName) => {
  console.log("Add sub procedure");
  console.log(subProcedureData);

  const id = subProcedureData.exportMethodDtoList ? subProcedureData.exportMethodDtoList[0].id : undefined;

  const exportMethodDto = {
    id: id,
    name: "openOutcomes SNOMED",
    validity: subProcedureData.validity,
    genericCode: subProcedureData.genericCode,
    specificCode: subProcedureData.specificCode
  };

  const body = {
    id: subProcedureData.id,
    subProcedureName: subProcedureData.subProcedureName,
    parentProcedureId: procedureId,
    parentProcedureName: parentProcedureName,
    exportMethodDtoList: [exportMethodDto]
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.addSubProcedure({ subProcedureTemplateDto: body })
    onRequest(request, dispatch, onAddSubProcedureSuccess,  onError, isLoading, null, "Error adding sub-procedure");
  };
}

const onFetchProceduresSuccess = (payload, searchTerm) => {
  return {
    type: PROCEDURE_FETCH_ALL,
    procedures: payload == null ? {} : payload.procedureList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm: searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime(),
    success: true
  }
};

const fetchProcedures = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log("Fetch procedure list");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest  = {
      "searchTerms": {"ALL" : searchTerm},
      "pageSize": pageSize,
      "start": start,
      "orderTerm": orderTerm,
      "orderDirection": orderDirection
  }

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.getProcedureList({"searchRequest" : searchRequest});
    onRequest(request, dispatch, onFetchProceduresSuccess,  onError, isLoading, searchTerm, "Error fetching procedures");
  };
}

const onFetchProcedureSuccess = (response) => {
  return {
    type: PROCEDURE_FETCH,
    procedures: response,
    success: true,
    updateSuccess: false
  }
};

const fetchProceduresById = (procedureIds) => {
  console.log("Fetch procedure");

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.getMultipleProceduresById({ ids: procedureIds});
    onRequest(request, dispatch, onFetchProcedureSuccess,  onError, isLoading, null, "Error fetching procedures");
  };
}


const onDeleteSubProcedureSuccess = (response) => {
  return {
    type: DELETE_SUBPROCEDURE,
    procedure: response,
    success: true
  }
};

const deleteSubProcedure = (subProcedureId, procedureId) => {
  console.log("Deleting sub procedure: " + subProcedureId);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.deleteSubProcedure({ procedureId: procedureId, subProcedureId: subProcedureId })
    onRequest(request, dispatch, onDeleteSubProcedureSuccess,  onError, isLoading, null, "Error deleting sub-procedure");
  };
}

const onDeleteProcedureSuccess = (response, procedureId) => {
  return {
    type: DELETE_PROCEDURE,
    procedureId: procedureId,
    success: true
  }
};

const deleteProcedure = (procedureId) => {
  console.log("Deleting procedure group: " + procedureId);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    var config = getConfiguation(dispatch, getState);
    var proceduresTemplateApi = new ProceduresEndpointApi(config);
    var request = proceduresTemplateApi.deleteProcedure({ procedureId: procedureId })
    onRequest(request, dispatch, onDeleteProcedureSuccess,  onError, isLoading, procedureId, "Error deleting procedure group");
  };
}


export default {
  addProcedureTemplate,
  addProcedure,
  addSubProcedure,
  deleteProcedure,
  deleteSubProcedure,
  fetchProcedures,
  fetchProceduresById,
  fetchTemplateList,
  fetchTemplates,
  clearError,
  clearSuccess
}