import { CardActions } from '@material-ui/core';
import keycloak from 'src/KeyCloak';
import { IS_LOGGED } from '../actions/Actions';
import { LOGIN_HAS_ERROR } from '../actions/Actions';
import { LOGIN_IS_LOADING } from '../actions/Actions';
import { LOGIN } from '../actions/Actions';
import { LOGOUT } from '../actions/Actions';
import { USER_PERMISSIONS } from '../actions/Actions';

const initialState = {
    hasError: false,
    isLoading: false,
    errorMsg: null,
    timeOut: false,

    username: null,
    keycloak: null,

    adminMode: false,
    permissions: []
};


const loginReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case IS_LOGGED:
            console.log('islogged', action);

            return Object.assign({}, state, {
                isLogged: action.isLogged,
                timeOut: false
            });
        case LOGIN_HAS_ERROR:
            console.log('haserror', action);

            return Object.assign({}, state, {
                hasError: action.hasError,
                errorMsg: action.errorMsg
            });
        case LOGIN_IS_LOADING:
            console.log('isloading', action);

            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case LOGIN:
            console.log('login', action);
            return Object.assign({}, state, {
                keycloak: payload.keycloak,
                username: payload.username,
                adminMode: payload.role !== "PATIENT",
                timeOut: false
            });
        case USER_PERMISSIONS:{
            console.log('login', action);
            return Object.assign({}, state, {
                permissions : action.permissions
            });
        }    
        case LOGOUT:
            console.log('logout', action);
            return Object.assign({}, state, {
                keycloak: null,
                permissions : [],
                timeOut: action.timeOut ? action.timeOut : false
            });
        default:
            return state
    }
}

export default loginReducer;