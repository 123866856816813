/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressDto } from './AddressDto';
import {
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
} from './AddressDto';
import type { OverrideMessageDto } from './OverrideMessageDto';
import {
    OverrideMessageDtoFromJSON,
    OverrideMessageDtoFromJSONTyped,
    OverrideMessageDtoToJSON,
} from './OverrideMessageDto';

/**
 * Represents a User Profile of the API
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {Array<OverrideMessageDto>}
     * @memberof ProfileDto
     */
    overrideMessages?: Array<OverrideMessageDto>;
    /**
     * User ids. Key is namespace, Value is id
     * @type {{ [key: string]: string; }}
     * @memberof ProfileDto
     */
    userIds: { [key: string]: string; };
    /**
     * Title
     * @type {string}
     * @memberof ProfileDto
     */
    title?: string;
    /**
     * First primaryEntityName(s)
     * @type {string}
     * @memberof ProfileDto
     */
    firstNames?: string;
    /**
     * Last primaryEntityName
     * @type {string}
     * @memberof ProfileDto
     */
    lastName?: string;
    /**
     * Users date of birth
     * @type {string}
     * @memberof ProfileDto
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ProfileDto
     */
    address?: AddressDto;
    /**
     * Mobile number
     * @type {string}
     * @memberof ProfileDto
     */
    mobileNumber?: string;
    /**
     * Second contact number
     * @type {string}
     * @memberof ProfileDto
     */
    telNumber?: string;
    /**
     * Gender, valid responses MALE / FEMALE / OTHER / UNKNOWN
     * @type {string}
     * @memberof ProfileDto
     */
    gender?: string;
    /**
     * Gender code, NHS AdministrativeGender coding
     * @type {number}
     * @memberof ProfileDto
     */
    genderCode?: number;
    /**
     * Ethnicity Code
     * @type {string}
     * @memberof ProfileDto
     */
    ethnicityCode?: string;
    /**
     * Ethnicity Code, namespace
     * @type {string}
     * @memberof ProfileDto
     */
    ethnicityCodeNamespace?: string;
    /**
     * Users email address
     * @type {string}
     * @memberof ProfileDto
     */
    emailAddress?: string;
    /**
     * If communications sent via email is allowed
     * @type {boolean}
     * @memberof ProfileDto
     */
    emailAcceptable?: boolean;
    /**
     * If communications sent via SMS is allowed
     * @type {boolean}
     * @memberof ProfileDto
     */
    smsAcceptable?: boolean;
    /**
     * Users date of death
     * @type {string}
     * @memberof ProfileDto
     */
    dateOfDeath?: string;
    /**
     * If the patient is deceased
     * @type {boolean}
     * @memberof ProfileDto
     */
    patientDeceased?: boolean;
}

/**
 * Check if a given object implements the ProfileDto interface.
 */
export function instanceOfProfileDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userIds" in value;

    return isInstance;
}

export function ProfileDtoFromJSON(json: any): ProfileDto {
    return ProfileDtoFromJSONTyped(json, false);
}

export function ProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overrideMessages': !exists(json, 'overrideMessages') ? undefined : ((json['overrideMessages'] as Array<any>).map(OverrideMessageDtoFromJSON)),
        'userIds': json['userIds'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstNames': !exists(json, 'firstNames') ? undefined : json['firstNames'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'address': !exists(json, 'address') ? undefined : AddressDtoFromJSON(json['address']),
        'mobileNumber': !exists(json, 'mobileNumber') ? undefined : json['mobileNumber'],
        'telNumber': !exists(json, 'telNumber') ? undefined : json['telNumber'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'genderCode': !exists(json, 'genderCode') ? undefined : json['genderCode'],
        'ethnicityCode': !exists(json, 'ethnicityCode') ? undefined : json['ethnicityCode'],
        'ethnicityCodeNamespace': !exists(json, 'ethnicityCodeNamespace') ? undefined : json['ethnicityCodeNamespace'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'emailAcceptable': !exists(json, 'emailAcceptable') ? undefined : json['emailAcceptable'],
        'smsAcceptable': !exists(json, 'smsAcceptable') ? undefined : json['smsAcceptable'],
        'dateOfDeath': !exists(json, 'dateOfDeath') ? undefined : json['dateOfDeath'],
        'patientDeceased': !exists(json, 'patientDeceased') ? undefined : json['patientDeceased'],
    };
}

export function ProfileDtoToJSON(value?: ProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'overrideMessages': value.overrideMessages === undefined ? undefined : ((value.overrideMessages as Array<any>).map(OverrideMessageDtoToJSON)),
        'userIds': value.userIds,
        'title': value.title,
        'firstNames': value.firstNames,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth,
        'address': AddressDtoToJSON(value.address),
        'mobileNumber': value.mobileNumber,
        'telNumber': value.telNumber,
        'gender': value.gender,
        'genderCode': value.genderCode,
        'ethnicityCode': value.ethnicityCode,
        'ethnicityCodeNamespace': value.ethnicityCodeNamespace,
        'emailAddress': value.emailAddress,
        'emailAcceptable': value.emailAcceptable,
        'smsAcceptable': value.smsAcceptable,
        'dateOfDeath': value.dateOfDeath,
        'patientDeceased': value.patientDeceased,
    };
}

