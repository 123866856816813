/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PathwayDto } from './PathwayDto';
import {
    PathwayDtoFromJSON,
    PathwayDtoFromJSONTyped,
    PathwayDtoToJSON,
} from './PathwayDto';

/**
 * Request for an Pathway composition
 * @export
 * @interface PathwayResponseDto
 */
export interface PathwayResponseDto {
    /**
     * 
     * @type {PathwayDto}
     * @memberof PathwayResponseDto
     */
    pathway?: PathwayDto;
    /**
     * Uid
     * @type {string}
     * @memberof PathwayResponseDto
     */
    uuid?: string;
}

/**
 * Check if a given object implements the PathwayResponseDto interface.
 */
export function instanceOfPathwayResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PathwayResponseDtoFromJSON(json: any): PathwayResponseDto {
    return PathwayResponseDtoFromJSONTyped(json, false);
}

export function PathwayResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pathway': !exists(json, 'pathway') ? undefined : PathwayDtoFromJSON(json['pathway']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
    };
}

export function PathwayResponseDtoToJSON(value?: PathwayResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pathway': PathwayDtoToJSON(value.pathway),
        'uuid': value.uuid,
    };
}

