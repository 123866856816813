/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a supported language
 * @export
 * @interface SupportedLanguageDto
 */
export interface SupportedLanguageDto {
    /**
     * Language code
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    languageCode: string;
    /**
     * Language name
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    languageName: string;
    /**
     * Language name
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    localisedName: string;
    /**
     * Enabled
     * @type {boolean}
     * @memberof SupportedLanguageDto
     */
    enabled: boolean;
    /**
     * Permanent
     * @type {boolean}
     * @memberof SupportedLanguageDto
     */
    permanent?: boolean;
}

/**
 * Check if a given object implements the SupportedLanguageDto interface.
 */
export function instanceOfSupportedLanguageDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "languageName" in value;
    isInstance = isInstance && "localisedName" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function SupportedLanguageDtoFromJSON(json: any): SupportedLanguageDto {
    return SupportedLanguageDtoFromJSONTyped(json, false);
}

export function SupportedLanguageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedLanguageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageCode': json['languageCode'],
        'languageName': json['languageName'],
        'localisedName': json['localisedName'],
        'enabled': json['enabled'],
        'permanent': !exists(json, 'permanent') ? undefined : json['permanent'],
    };
}

export function SupportedLanguageDtoToJSON(value?: SupportedLanguageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'languageCode': value.languageCode,
        'languageName': value.languageName,
        'localisedName': value.localisedName,
        'enabled': value.enabled,
        'permanent': value.permanent,
    };
}

