import React from 'react';

import {
  Backdrop,
  Card,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import {
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LoadingView = ({
  className,
  isLoading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Backdrop className={classes.backdrop} open={isLoading === undefined ? false : isLoading}>
      <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: 20,
                  paddingRight: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'transparent'
                }}>

        <CircularProgress size={60} color="inherit"/>
        </div>
  </Backdrop>             
  );
};

export default LoadingView;
