import { BorderAll } from '@material-ui/icons';
import { ADD_PATIENT_DATA_COMPOSITION } from '../actions/Actions';
import { FETCH_PATIENT_DATA_COMPOSITION } from '../actions/Actions';
import { FETCH_PATIENT_PATHWAY_RESULTS } from '../actions/Actions';
import { FETCH_PATIENT_PATHWAYS } from '../actions/Actions';
import { FETCH_PATIENT_PATHWAY_BY_UUID } from '../actions/Actions';
import { PATIENT_DATA_ERROR } from '../actions/Actions';
import { PATIENT_DATA_UPDATE_ERROR } from '../actions/Actions';
import { PATIENT_DATA_CLEAR_ERROR } from '../actions/Actions';
import { PATIENT_DATA_IS_LOADING } from '../actions/Actions';
import { PATIENT_DATA_SUCCESS_CLEAR } from '../actions/Actions';
import { PATIENT_DATA_DELETE_SUCCESS_CLEAR } from '../actions/Actions';
import { PATIENT_DETAILS_CLEAR } from '../actions/Actions';
import { LOGOUT } from '../actions/Actions';

import { ADD_PATIENT_PATHWAY } from '../actions/Actions';
import { UPDATE_PATIENT_PATHWAY } from '../actions/Actions';
import { DELETE_PATIENT_PATHWAY } from '../actions/Actions';

const initialState = {
  pathwayOutcomes: [],
  compositions: {},
  pathways: [],
  scheduledEvents: [],
  patientPathwayFetchTime: 0,

  hasError: false,
  hasUpdateError: false,
  isLoading: false,
  errorMsg: null,
  updateSuccess: false,
  deleteSuccess: false
};

function generateOutcomesFromPatientQuestionnaireResults(rows, columns) {
  // Filter column indexes into proms and subTemplates
  const columnIndexes = {};
  columns.forEach((column, columnIndex) => {
    const columnName = column.name.replaceAll("__hyphen__", "-").replaceAll("__colon__", ":").replaceAll("__slash__", "/");

    const columnSplitArray = columnName.split("__");
    if (columnSplitArray.length > 1) {
      const promId = columnSplitArray[0];
      const templateId = columnSplitArray[1];
      const key = columnSplitArray[2];

      if (columnIndexes[promId] === undefined) {
        var templates = {};
        templates[templateId] = { resultSections: [], scoreColumns: [] };
        columnIndexes[promId] = templates;
      } else {
        if (columnIndexes[promId][templateId] === undefined) {
          columnIndexes[promId][templateId] = { resultSections: [], scoreColumns: [] };
        }
      }

      if (key !== "dateTime") {
        if (key === "score") {
          columnIndexes[promId][templateId].scoreColumns.push(columnIndex);
        } else {
          const sectionIndex = columnSplitArray[2];
          const sections = columnIndexes[promId][templateId].resultSections;
          var sectionArray = sections.find((section) => section.index === sectionIndex);
          if (sectionArray === undefined) {
            sectionArray = {
              index: sectionIndex,
              resultColumns: []
            }
            sections.push(sectionArray);
          }

          sectionArray.resultColumns.push(columnIndex);
        }
      }
    }
  }
  );

  // Filter rows
  Object.keys(columnIndexes).forEach((key) => {
    return Object.keys(columnIndexes[key]).forEach((t) => {
      const template = columnIndexes[key][t];

      columnIndexes[key][t] = rows.map((row) => {
        const dateTime = row[5];
        const results = template.resultSections.map((section) => {
          return row.filter((c, cIndex) => section.resultColumns.includes(cIndex));
        });

        const checkForResults = results.find((section) => {
          const found1 = section.find((e) => e != null);
          return found1 != undefined;
        });

        if (checkForResults) {
          const scores = row.filter((c, cIndex) => template.scoreColumns.includes(cIndex));

          return {
            compositionUid: row[0].split(":")[0],
            composer: row[1].replace("NULL ", ""),
            scheduledDateTime: row[2],
            description: row[3],
            stepId: row[4],
            results: results,
            scores: scores,
            dateTime: dateTime,
            sDt: dateTime ? new Date(dateTime) : null
          };
        }
      }).filter((row) => row).sort((a, b) => a.sDt - b.sDt)
    })
  })

  const outcomes = Object.keys(columnIndexes).map((key) => {
    return Object.keys(columnIndexes[key]).map((t) => {
      const template = columnIndexes[key][t];
      return {
        promsId: key,
        promsSubTemplateId: t,
        results: template
      }
    });
  }).flat(1);

  return outcomes;
}

function getScheduledEvents(pathways) {
  var events = [];
  pathways.forEach(pathway => {
    if (pathway.status !== "completed") {
      pathway.steps.forEach((step) => {
        if (step.status === "scheduled") {
          var event = Object.assign({}, step);
          event.pathway = pathway.pathway;
          event.pathwayId = pathway.pathwayId;
          events.push(event);
        }
      }
      );
    }
  });

  return events;
}

const patientDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PATIENT_DATA_COMPOSITION:
      return {
        ...state,
        updateSuccess: true
      };
    case FETCH_PATIENT_PATHWAY_RESULTS:
      console.log("FETCH QUESTIONNAIRES");
      console.log(action.promResults);
      console.log(action.pathwayId);
      console.log(action.patientId);

      var outcomes = [];
      if (action.promResults != null) {
        const rows = action.promResults.rows;
        const columns = action.promResults.columns;
        outcomes = generateOutcomesFromPatientQuestionnaireResults(rows, columns);
      }

      const compositions = { ...state.compositions };
      action.procedureCompositions.forEach((c) => {
        compositions[c.uid] = JSON.parse(c.composition);
      });

      return Object.assign({}, state, {
        pathwayOutcomes: outcomes,
        compositions: compositions
      });
    case PATIENT_DATA_ERROR:
      console.log('haserror', action);

      return Object.assign({}, state, {
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        updateSuccess: false
      });
    case PATIENT_DATA_UPDATE_ERROR:
      console.log('haserror', action);

      return Object.assign({}, state, {
        hasUpdateError: action.hasError,
        errorMsg: action.errorMsg,
        updateSuccess: false
      });
    case PATIENT_DATA_CLEAR_ERROR:
      console.log('haserror', action);

      return Object.assign({}, state, {
        hasError: false,
        hasUpdateError: false
      });

    case PATIENT_DATA_IS_LOADING:
      console.log('isloading', action);

      return Object.assign({}, state, {
        isLoading: action.isLoading,
      });
    case PATIENT_DATA_SUCCESS_CLEAR: {
      console.log('update success clear', action);

      return Object.assign({}, state, {
        updateSuccess: false,
      });
    }
    case PATIENT_DATA_DELETE_SUCCESS_CLEAR: {
      console.log('update delete success clear', action);

      return Object.assign({}, state, {
        deleteSuccess: false,
      });
    }
    case FETCH_PATIENT_PATHWAYS: {
      console.log('Fetch patient pathways', action);

      var events = getScheduledEvents(action.payload);

      const patientPathwayFetchTime = action.patientPathwayFetchTime;
      const userId = action.userId;
      const userIdNamespace = action.userIdNamespace;

      return Object.assign({}, state, {
        pathways: action.payload,
        scheduledEvents: events,
        patientPathwayFetchTime: patientPathwayFetchTime,
        userId: userId,
        userIdNamespace: userIdNamespace
      });
    }
    case ADD_PATIENT_PATHWAY: {
      console.log('Add new patient pathway', action);
      var array = [...state.pathways, action.pathway];

      var events = getScheduledEvents(array);

      return Object.assign({}, state, {
        pathways: array,
        scheduledEvents: events
      });
    }
    case FETCH_PATIENT_PATHWAY_BY_UUID: {
      console.log('fetch patient pathway', action);
      var updatedPathway = action.pathway;
      var updatedPathwayArray = state.pathways.map((pathway) => {
        if (pathway.pathwayId !== updatedPathway.pathwayId) {
          return pathway;
        }

        return updatedPathway;
      });

      var events = getScheduledEvents(updatedPathwayArray);

      return Object.assign({}, state, {
        pathways: updatedPathwayArray,
        scheduledEvents: events
      });
    }
    case UPDATE_PATIENT_PATHWAY: {
      console.log('Update patient pathway', action);
      var updatedPathway = action.pathway;
      var updatedPathwayArray = state.pathways.map((pathway) => {
        if (pathway.pathwayId !== updatedPathway.pathwayId) {
          return pathway;
        }

        return updatedPathway;
      });

      var events = getScheduledEvents(updatedPathwayArray);

      return Object.assign({}, state, {
        pathways: updatedPathwayArray,
        scheduledEvents: events,
        updateSuccess: true
      });
    }
    case DELETE_PATIENT_PATHWAY: {
      console.log('Delete patient pathway', action);

      var updatedPathway = action.data.pathway;
      var updatedPathwayArray = state.pathways.filter((pathway) => pathway.pathwayId !== updatedPathway.pathwayId);

      var events = getScheduledEvents(updatedPathwayArray);

      return Object.assign({}, state, {
        pathways: updatedPathwayArray,
        scheduledEvents: events,
        deleteSuccess: true
      });
    }
    case FETCH_PATIENT_DATA_COMPOSITION: {
      console.log('Fetch patient composition');

      const content = JSON.parse(action.payload);
      const composition = JSON.parse(content.composition);
      const compositionUUID = action.compositionUUID;

      const compositions = { ...state.compositions, [compositionUUID]: composition };

      return Object.assign({}, state, {
        compositions: compositions
      }); // TODO 
    }
    case PATIENT_DETAILS_CLEAR:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default patientDataReducer;