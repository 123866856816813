/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents an audit entry
 * @export
 * @interface AuditDto
 */
export interface AuditDto {
    /**
     * Id
     * @type {string}
     * @memberof AuditDto
     */
    id: string;
    /**
     * Type
     * @type {string}
     * @memberof AuditDto
     */
    type: string;
    /**
     * Action
     * @type {string}
     * @memberof AuditDto
     */
    action: string;
    /**
     * Attributes
     * @type {{ [key: string]: string; }}
     * @memberof AuditDto
     */
    attributes: { [key: string]: string; };
    /**
     * Name of user
     * @type {string}
     * @memberof AuditDto
     */
    userName: string;
    /**
     * Id of user
     * @type {string}
     * @memberof AuditDto
     */
    userId: string;
    /**
     * Date/time of entry
     * @type {string}
     * @memberof AuditDto
     */
    dateTime: string;
}

/**
 * Check if a given object implements the AuditDto interface.
 */
export function instanceOfAuditDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "attributes" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "dateTime" in value;

    return isInstance;
}

export function AuditDtoFromJSON(json: any): AuditDto {
    return AuditDtoFromJSONTyped(json, false);
}

export function AuditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'action': json['action'],
        'attributes': json['attributes'],
        'userName': json['userName'],
        'userId': json['userId'],
        'dateTime': json['dateTime'],
    };
}

export function AuditDtoToJSON(value?: AuditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'action': value.action,
        'attributes': value.attributes,
        'userName': value.userName,
        'userId': value.userId,
        'dateTime': value.dateTime,
    };
}

