import { WIDGET_DATA_FETCH } from './Actions';
import { WIDGET_DATA_IS_LOADING  } from './Actions';
import { WIDGET_DATA_ERROR  } from './Actions';

import { WidgetDataEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const widgetDataError = (bool, errorMsg) => {
  return {
      type: WIDGET_DATA_ERROR,
      hasError: bool,
      errorMsg: errorMsg
  }
};

const widgetDataLoading = (bool) => {
  return {
      type: WIDGET_DATA_IS_LOADING,
      isLoading: bool
  }
};

const clearWidgetDataError = () => {
  return {
      type: WIDGET_DATA_ERROR,
      hasError: false,
      errorMsg: null
  }
};

const fetchWidgetDataSuccessHandler = (payload) => {
  return {
      type: WIDGET_DATA_FETCH,
      payload : payload
  }
};

const fetchWidgetData = () => {
  console.log("Fetch widget data");

  return (dispatch, getState) => {
      dispatch(widgetDataLoading(true));

      var config = getConfiguation(dispatch, getState);
      var widgetApi = new WidgetDataEndpointApi(config);
      var request = widgetApi.getData();
      onRequest(request, dispatch, fetchWidgetDataSuccessHandler, widgetDataError, widgetDataLoading, )
  };
};

export default {
  clearWidgetDataError,
  fetchWidgetData
}